import React, { useState } from 'react'

import Step1 from '../TutorialSteps/DashboardSteps/Step1'
import Step2 from '../TutorialSteps/DashboardSteps/Step2'
import Step3 from '../TutorialSteps/DashboardSteps/Step3'
import Step4 from '../TutorialSteps/FormSteps/Step4'
import Step5 from '../TutorialSteps/FormSteps/Step5'
import Step6 from '../TutorialSteps/FormSteps/Step6'

interface TutorialFormInterface {
    skipClick: () => void
    onClose: () => void
    setCheckTourOpen?: any
}

const TutorialDashboard = ({ skipClick, onClose }: TutorialFormInterface) => {
    const [activeStep, setActiveStep] = useState(0)

    const nextStep = async () => {
        setActiveStep(activeStep + 1)
    }

    const previousStep = () => setActiveStep(activeStep - 1)

    const stepComponent = () => {
        switch (activeStep) {
            case 0:
                return <Step1 nextStep={nextStep} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
            case 1:
                return <Step2 nextStep={nextStep} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
            case 2:
                return <Step3 nextStep={onClose} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
        }
    }
    return (
        <div>
            <div className="mt-[0px]">{stepComponent()}</div>
        </div>
    )
}

export default TutorialDashboard
