export const decimalKeysForm7_1 = [
    'o7_1_k2_z_15_19',
    'o7_1_k2_m_15_19',
    'o7_1_k2_z_20_24',
    'o7_1_k2_m_20_24',
    'o7_1_k2_z_25_29',
    'o7_1_k2_m_25_29',
    'o7_1_k2_z_30_34',
    'o7_1_k2_m_30_34',
    'o7_1_k2_z_35_39',
    'o7_1_k2_m_35_39',

    'o7_1_k2_z_40_44',
    'o7_1_k2_m_40_44',
    'o7_1_k2_z_45_49',
    'o7_1_k2_m_45_49',
    'o7_1_k2_z_50_54',
    'o7_1_k2_m_50_54',
    'o7_1_k2_z_55_59',
    'o7_1_k2_m_55_59',
    'o7_1_k2_z_60_64',
    'o7_1_k2_m_60_64',

    'o7_1_k2_z_65_69',
    'o7_1_k2_m_65_69',
    'o7_1_k2_z_70_74',
    'o7_1_k2_m_70_74',
    'o7_1_k2_z_75',
    'o7_1_k2_m_75',

    'o7_1_k3_z_15_19',
    'o7_1_k3_m_15_19',
    'o7_1_k3_z_20_24',
    'o7_1_k3_m_20_24',
    'o7_1_k3_z_25_29',
    'o7_1_k3_m_25_29',
    'o7_1_k3_z_30_34',
    'o7_1_k3_m_30_34',
    'o7_1_k3_z_35_39',
    'o7_1_k3_m_35_39',
    'o7_1_k3_z_40_44',
    'o7_1_k3_m_40_44',
    'o7_1_k3_z_45_49',
    'o7_1_k3_m_45_49',
    'o7_1_k3_z_50_54',
    'o7_1_k3_m_50_54',
    'o7_1_k3_z_55_59',
    'o7_1_k3_m_55_59',
    'o7_1_k3_z_60_64',
    'o7_1_k3_m_60_64',
    'o7_1_k3_z_65_69',
    'o7_1_k3_m_65_69',
    'o7_1_k3_z_70_74',
    'o7_1_k3_m_70_74',
    'o7_1_k3_z_75',
    'o7_1_k3_m_75',

    'o7_1_k4_z_1_14',
    'o7_1_k4_z_15_35',
    'o7_1_k4_z_36_48',
    'o7_1_k4_z_49_59',
    'o7_1_k4_z_60',
    'o7_1_k4_z_varira',

    'o7_1_k4_m_1_14',
    'o7_1_k4_m_15_35',
    'o7_1_k4_m_36_48',
    'o7_1_k4_m_49_59',
    'o7_1_k4_m_60',
    'o7_1_k4_m_varira',

    'o7_1_k4_z_poljoprivreda',
    'o7_1_k4_z_industrija',
    'o7_1_k4_z_gradjevina',
    'o7_1_k4_z_usluge',

    'o7_1_k4_m_poljoprivreda',
    'o7_1_k4_m_industrija',
    'o7_1_k4_m_gradjevina',
    'o7_1_k4_m_usluge',

    'o7_1_k5_z_poljoprivreda',
    'o7_1_k5_z_industrija',
    'o7_1_k5_z_gradjevina',
    'o7_1_k5_z_usluge',

    'o7_1_k5_m_poljoprivreda',
    'o7_1_k5_m_industrija',
    'o7_1_k5_m_gradjevina',
    'o7_1_k5_m_usluge',

    'o7_1_k5_z_zap_privatna',
    'o7_1_k5_z_zap_drzavna',
    'o7_1_k5_z_zap_ostalo',

    'o7_1_k5_m_zap_privatna',
    'o7_1_k5_m_zap_drzavna',
    'o7_1_k5_m_zap_ostalo',

    'o7_1_k5_z_samo_privatna',
    'o7_1_k5_z_samo_drzavna',
    'o7_1_k5_z_samo_ostalo',

    'o7_1_k5_m_samo_privatna',
    'o7_1_k5_m_samo_drzavna',
    'o7_1_k5_m_samo_ostalo',
]
export const decimalKeysForm7_2 = [
    'o7_2_k1_z_placeni',
    'o7_2_k1_z_neplaceni',
    'o7_2_k1_z_ucenje',
    'o7_2_k1_z_licne',
    'o7_2_k1_z_slobodno',
    'o7_2_k1_z_ostalo',

    'o7_2_k1_m_placeni',
    'o7_2_k1_m_neplaceni',
    'o7_2_k1_m_ucenje',
    'o7_2_k1_m_licne',
    'o7_2_k1_m_slobodno',
    'o7_2_k1_m_ostalo',

    'o7_2_k2_z_placeni',
    'o7_2_k2_z_neplaceni',
    'o7_2_k2_z_ucenje',
    'o7_2_k2_z_licne',
    'o7_2_k2_z_slobodno',
    'o7_2_k2_z_ostalo',

    'o7_2_k2_m_placeni',
    'o7_2_k2_m_neplaceni',
    'o7_2_k2_m_ucenje',
    'o7_2_k2_m_licne',
    'o7_2_k2_m_slobodno',
    'o7_2_k2_m_ostalo',

    'o7_2_k3_t1_z_placeni',
    'o7_2_k3_t1_z_neplaceni',
    'o7_2_k3_t1_z_ucenje',
    'o7_2_k3_t1_z_licne',
    'o7_2_k3_t1_z_slobodno',
    'o7_2_k3_t1_z_ostalo',

    'o7_2_k3_t1_m_placeni',
    'o7_2_k3_t1_m_neplaceni',
    'o7_2_k3_t1_m_ucenje',
    'o7_2_k3_t1_m_licne',
    'o7_2_k3_t1_m_slobodno',
    'o7_2_k3_t1_m_ostalo',

    'o7_2_k3_t2_z_placeni',
    'o7_2_k3_t2_z_neplaceni',
    'o7_2_k3_t2_z_ucenje',
    'o7_2_k3_t2_z_licne',
    'o7_2_k3_t2_z_slobodno',
    'o7_2_k3_t2_z_ostalo',

    'o7_2_k3_t2_m_placeni',
    'o7_2_k3_t2_m_neplaceni',
    'o7_2_k3_t2_m_ucenje',
    'o7_2_k3_t2_m_licne',
    'o7_2_k3_t2_m_slobodno',
    'o7_2_k3_t2_m_ostalo',

    'o7_2_k4_z_ubraku_bezdeteta_17',
    'o7_2_k4_z_ubraku_dete_0_6',
    'o7_2_k4_z_ubraku_dete_7_17',
    'o7_2_k4_z_vanbraka_dete_17',
    'o7_2_k4_z_vanbraka_bezdeteta_17',

    'o7_2_k4_m_ubraku_bezdeteta_17',
    'o7_2_k4_m_ubraku_dete_0_6',
    'o7_2_k4_m_ubraku_dete_7_17',
    'o7_2_k4_m_vanbraka_dete_17',
    'o7_2_k4_m_vanbraka_bezdeteta_17',

    'o7_2_k5_z_0_14',
    'o7_2_k5_z_15_24',
    'o7_2_k5_z_25_44',
    'o7_2_k5_z_45_54',
    'o7_2_k5_z_55_64',
    'o7_2_k5_z_65',

    'o7_2_k5_m_0_14',
    'o7_2_k5_m_15_24',
    'o7_2_k5_m_25_44',
    'o7_2_k5_m_45_54',
    'o7_2_k5_m_55_64',
    'o7_2_k5_m_65',
]
export const decimalKeysForm7_3 = [
    'o7_3_k1_z_tg_2',
    'o7_3_k1_z_tg_1',
    'o7_3_k1_z_tg',
    'o7_3_k1_m_tg_2',
    'o7_3_k1_m_tg_1',
    'o7_3_k1_m_tg',
    'o7_3_k2_z_15_29',
    'o7_3_k2_z_30_39',
    'o7_3_k2_z_40_49',
    'o7_3_k2_z_50_59',
    'o7_3_k2_z_60',

    'o7_3_k2_m_15_29',
    'o7_3_k2_m_30_39',
    'o7_3_k2_m_40_49',
    'o7_3_k2_m_50_59',
    'o7_3_k2_m_60',

    'o7_3_k2_jaz_15_29',
    'o7_3_k2_jaz_30_39',
    'o7_3_k2_jaz_40_49',
    'o7_3_k2_jaz_50_59',
    'o7_3_k2_jaz_60',

    'o7_3_k3_z_osnovna',
    'o7_3_k3_z_srednja',
    'o7_3_k3_z_visa',
    'o7_3_k3_z_visoka',

    'o7_3_k3_m_osnovna',
    'o7_3_k3_m_srednja',
    'o7_3_k3_m_visa',
    'o7_3_k3_m_visoka',

    'o7_3_k3_jaz_osnovna',
    'o7_3_k3_jaz_srednja',
    'o7_3_k3_jaz_visa',
    'o7_3_k3_jaz_visoka',

    'o7_3_k4_z_rukovodioci',
    'o7_3_k4_z_strucnjaci',
    'o7_3_k4_z_inzenjeri',
    'o7_3_k4_z_admini',
    'o7_3_k4_z_usluga',
    'o7_3_k4_z_poljoprivreda',
    'o7_3_k4_z_zanatlije',
    'o7_3_k4_z_rukovaoci',
    'o7_3_k4_z_jednostavna',

    'o7_3_k4_m_rukovodioci',
    'o7_3_k4_m_strucnjaci',
    'o7_3_k4_m_inzenjeri',
    'o7_3_k4_m_admini',
    'o7_3_k4_m_usluga',
    'o7_3_k4_m_poljoprivreda',
    'o7_3_k4_m_zanatlije',
    'o7_3_k4_m_rukovaoci',
    'o7_3_k4_m_jednostavna',

    'o7_3_k4_jaz_rukovodioci',
    'o7_3_k4_jaz_strucnjaci',
    'o7_3_k4_jaz_inzenjeri',
    'o7_3_k4_jaz_admini',
    'o7_3_k4_jaz_usluga',
    'o7_3_k4_jaz_poljoprivreda',
    'o7_3_k4_jaz_zanatlije',
    'o7_3_k4_jaz_rukovaoci',
    'o7_3_k4_jaz_jednostavna',

    'o7_3_k5_z_tg_2',
    'o7_3_k5_z_tg_1',
    'o7_3_k5_z_tg',
    'o7_3_k5_m_tg_2',
    'o7_3_k5_m_tg_1',
    'o7_3_k5_m_tg',

    'o7_3_k6_z_broj_sve_starosne',
    'o7_3_k6_z_broj_sve_invalidske',
    'o7_3_k6_z_broj_zaposleni_starosne',
    'o7_3_k6_z_broj_zaposleni_invalidske',
    'o7_3_k6_z_broj_samostalne_starosne',
    'o7_3_k6_z_broj_samostalne_invalidske',
    'o7_3_k6_z_broj_poljop_starosne',
    'o7_3_k6_z_broj_poljop_invalidske',

    'o7_3_k6_z_prosek_sve_starosne',
    'o7_3_k6_z_prosek_sve_invalidske',
    'o7_3_k6_z_prosek_zaposleni_starosne',
    'o7_3_k6_z_prosek_zaposleni_invalidske',
    'o7_3_k6_z_prosek_samostalne_starosne',
    'o7_3_k6_z_prosek_samostalne_invalidske',
    'o7_3_k6_z_prosek_poljop_starosne',
    'o7_3_k6_z_prosek_poljop_invalidske',

    'o7_3_k6_m_prosek_sve_starosne',
    'o7_3_k6_m_prosek_sve_invalidske',
    'o7_3_k6_m_prosek_zaposleni_starosne',
    'o7_3_k6_m_prosek_zaposleni_invalidske',
    'o7_3_k6_m_prosek_samostalne_starosne',
    'o7_3_k6_m_prosek_samostalne_invalidske',
    'o7_3_k6_m_prosek_poljop_starosne',
    'o7_3_k6_m_prosek_poljop_invalidske',

    'o7_3_k6_m_prosek_penzije_sve_starosne',
    'o7_3_k6_m_prosek_penzije_sve_invalidske',
    'o7_3_k6_m_prosek_penzije_zaposleni_starosne',
    'o7_3_k6_m_prosek_penzije_zaposleni_invalidske',
    'o7_3_k6_m_prosek_penzije_samostalne_starosne',
    'o7_3_k6_m_prosek_penzije_samostalne_invalidske',
    'o7_3_k6_m_prosek_penzije_poljop_starosne',
    'o7_3_k6_m_prosek_penzije_poljop_invalidske',

    'o7_3_k7_z_invalidske',
    'o7_3_k7_z_starosne',
    'o7_3_k7_m_invalidske',
    'o7_3_k7_m_starosne',
]
export const decimalKeysForm7_10 = ['o7_10_k4_procenat_obucenih']
export const decimalKeysForm7_12 = ['o7_12_k2_z_tg_1', 'o7_12_k2_z_tg', 'o7_12_k2_m_tg_1', 'o7_12_k2_m_tg']
export const decimalKeysForm7_8 = ['o7_8_k1_woman_dropout_percent']
