import { useWatch } from 'react-hook-form'
import { TblOblast5 } from '../../../models/Form5/Form5.entity'
import ActionButtons from '../../../components/ActionButtons'
import SimpleInputRowForObject from '../../Form2/Components/SimpleImputRowForObject'
import { StepInterface } from './Step1'

const REGEX_STRING = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

function Step7({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast5>({ control })

    const fields: ('o5_k7_firstName' | 'o5_k7_lastName' | 'o5_k7_phoneNumber' | 'o5_k7_email')[] = ['o5_k7_firstName', 'o5_k7_lastName', 'o5_k7_phoneNumber', 'o5_k7_email']
    const disabledNext = !fields.every((e) => !!watchedValues && !!watchedValues[e])

    const goToNextStep = () => {
        if (!disabledNext && !errors?.o5_k7_email) nextStep()
        else if (adminView) nextStep()
    }

    const renderErrorMessage = (field: string) => {
        const error = errors?.[field]

        if (!error) return null

        let message = 'Попуните обавезно поље'

        if (error?.type === 'pattern') {
            message += ', Електронска адреса није исправно попуњена.'
        } else {
            message += '.'
        }

        return <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">{message}</p>
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }
    return (
        <div onKeyDown={handleKeyDown}>
            <h1 className="step-name">Подаци о лицу које је унело податке у дефинисана поља.</h1>
            <div className="mt-[20px] flex flex-col gap-[15px]">
                <SimpleInputRowForObject
                    title="Име"
                    name="o5_k7_firstName"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    isName
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Презиме"
                    name="o5_k7_lastName"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    isName
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Контакт телефон"
                    name="o5_k7_phoneNumber"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Емаил"
                    name="o5_k7_email"
                    maxNumberOfCharacter={300}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    isEmail
                    disabled={adminView}
                />
            </div>
            <div className="h-[20px]">
                <div className="h-[2px]">
                    {errors.o5_k7_firstName && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o5_k7_firstName')}</p>}
                    {!errors.o5_k7_firstName && errors.o5_k7_lastName && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o5_k7_lastName')}</p>}
                    {!errors.o5_k7_firstName && !errors.o5_k7_lastName && errors.o5_k7_phoneNumber && (
                        <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o5_k7_phoneNumber')}</p>
                    )}
                    {!errors.o5_k7_firstName && !errors.o5_k7_lastName && !errors.o5_k7_phoneNumber && errors.o5_k7_email && (
                        <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o5_k7_email')}</p>
                    )}
                </div>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}
export default Step7
