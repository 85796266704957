import { useEffect, useMemo, useState } from 'react'
import Stepper from '../../components/Common/Stepper/Stepper'
import { useForm } from 'react-hook-form'
import { TblOblast3, NumberOfSteps } from '../../models/Form3/Form3.entity'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import Step5 from './Steps/Step5'
import Step6 from './Steps/Step6'
import Step7 from './Steps/Step7'
import Step8 from './Steps/Step8'
import Step9 from './Steps/Step9'
import Step10 from './Steps/Step10'
import { getForm3, getUnfinishedForm3, saveForm3 } from '../../server/server'
import { FORM3_NAMES, HELP_BUTTON_3 } from '../../models/Form3/Form3Names'
import { FORM3_TITLE } from '../../models/FormTitles'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FinishOfFormModal from '../../components/Common/FinishOfFormModal'
import { isNotEmpty } from '../../utils/Validation'
import LoadingSpinner from '../../components/Common/LoadingSpinner'
import { useUser } from '../../auth/UseUser'
import TutorialModal from '../Tutorial/TutorialModal'
import HelpButton from '../../components/Common/HelpButton'

function Form3Screen() {
    let navigate = useNavigate()
    const { user } = useUser()
    const adminView = user.admin

    const [finishModalVisible, setFinishModalVisible] = useState(false)
    const [formCompleted, setFormCompleted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    let [searchParams, setSearchParams] = useSearchParams()
    const [tutorialModalOpen, setTutorialModalOpen] = useState(!adminView)
    const adminUsage = searchParams.get('adminUsage') === '1'
    const {
        handleSubmit,
        control,
        getValues,
        reset,
        setValue,
        formState: { errors },
    } = useForm<TblOblast3>()

    const formId = searchParams.get('formId')

    useEffect(() => {
        const formValues = getValues()
        const isNewForm = isNotEmpty(formValues.o3_k1_ima_plan)
        if (!isNewForm) {
            getUnfinishedForm()
        }
    }, [])

    const getUnfinishedForm = async () => {
        setLoading(true)
        let resp
        if (!adminView) {
            resp = await getUnfinishedForm3()
        } else {
            resp = await getForm3(formId)
        }
        const form = resp.data.form

        if (resp.status === 200 && resp.data) {
            Object.keys(form).forEach((key) => {
                setValue(key as keyof TblOblast3, form[key])
                if (key === 'report') {
                    setValue('submitted_by.firstName', form[key].submittedBy.firstName)
                    setValue('submitted_by.lastName', form[key].submittedBy.lastName)
                    setValue('submitted_by.phoneNumber', form[key].submittedBy.phoneNumber)
                    setValue('submitted_by.email', form[key].submittedBy.email)
                    setValue('submitted_by.has_education', form[key].submittedBy.has_education)
                    setValue('submitted_by.education_date', form[key].submittedBy.education_date)
                    setValue('submitted_by.education_name', form[key].submittedBy.education_name)
                    setValue('submitted_by.education_org', form[key].submittedBy.education_org)
                    setValue('submitted_by.education_teacher', form[key].submittedBy.education_teacher)
                    setValue('submitted_by.files', form[key].submittedBy.files)
                }
            })
            setFormCompleted(resp.data.form.status !== 0)
            setActiveStep(Number(resp.data.currentStep))
        }
        setLoading(false)
    }

    const onSelectStep = (step: number) => setActiveStep(step)
    const nextStep = async () => {
        const form = getValues()
        if (!adminView) {
            await save()
            if (activeStep === 0 && form.status === undefined) getUnfinishedForm()
        }
        setActiveStep(activeStep + 1)
    }
    const previousStep = () => setActiveStep(activeStep - 1)

    const save = async () => {
        const resp = await saveForm3(getValues(), activeStep + 1)

        if (resp.status === 201) {
            setValue('report_id', resp.data.report_id)
        }
    }

    const finish = async () => {
        if (!adminView) {
            await save()
        }
        setFinishModalVisible(true)
    }

    const resetValuesAndGoToFirstStep = () => {
        setFinishModalVisible(false)
        setActiveStep(0)
        reset()
        navigate('/')
    }

    const stepComponent = () => {
        switch (activeStep) {
            case 0:
                return <Step1 control={control} nextStep={nextStep} previousStep={previousStep} previousButtonDisabled={activeStep === 0} errors={errors} adminView={adminView} />
            case 1:
                return <Step2 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 2:
                return <Step3 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 3:
                return <Step4 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 4:
                return <Step5 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 5:
                return <Step6 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 6:
                return <Step7 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 7:
                return <Step8 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 8:
                return <Step9 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 9:
                return <Step10 control={control} nextStep={finish} previousStep={previousStep} errors={errors} adminView={adminView} setValue={setValue} />
            default:
                return null
        }
    }
    const onSubmit = async () => {
        // console.log('get values ', getValues())
    }

    return (
        <div className="max-w-[1556px] mx-[auto] px-[40px] pb-[20px]">
            <div className="pt-[40px] pb-[55px] flex flex-row gap-5 items-start justify-between">
                <h1 className="text-[24px] font-light">{FORM3_TITLE}</h1>
                <HelpButton name={HELP_BUTTON_3[activeStep]} marginTop={5} />
            </div>
            <div>
                <Stepper
                    formName={FORM3_NAMES}
                    numberOfSteps={NumberOfSteps}
                    onSelectStep={onSelectStep}
                    activeStep={activeStep}
                    unlockEveryStep={!!adminUsage || adminView || formCompleted}
                />
            </div>
            {!loading ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-[55px]">{stepComponent()}</div>
                </form>
            ) : (
                <div className="flex justify-center items-center h-full mt-[100px]">
                    <LoadingSpinner />
                </div>
            )}
            <TutorialModal open={tutorialModalOpen} onClose={() => setTutorialModalOpen(false)} segment="form" />
            <FinishOfFormModal modalIsOpen={finishModalVisible} closeModal={resetValuesAndGoToFirstStep} infoText={FORM3_TITLE} adminView={adminView} />
        </div>
    )
}

export default Form3Screen
