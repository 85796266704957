export const FORM7_NAMES = [
    '7.1 Подршка иновативним програмима и услугама за активацију жена на тржишту рада, и њихову повећану запошљивост и самозапошљавање, те креирање радних места за жене са додатно отежаним приступом одрживом запошљавању и самозапошљавању.',
    '7.2. Препознавање, вредновање и редистрибуција неплаћеног кућног рада и повећање расположивог времена за плаћени рад, лични развој и слободно време',
    '7.3. Смањење платног јаза између жена и мушкараца на тржишту рада у свим секторима и повећање учешћа жена у високо плаћеним пословима',
    '7.4. Успостављање системске подршке за започињање, развој и раст бизниса у већинском власништву жена и повећање профитабилности њихових предузетничких активности',
    '7.5. Успостављање системске подршке за започињање, развој и раст бизниса у већинском власништву жена и повећање профитабилности њихових предузетничких активности',
    '7.6. Обезбеђење социјалне сигурности, смањење сиромаштва, социјалне искључености и унапређење доступности услуга социјалне заштите ради очувања и повећања квалитета живота, благостања жена и мушкараца из рањивих група, као и подршке породицама у задовољењу животних потреба.',
    '7.7. Уродњавање јавних политика и законодавства у области предшколског и основношколског васпитања и образовања, јачање капацитета свих релевантних актера и институција и подизања нивоа свести о значају остваривања родне равноправности',
    '7.8. Уродњавање јавних политика и законодавства у области средњег образовања и васпитања, јачање капацитета свих релевантних актера и институција, подизање нивоа свести о значају остваривања родне равноправности и промоција инклузивног родно сензитивног доживотног учења',
    '7.9. Уродњавање јавних политика и законодавства у области образовања и науке, јачање капацитета свих релевантних актера и институција, подизања нивоа свести о значају остваривања родне равноправности и успостављање једнаких могућности за каријерно напредовање запослених жена и мушкараца',
    '7.10. Успостављање институционалних претпоставки и нормативних гаранција за владавину права, једнаке могућности приступа правди и обезбеђивање ефикасне и делотворне заштите људских права и родне равноправности',
    '7.11. Унапређена безбедност жена и девојчица у јавној и приватној сфери кроз елиминацију свих облика насиља, укључујући трговину људима, сексуалне и друге облике експлоатације, посебно у време криза и ванредних ситуација',
    '7.12. Јачање капацитета, унапређивање институционалног и нормативног оквира родне равноправности у политичком животу и обезбеђивање равноправног учешћа жена и мушкараца, посебно рањивих група у одлучивању о јавним пословима',
    '7.13. Унапређени капацитети, институционални и нормативни оквир који обликују амбијент у коме се унапређује родна равноправност са акцентом на институционалне механизме родне равноправности као део целовитог и функционалног система и механизама за креирање родно одговорних јавних политика',
    '7.14.1. Унапређени капацитети за креирање родно одговорних ресорних јавних политика, анализу и прађење ефеката јавних политика на остваривање родне равноправности',
    '7.14.2. Унапређени капацитети за креирање родно одговорних ресорних јавних политика, анализу и прађење ефеката јавних политика на остваривање родне равноправности',
    '7.15 Унапређење доступности и квалитета здравствене заштите без дискриминације, укључујући програме ране превенције сексуалног и репродуктивног здравља, канцера дојке и канцера грлића материце код девојчица и жена, односно канцера простате код дечака и мушкараца као и подизање свести о савременим облицима контрацепције и побољшање приступа услугама за планирање породице',
    '7.16. Унапређена безбедност жена у миру, конфликту и постконфликтном опоравку друштва, кризним и ванредним ситуацијама, кроз интегралну примену Резолуције 1325 СБ УН–Жене, мир и безбедност у Републици Србији',
    '7.17. Развој и унапређење родне статистике, повећање доступности података и информација као и коришћење података који одражавају стварност живота жена и мушкараца, девојчица и дечака за креирање политика',
    '7.18. Континуирана и појачана примена родно одговорног буџетирања на националном, покрајинском и локалном нивоу у поступку планирања и извршења буџета, институционализација РОБ-а у буџетском систему РС на нивоу јединице територијалне аутономије или јединице локалне самоуправе исказани за годину вођења евиденције',
    '7.19. Успостављање системске подршке за подстицање учешћа жена у друштвеним и технолошким иновацијама и повећању користи од иновационе делатности',
]
