import React, { useState } from 'react'
import { StepInterface } from './Step1'
import FileDescriptionTable from '../../../Components/FileDescriptionTable'
import { Button } from '@mui/material'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Form7_16Entity } from '../../../../../models/Form7/Entities/Form7_16.entity'
import UploadFile from '../../../../../components/UploadFile'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_17Entity } from '../../../../../models/Form7/Entities/Form7_17.entity'
import ComplexTable7_17 from '../../../Components/Form7_17_k6Table'

const getPercentageDifference = (current: number, previous: number) => {
    if (previous == 0) {
        return 0
    }
    return ((current - previous) / previous) * 100
}

export const Step7 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_17Entity>({ control })

    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_17_k7_fileDescription',
    })
    const name = 'o7_17_k7_fileDescription'

    const header = ['Бр.', 'Назив документа	', 'Врста документа', 'Линк (ако постоји)']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ fileName: '', type: '', link: '' })
    }

    useState(() => {
        if (fields.length === 0) {
            append({ fileName: '', type: '', link: '' })
        }
    })

    const validateEmptyArray = () => {
        const isEmpty = !watchedValues[name]?.length
        setEmptyArrayError(isEmpty)
        return isEmpty
    }

    const currentYear = new Date().getFullYear()
    const headers = [
        'Област',
        ...Array.from({ length: 6 }, (_, i) => (currentYear - 5 + i).toString()),
        'Процентуална промена у односу на 1 посматрану годину',
        'Број година до изједначења',
        'Оцена напретка',
    ]

    const rows: { label: string; fields: (keyof Form7_17Entity)[] }[] = [
        {
            label: 'Рад',
            fields: ['o7_17_k7_rad_tg5', 'o7_17_k7_rad_tg4', 'o7_17_k7_rad_tg3', 'o7_17_k7_rad_tg2', 'o7_17_k7_rad_tg1', 'o7_17_k7_rad_tg0', 'o7_17_k7_rad_ocena'],
        },
        {
            label: 'Новац',
            fields: ['o7_17_k7_novac_tg5', 'o7_17_k7_novac_tg4', 'o7_17_k7_novac_tg3', 'o7_17_k7_novac_tg2', 'o7_17_k7_novac_tg1', 'o7_17_k7_novac_tg0', 'o7_17_k7_novac_ocena'],
        },
        {
            label: 'Знање',
            fields: [
                'o7_17_k7_znanje_tg5',
                'o7_17_k7_znanje_tg4',
                'o7_17_k7_znanje_tg3',
                'o7_17_k7_znanje_tg2',
                'o7_17_k7_znanje_tg1',
                'o7_17_k7_znanje_tg0',
                'o7_17_k7_znanje_ocena',
            ],
        },
        {
            label: 'Време',
            fields: ['o7_17_k7_vreme_tg5', 'o7_17_k7_vreme_tg4', 'o7_17_k7_vreme_tg3', 'o7_17_k7_vreme_tg2', 'o7_17_k7_vreme_tg1', 'o7_17_k7_vreme_tg0', 'o7_17_k7_vreme_ocena'],
        },
        {
            label: 'Моћ',
            fields: ['o7_17_k7_moc_tg5', 'o7_17_k7_moc_tg4', 'o7_17_k7_moc_tg3', 'o7_17_k7_moc_tg2', 'o7_17_k7_moc_tg1', 'o7_17_k7_moc_tg0', 'o7_17_k7_moc_ocena'],
        },
        {
            label: 'Здравље',
            fields: [
                'o7_17_k7_zdravlje_tg5',
                'o7_17_k7_zdravlje_tg4',
                'o7_17_k7_zdravlje_tg3',
                'o7_17_k7_zdravlje_tg2',
                'o7_17_k7_zdravlje_tg1',
                'o7_17_k7_zdravlje_tg0',
                'o7_17_k7_zdravlje_ocena',
            ],
        },
    ]

    const allFields: (keyof Form7_17Entity)[] = [
        ...rows.reduce((acc, row) => [...acc, ...row.fields], [] as (keyof Form7_17Entity)[]),
        'o7_17_k7_indeks_tg0',
        'o7_17_k7_indeks_tg1',
        'o7_17_k7_indeksRR',
    ]

    const goToNextStep = () => {
        const hasEmptyFields = (watchedValues?.o7_17_k7_fileDescription ?? []).some(({ fileName, type }) => !fileName || !type)
        const hasEmptyTableFields = allFields.some((e) => watchedValues[e] === null || watchedValues[e] === undefined || watchedValues[e]?.toString() === '')

        const disabledNext = watchedValues[name]?.length === 0 || hasEmptyTableFields

        if (!hasEmptyFields && !disabledNext && !validateEmptyArray()) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <div className="flex flex-col justify-between mb-[40px]">Индекс родне равноправности у РС</div>
            <ComplexTable7_17 rows={rows} control={control} header={headers} errors={errors} disabled={adminView} />
            <div className="flex flex-col justify-between mb-[40px] mt-[60px]">Индекс родне равноправности у години извештавања</div>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_17_k7_indeks_tg0" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} decimal />
                    {errors['o7_17_k7_indeks_tg0']?.type === 'required' && (
                        <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 w-[200px]">{'Попуните обавезно поље.'}</p>
                    )}
                </div>
            </div>
            <div className="flex flex-col justify-between mb-[40px]">Индекс родне равноправности у претходној години</div>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_17_k7_indeks_tg1" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} decimal />
                    {errors['o7_17_k7_indeks_tg1']?.type === 'required' && (
                        <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 w-[200px]">{'Попуните обавезно поље.'}</p>
                    )}
                </div>
            </div>
            <div className="flex flex-col justify-between mb-[40px]">Апсолутна вредност промене индекса</div>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell
                        name=""
                        control={control}
                        errors={errors}
                        isRequired={false}
                        height={52}
                        disabled
                        decimal
                        placeholder={Math.abs((watchedValues?.o7_17_k7_indeks_tg0 ?? 0) - (watchedValues?.o7_17_k7_indeks_tg1 ?? 0)).toFixed(2)}
                    />
                </div>
            </div>
            <div className="flex flex-col justify-between mb-[40px]">Процентуална промена индекса</div>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell
                        name=""
                        control={control}
                        errors={errors}
                        isRequired={false}
                        height={52}
                        disabled
                        decimal
                        precentage
                        placeholder={getPercentageDifference(watchedValues?.o7_17_k7_indeks_tg0 ?? 0, watchedValues?.o7_17_k7_indeks_tg1 ?? 0).toFixed(2)}
                    />
                </div>
            </div>
            <div className="flex flex-col justify-between mb-[40px]">Вредност индекса РР за текућу годину у ЕУ 27</div>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_17_k7_indeksRR" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} decimal />
                    {errors['o7_17_k7_indeksRR']?.type === 'required' && (
                        <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 w-[200px]">{'Попуните обавезно поље.'}</p>
                    )}
                </div>
            </div>
            <div className="flex flex-col justify-between mb-[40px]">Апсолутна вредност разлике индекса у односу на ЕУ 27</div>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell
                        name=""
                        control={control}
                        errors={errors}
                        isRequired={false}
                        height={52}
                        disabled
                        decimal
                        placeholder={Math.abs((watchedValues?.o7_17_k7_indeks_tg0 ?? 0) - (watchedValues?.o7_17_k7_indeksRR ?? 0)).toFixed(2)}
                    />
                </div>
            </div>

            <div className="flex flex-col justify-between mb-[40px] mt-[80px]">
                <h1 className="step-name mb-[10px]">Молимо вас да приложите последњи објављен извештај о индексу родне равноправности</h1>
            </div>
            <FileDescriptionTable header={header} fields={fields} control={control} errors={errors} name={name} adminView={adminView} remove={remove} />

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>

            <div className="my-10 gap-10">
                <h1>Молимо Вас приложите планове за горе наведене програме ако нема јавно доступног линка</h1>
                <UploadFile control={control} name={'o7_17_k7_file'} disabled={adminView} form7 />
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} isAdmin={adminView} />
        </div>
    )
}
