import React, { useState } from 'react'
import { StepInterface } from './Step1'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_13Entity } from '../../../../../models/Form7/Entities/Form7_13.entity'
import { useFieldArray, useWatch } from 'react-hook-form'
import { getPercentage } from '../../../../../utils/mathUtils'
import ActionButtons from '../../../../../components/ActionButtons'
import TextTable from '../../../../Form2/Components/TextTable'
import { Button } from '@mui/material'

export const Step2 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_13Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_13_k2_kampanje',
    })
    const firstArrayName = 'o7_13_k2_kampanje'

    const headers = ['Број', 'Жене', 'Жене %', 'Мушкарци', 'Мушкарци %']

    const rows = ['o7_13_k2_z', 'o7_13_k2_m']

    const numberFields: ('o7_13_k2_z' | 'o7_13_k2_m')[] = ['o7_13_k2_z', 'o7_13_k2_m']

    let totalSum

    if (watchedValues) {
        totalSum = (watchedValues.o7_13_k2_z || 0) + (watchedValues.o7_13_k2_m || 0)
    }

    const addArrayField = () => {
        setEmptyArrayError(false)
        append({ text_content: '' })
    }

    const validateArray = (): boolean => {
        if (!watchedValues.o7_13_k2_kampanje || watchedValues.o7_13_k2_kampanje.length === 0) {
            return true
        }

        const newMap = watchedValues.o7_13_k2_kampanje?.map((field) => field.text_content !== '')
        return newMap?.includes(false) ? true : false
    }

    const disableArray = validateArray()
    const disabledNext = !numberFields.every((e) => {
        return watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== ''
    })

    const validateEmptyArray = () => {
        if (watchedValues[firstArrayName]?.length === 0 || watchedValues[firstArrayName] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !disableArray && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name mb-[10px]">
                Број обучених представника/ца јавне власти и чланова/чланица механизама РР о родно заснованим стереотипима, родној равноправности, и родно заснованој
                дискриминацији, уродњавање јавних политика и закона, посебно жена из друштвено осетљивих група, разврстано по полу, институцији, и нивоима организације на којима су
                образовани
            </h1>
            <table className="simple-table mt-[30px]">
                <thead>
                    <tr className="simple-table-header">
                        {headers.map((head, index) => (
                            <th className="simple-table-cell" key={index}>
                                {head}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr className="simple-table-row">
                        <td className="simple-table-cell disabled  !mt-[8px]">Број ТГ</td>
                        <td className="simple-table-cell editable !border-none  !mt-[8px]" style={{ border: 0, backgroundColor: '', padding: 0 }}>
                            <PositiveNumberInputCell name={rows[0]} control={control} isMultipleTable={true} errors={errors} isRequired={!adminView} disabled={adminView} />
                        </td>
                        <td className="simple-table-cell disabled  !mt-[8px]">{getPercentage(watchedValues.o7_13_k2_z || 0, totalSum)}%</td>
                        <td className="simple-table-cell editable !border-none  !mt-[8px]" style={{ border: 0, backgroundColor: '', padding: 0 }}>
                            <PositiveNumberInputCell name={rows[1]} control={control} isMultipleTable={true} errors={errors} isRequired={!adminView} disabled={adminView} />
                        </td>
                        <td className="simple-table-cell disabled  !mt-[8px]">{getPercentage(watchedValues.o7_13_k2_m || 0, totalSum)}%</td>
                    </tr>
                </tbody>
            </table>
            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px]">{errors.o7_13_k2_z || errors.o7_13_k2_m ? 'Попуните обавезно поље!' : ''}</span>
            <h1 className="step-name my-[10px]">
                Број и називи информативних јавних кампања о улози, важности и начину функционисања механизама РР на свим нивоима и учешћу грађана и грађанки у процесима планирања
                и реализације родно одговорних јавних политика на свим нивоима
            </h1>
            <div className="mt-[50px]">
                <div>
                    <TextTable
                        control={control}
                        name={firstArrayName}
                        errors={errors}
                        fields={fields}
                        remove={remove}
                        label={'Називи информативних кампања'}
                        maxChar={200}
                        disabled={adminView}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors && errors?.[firstArrayName] ? 'Попуните обавезно поље!' : ''}</span>
                </div>
                <div className="flex flex-col">
                    <Button variant="contained" className="max-w-[172px]" onClick={addArrayField} style={{ marginTop: 20 }} disabled={adminView}>
                        Додај назив
                    </Button>
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
                </div>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
