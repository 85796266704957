import { ManagementPosition } from '../ManagementPosition.entity'
import { Organization } from '../Organization.entity'
import { SubmittedBy } from '../SubmittedBy.entity'
import { TextDescription } from '../TextDescription.entity'
import { UnionPartyBody } from '../UnionPartyBody.entity'

export const NumberOfSteps = 7
export type TblOblast5Keys = keyof TblOblast5

export interface TblOblast5 {
    report_id?: string
    rep_year?: number // year
    submitted_date?: Date
    submitted_place?: string
    category?: string // category
    organization?: Organization
    status?: number // rep_status
    // Korak 1
    o5_k1_r1?: string // yesno
    o5_k1_r2?: string // yesno
    o5_k1_r3?: string // yesno
    // Korak 2
    o5_k2_naziv?: string | null // nvarchar(200)
    o5_k2_citat?: string | null // nvarchar(300)
    // Korak 3
    o5_k3_r1?: string // yesno
    o5_k3_submitted_date?: Date | undefined | null // date
    o5_k3_r2?: string // yesno
    o5_k3_link?: string | null // nvarchar(200)
    // Korak 4
    o5_k4_mere?: TextDescription[]
    // Korak 5
    o5_k5_r1?: string // yesno
    o5_k5_razlog?: string | null // nvarchar(200)
    // Korak 6
    o5_k6_pozicije?: ManagementPosition[]
    o5_k6_tela?: UnionPartyBody[]
    // Korak 7
    submitted_by?: SubmittedBy // uuid

    o5_k7_firstName?: string

    o5_k7_lastName?: string

    o5_k7_phoneNumber?: string

    o5_k7_email?: string
}

export enum YESNO {
    'Da' = 'Да',
    'Ne' = 'Не',
}

export enum OrganiStranke {
    Izvrsni = 'Извршни',
    Upravni = 'Управни',
    Nadzorni = 'Надзорни',
    Nema = 'Нема тела',
}
