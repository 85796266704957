import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { ConstantProgram } from './Common/ConstantProgram.entity'
import { FileDescription } from './Common/FileDescription.entity'
import { Sector } from './Common/Sector.entity'

export const NumberOfSteps = 5
export interface Form7_5Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string

    //sektori:
    o7_5_k1_prezivljavanje: Sector[]

    o7_5_k2_vlasnistvo: Sector[]

    o7_5_k3_programs: ConstantProgram[]

    o7_5_k3_z: number // not required (%)

    o7_5_k4_fileDescriptions: FileDescription[]

    o7_5_k4_file: File[]

    submitted_by?: SubmittedBy
}
