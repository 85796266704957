import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useUser } from '../../auth/UseUser'
import HelpButton from '../../components/Common/HelpButton'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { getFinishedForms, getRemainingForm, getStartedForms } from '../../server/server'
import { STATUS } from '../../models/CommonTypes'
import TutorialModal from '../Tutorial/TutorialModal'

const headerMapping = [
    { header: 'Година извештаја', value: 'year' },
    // { header: 'Категорија подносиоца', value: 'category' },
    // { header: 'Подкатегорија подносиоца', value: 'subCategory' },
    { header: 'Име овлашћеног лица', value: 'name', width: 300 },
    { header: 'Статус извештаја', value: 'status' },
    { header: 'Образац 1', value: 'f1' },
    { header: 'Образац 2', value: 'f2' },
    { header: 'Образац 3', value: 'f3' },
    { header: 'Образац 4', value: 'f4' },
    { header: 'Образац 5', value: 'f5' },
    { header: 'Образац 6', value: 'f6' },
    { header: 'Образац 7-1', value: 'f7' },
    { header: 'Образац 7-2', value: 'f7' },
    { header: 'Образац 7-3', value: 'f7' },
    { header: 'Образац 7-4', value: 'f7' },
    { header: 'Образац 7-5', value: 'f7' },
    { header: 'Образац 7-6', value: 'f7' },
    { header: 'Образац 7-7', value: 'f7' },
    { header: 'Образац 7-8', value: 'f7' },
    { header: 'Образац 7-9', value: 'f7' },
    { header: 'Образац 7-10', value: 'f7' },
    { header: 'Образац 7-11', value: 'f7' },
    { header: 'Образац 7-12', value: 'f7' },
    { header: 'Образац 7-13', value: 'f7' },
    { header: 'Образац 7-14-1', value: 'f7' },
    { header: 'Образац 7-14-2', value: 'f7' },
    { header: 'Образац 7-15', value: 'f7' },
    { header: 'Образац 7-16', value: 'f7' },
    { header: 'Образац 7-17', value: 'f7' },
    { header: 'Образац 7-18', value: 'f7' },
    { header: 'Образац 7-19', value: 'f7' },
]

function HomeScreen() {
    const { user } = useUser()
    const navigate = useNavigate()
    const [remainingForms, setRemainingForms] = useState<any[]>([])
    const [startedForms, setStartedForm] = useState<any[]>([])
    const [finishedForms, setFinishedForms] = useState<any[]>([])
    const [completeForms, setCompleteForms] = useState<any[]>([])
    const [tutorialModalOpen, setTutorialModalOpen] = useState(true)
    const [forceOpen, setForceOpen] = useState(false)
    const [showStatusForms, setShowStatusForms] = useState(false)

    useEffect(() => {
        loadRemainingForms()
        loadStartedForms()
        loadFinishedForms()
    }, [])

    const loadRemainingForms = async () => {
        const resp = await getRemainingForm()
        if (resp.status === 200) {
            setRemainingForms(resp.data)
        }
    }
    const loadStartedForms = async () => {
        const resp = await getStartedForms()
        if (resp.status === 200) {
            setStartedForm(resp.data)
        }
    }
    const loadFinishedForms = async () => {
        const resp = await getFinishedForms()
        console.log('🚀 ~ loadFinishedForms ~ resp:', resp)
        if (resp.status === 200) {
            setFinishedForms(resp.data.reports)
            setCompleteForms(resp.data.submittedForms)
            setShowStatusForms(resp?.data?.reports?.length > 0)
        }
    }
    // <div className="!w-full px-[100px] bg-[#ffffff] pt-[52px] relative">
    return (
        <div className="max-w-[1556px] mx-[auto] px-[40px] pb-[20px] pt-[52px] relative">
            <div className="absolute top-5 right-10 ">
                <div>
                    <button
                        className="flex flex-row items-center justify-center gap-2 border border-[#909090] hover:border-[#202020] rounded-full h-8 pl-[5px] pr-[8px] py-[4px] transition"
                        onClick={() => {
                            setForceOpen(true)
                            setTutorialModalOpen(true)
                        }}
                        type="button"
                    >
                        <div className="flex items-center justify-center min-w-6 h-6 rounded-full bg-[#606060]">
                            <p className="font-bold text-white text-[16px] leading-4 mt-[1px]">?</p>
                        </div>
                        <p className="text-[#606060] text-[16px] leading-5">Упутство</p>
                    </button>
                </div>
            </div>

            <h2 className="text-[24px] font-light max-w-[1200px]">
                Подношење извештаја за: <b className="text-[24px]">{user.subjectName}</b> за{' '}
                {new Date().getMonth() === 0 && new Date().getDate() <= 15 ? new Date().getFullYear() - 1 : new Date().getFullYear()} годину
            </h2>
            <div className="pt-[10px]">
                {remainingForms.length > 0 && (
                    <>
                        <p>Потребно је да попуните следеће обрасце:</p>
                        <div className="pb-[40px] pt-[20px] gap-[10px] flex flex-row">
                            {remainingForms.map((button, index) => (
                                <ObrazacButton key={index} path={button.path} text={button.name} />
                            ))}
                        </div>
                    </>
                )}
            </div>
            {!!startedForms.length && (
                <>
                    <div className="flex flex-row items-center gap-4">
                        <h2 className="text-[24px] font-light max-w-[800px]">Започети обрасци</h2>
                    </div>
                    <div className="pt-[10px]">
                        <p>Потребно је да завршите унос следећих образаца:</p>

                        <div className="pb-[40px] pt-[20px] gap-[10px] flex flex-row">
                            {startedForms.map((button, index) => (
                                <ObrazacButton key={index} path={button.path} text={button.name} />
                            ))}
                        </div>
                    </div>
                </>
            )}
            {showStatusForms && (
                <>
                    <div className="flex flex-row items-center gap-4">
                        <h2 className="text-[24px] font-light max-w-[800px]">Статус извештаја</h2>
                    </div>
                    <div className="!w-full py-10 min-h-full">
                        <Paper>
                            <TableContainer>
                                <Table className="overflow-x-auto w-full">
                                    <TableRow>
                                        {headerMapping.map((item, index) => (
                                            <TableCell className="min-w-[150px]" key={index} style={{ minWidth: item.width ?? 150 }}>
                                                <div
                                                    className={`!flex !flex-row justify-center items-center px-2 text-center ${
                                                        item.header.includes('бра') ? 'justify-center' : 'justify-between'
                                                    } `}
                                                >
                                                    {item.header}
                                                </div>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableBody>
                                        {finishedForms.map((r) => {
                                            return (
                                                <TableRow key={r.id}>
                                                    <TableCell height={52}>
                                                        <p className="px-2 text-center">{r.report_year}</p>
                                                    </TableCell>
                                                    <TableCell height={52} width={400}>
                                                        <p className="px-2">{`${r.submittedBy.firstName} ${r.submittedBy.lastName}`}</p>
                                                    </TableCell>
                                                    <TableCell height={52} align="center">
                                                        {STATUS[r.status as keyof typeof STATUS]}
                                                    </TableCell>
                                                    {r?.obrasci?.map((items: any, index: number) => {
                                                        const item = !!items?.length ? items[0] : {}
                                                        return (
                                                            <TableCell key={index} align="center" className={`!min-w-[150px] !max-w-[150px]`}>
                                                                {!!item.report_id ? (
                                                                    <Button
                                                                        style={{ width: '94px', backgroundColor: '#0D47A1' }}
                                                                        key={item.id}
                                                                        onClick={() => {
                                                                            const addonString = item.orgType === 'stranka' || item.orgType === 'sindikat' ? `/${item.type}` : ''

                                                                            navigate(`/${item.path}?formId=${item.report_id}`)
                                                                        }}
                                                                        variant="contained"
                                                                    >
                                                                        Прегледај
                                                                    </Button>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </>
            )}
            <TutorialModal open={tutorialModalOpen} onClose={() => setTutorialModalOpen(false)} segment="home" forceOpen={forceOpen} />
        </div>
    )
}

export const ObrazacButton = ({ path, text, color = '#32a869', disabled = false }: { path: string; text: string; color?: string; disabled?: boolean }) => {
    const navigate = useNavigate()
    return (
        <Button variant="contained" style={{ backgroundColor: disabled ? '#e0e0e0' : '#0D47A1' }} disabled={disabled} onClick={() => navigate(path)}>
            {text}
        </Button>
    )
}
export default HomeScreen
