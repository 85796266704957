import React, { useEffect, useState } from 'react'
import { StepInterface } from './Step1'
import CheckboxComponent from '../../../Components/CheckboxComponent'
import Textbox from '../../../../../components/Common/Textbox'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_6Entity } from '../../../../../models/Form7/Entities/Form7_6.entity'
import { useWatch } from 'react-hook-form'

const titles = [
    'Недостатак свести',
    'Нејасна регулатива',
    'Лош софтвер за планирање',
    'Недостатак обуке',
    'Организационе препреке',
    'Недовољно времена',
    'Недовољно средстава у буџету',
]

export const Step7 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors, setError }: StepInterface) => {
    const [hasCompletedRequired, setHasCompletedRequired] = useState(false)
    const watchedValues = useWatch<Form7_6Entity>({ control })
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const allFields: ('o7_6_k7_svest' | 'o7_6_k7_regulative' | 'o7_6_k7_softver' | 'o7_6_k7_obuka' | 'o7_6_k7_prepreke' | 'o7_6_k7_vreme' | 'o7_6_k7_budzet' | 'o7_6_k7_drugo')[] =
        ['o7_6_k7_svest', 'o7_6_k7_regulative', 'o7_6_k7_softver', 'o7_6_k7_obuka', 'o7_6_k7_prepreke', 'o7_6_k7_vreme', 'o7_6_k7_budzet', 'o7_6_k7_drugo']

    const fields: ('o7_6_k7_svest' | 'o7_6_k7_regulative' | 'o7_6_k7_softver' | 'o7_6_k7_obuka' | 'o7_6_k7_prepreke' | 'o7_6_k7_vreme' | 'o7_6_k7_budzet')[] = [
        'o7_6_k7_svest',
        'o7_6_k7_regulative',
        'o7_6_k7_softver',
        'o7_6_k7_obuka',
        'o7_6_k7_prepreke',
        'o7_6_k7_vreme',
        'o7_6_k7_budzet',
    ]

    const hasCheckedAnyBox = fields.some((field) => watchedValues[field])
    const hasEnteredText = !!watchedValues.o7_6_k7_drugo?.toString().trim()

    useEffect(() => {
        const hasCompletedRequired = hasCheckedAnyBox || hasEnteredText
        setHasCompletedRequired(hasCompletedRequired)

        if (hasCompletedRequired) {
            clearErrors(allFields)
            setErrorMessage('')
        }
    }, [hasCheckedAnyBox, hasEnteredText])

    const disabledNext = !hasCompletedRequired

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
        else {
            setErrorMessage('Молимо Вас да одаберете једну од понуђених опција или унесете Вашу препреку у текстуално поље.')
        }
    }

    const validateAllFields = () => {
        if (!hasCheckedAnyBox || !hasEnteredText) {
            fields.forEach((field) => {
                setError(field, {
                    type: 'required',
                    message: 'Обавезно поље.',
                })
                setErrorMessage('Молимо Вас да одаберете једну од понуђених опција или унесете Вашу препреку у текстуално поље.')
            })

            setError('o7_6_k7_drugo', {
                type: 'required',
                message: 'Обавезно поље.',
            })
        } else {
            setErrorMessage('')
            clearErrors([...fields, 'o7_6_k7_drugo'])
        }
    }
    return (
        <div>
            <h1 className="mb-[20px]">
                Шта је по вама највећа препрека или изазов за обезбеђење социјалне сигурности кроз пружање услуга социјалне заштите квалитета живота, благостања жена и мушкараца из
                рањивих група, као и подршке породицама у задовољењу животних потреба?
            </h1>
            <div className="grid grid-cols-3 gap-[20px]">
                {fields.map((field, index) => (
                    <CheckboxComponent
                        key={field}
                        control={control}
                        errors={errors}
                        isRequired={!hasEnteredText || !adminView}
                        name={field}
                        text={titles[index]}
                        onValidate={validateAllFields}
                        disabled={hasEnteredText || adminView}
                    />
                ))}
            </div>
            <div className="mt-[50px]">
                <h4 className="mb-[10px] text-disabled">Друго - молим Вас да упишете:</h4>
                <Textbox
                    name="o7_6_k7_drugo"
                    control={control}
                    placeholder="Текст (200 карактера)*"
                    maxNumberOfCharacters={200}
                    errors={errors}
                    isRequired={!hasCheckedAnyBox ? 'Попуните обавезно поље.' : false}
                    disabled={adminView || hasCheckedAnyBox}
                />
            </div>
            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px]">{errorMessage}</span>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
