import React from 'react'
import { StepInterface } from './Step1'
import ComplexReductionTable from '../../../Components/ComplexSubtractionTable'
import { Form7_19Entity } from '../../../../../models/Form7/Entities/Form7_19.entity'
import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'

export interface RowInterface {
    label: string
    fields: (keyof Form7_19Entity)[]
}

const rows: RowInterface[] = [
    { label: 'Број ТГ', fields: ['o7_19_k3_z1', 'o7_19_k3_m1'] },
    { label: 'Број ТГ-1', fields: ['o7_19_k3_z2', 'o7_19_k3_m2'] },
]

const allFields = ['o7_19_k3_z1', 'o7_19_k3_z2', 'o7_19_k3_m1', 'o7_19_k3_m2'] as const

const allMaleFields = [...allFields.filter((e) => e.includes('_m'))] as const
const allFemaleFields = [...allFields.filter((e) => e.includes('_z'))] as const

type AllFieldsType = (typeof allFields)[number]

export const Step3 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_19Entity>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const disabledNext = !fields.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">Проценат жена у заштити интелектуалне својине и патената, по врсти заштите</h1>

            <ComplexReductionTable
                rows={rows}
                control={control}
                allFemaleFields={[...allFemaleFields]}
                allMaleFields={[...allMaleFields]}
                allFields={[...allFields]}
                header={['Број', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Број жена С100 на 100 мушких']}
                errors={errors}
                disabled={adminView}
            />

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
