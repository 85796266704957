import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { ReportedCases } from '../../../models/ReportedCases.entity'
import '../../../assets/styles/SimpleTable.css'
import { PositiveNumberInputState } from '../../../components/Common/PositiveNumberInput'
import { Button } from '@mui/material'
import SelectComponent from '../../../components/Common/SelectComponent'
import { StepInterface } from './Step1'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControlWithError'

function Step17({ control, nextStep, previousStep, previousButtonDisabled = false, adminView, errors }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })
    const [newEntity, setNewEntity] = useState<ReportedCases>({})
    const [reset, setReset] = useState(Date.now())
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o1_k17_reportedCases',
    })
    const [error, setError] = useState<any>({
        active: false,
        text: '',
        field: '',
    })

    const handleAddField = () => {
        append(newEntity)
        setNewEntity({})
        setReset(Date.now())
        setError({ active: false, text: '' })
    }

    const updateEntity = (value: any, field: 'occurrences_no' | 'violence_form' | 'victim_gender' | 'perpetrator_gender' | 'relation') => {
        setNewEntity((prev) => ({ ...prev, [field]: value }))
    }

    const disabledNext = watchedValues.o1_k17_reportedCases?.length === 0 || watchedValues?.o1_k17_reportedCases?.length === undefined
    const allFields: ('occurrences_no' | 'occurrences_no' | 'violence_form' | 'victim_gender' | 'perpetrator_gender' | 'relation')[] = [
        'occurrences_no',
        'occurrences_no',
        'violence_form',
        'victim_gender',
        'perpetrator_gender',
        'relation',
    ]
    const disabledAdd = !allFields.every((e) => !!newEntity[e] || newEntity.occurrences_no === 0)
    const disableTable = watchedValues.o1_k17_noCases === 1

    const goToNextStep = () => {
        if (!disableTable) {
            const emptyFields = allFields.filter((field) => newEntity[field] === null || newEntity[field] === undefined || newEntity[field]?.toString() === '')
            if (adminView) {
                nextStep()
            } else if (newEntity.occurrences_no === 0) {
                setError({
                    active: true,
                    text: 'Додајте случај',
                    field: '',
                })
            } else if (newEntity.occurrences_no !== 0 && disabledNext && disabledAdd) {
                setError({
                    active: true,
                    text: 'Попуните обавезно поље.',
                    field: `${emptyFields.join(', ')}`,
                })
            } else if (watchedValues.o1_k17_reportedCases && watchedValues?.o1_k17_reportedCases?.length > 0 && !disabledAdd) {
                setError({
                    active: true,
                    text: 'Додајте случај',
                })
            } else if (!disabledAdd) {
                setError({
                    active: false,
                    text: 'Додајте случај',
                })
            } else {
                setError({
                    active: false,
                    text: '',
                })
                nextStep()
            }
        } else if (disableTable) {
            nextStep()
        }
    }
    const removeErrorField = (fieldToRemove: string, error: any) => {
        if (error.field) {
            const updatedFields = error.field.split(', ').filter((field: any) => field !== fieldToRemove)

            if (updatedFields.length === 0) {
                return {}
            }
            return {
                ...error,
                field: updatedFields.join(', '),
            }
        }
        return error
    }

    useEffect(() => {
        setError({})
    }, [watchedValues.o1_k17_noCases])
    return (
        <div>
            <div>
                <h1 className="step-name">Да ли је у години вођења евиденције било пријављених случајева насиља у органу јавне власти или код послодавца?</h1>
                <RadioButtonsControl control={control} name={'o1_k17_noCases'} options={['Да', 'Не']} errors={errors} enumerated disabled={adminView} />
            </div>
            <div className="mt-[20px]">
                <h1 className="step-name">
                    17. Број пријављених случајева насиља у органу јавне власти или код послодавца по основу пола, односно рода, разврстаних по облику насиља, полу жртве насиља и
                    полу извршиоца насиља, као и међусобном односу жртве и извршиоца насиља, у години вођења евиденције:
                </h1>
                <div>
                    <table className="simple-table mt-[30px]">
                        <tr className="simple-table-header">
                            <th className="simple-table-cell min-w-[300px]">Пријављени случајеви</th>
                            <th className="simple-table-cell !w-[186px]">Облик насиља</th>
                            <th className="simple-table-cell !w-[186px]">Пол жртве</th>
                            <th className="simple-table-cell !w-[186px]">Пол учиоца</th>
                            <th className="simple-table-cell !w-[186px]">Међусобни однос</th>
                        </tr>
                        {fields.map((row, index) => (
                            <tr className="simple-table-row">
                                <td className="simple-table-cell disabled !w-[146px]">{row.occurrences_no}</td>
                                <td className="simple-table-cell disabled !w-[146px]">{row.occurrences_no}%</td>
                                <td className="simple-table-cell disabled !w-[186px]">{row.violence_form}</td>
                                <td className="simple-table-cell disabled !w-[186px]">{row.victim_gender}</td>
                                <td className="simple-table-cell disabled !w-[186px]">{row.perpetrator_gender}</td>
                                <td className="simple-table-cell disabled !w-[186px]">{row.relation}</td>
                                <td className="!border-none">
                                    <Button color="error" variant="contained" onClick={() => remove(index)} disabled={adminView}>
                                        Обриши
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>

                <div className="mt-[50px] flex flex-row gap-[8px]">
                    <div className="w-[300px] flex flex-row items-center gap-[8px]">
                        <div
                            className={`border ${
                                error.field && error.field.includes('occurrences_no') ? 'border-[#d32f2f]' : 'border-[#00000010]'
                            } w-[140px] h-[50px] flex items-center justify-center`}
                        >
                            <PositiveNumberInputState
                                disabled={adminView || disableTable}
                                value={`${newEntity?.occurrences_no ?? ''}`}
                                onChange={(text) => {
                                    updateEntity(Number(text), 'occurrences_no')
                                    if (text === '0' || disableTable) {
                                        setError({})
                                    } else {
                                        setError((prevError: any) => removeErrorField('occurrences_no', prevError))
                                    }
                                }}
                            />
                        </div>
                        <div className={`border border-[#00000010] w-[140px] h-[50px] flex items-center justify-center`}>{`${newEntity?.occurrences_no ?? ''}`}%</div>
                    </div>

                    <SelectComponent
                        disabled={adminView || disableTable}
                        value={newEntity?.violence_form}
                        onChange={(text) => {
                            updateEntity(text, 'violence_form')
                            setError((prevError: any) => removeErrorField('violence_form', prevError))
                        }}
                        elements={['Психичко насиље', 'Физичко насиље', 'Сексуално узнемиравање', 'Друго']}
                        key={reset}
                        error={error.field?.includes('violence_form')}
                    />
                    <SelectComponent
                        disabled={adminView || disableTable}
                        value={newEntity?.victim_gender}
                        onChange={(text) => {
                            updateEntity(text, 'victim_gender')
                            setError((prevError: any) => removeErrorField('victim_gender', prevError))
                        }}
                        elements={['Мушкарац', 'Жена']}
                        key={reset + 1}
                        error={error.field?.includes('victim_gender')}
                    />
                    <SelectComponent
                        disabled={adminView || disableTable}
                        value={newEntity?.perpetrator_gender}
                        onChange={(text) => {
                            updateEntity(text, 'perpetrator_gender')
                            setError((prevError: any) => removeErrorField('perpetrator_gender', prevError))
                        }}
                        elements={['Мушкарац', 'Жена']}
                        key={reset + 2}
                        error={error.field?.includes('perpetrator_gender')}
                    />
                    <SelectComponent
                        disabled={adminView || disableTable}
                        value={newEntity?.relation}
                        onChange={(text) => {
                            updateEntity(text, 'relation')
                            setError((prevError: any) => removeErrorField('relation', prevError))
                        }}
                        elements={['Надређени', 'Подређени', 'Колеге', 'Друго']}
                        key={reset + 3}
                        error={error.field?.includes('relation')}
                    />
                </div>
                <div className="h-[26px]">{error.text == 'Попуните обавезно поље.' && <p className="text leading-5 mt-[10px] text-[#d32f2f] text-[0.75rem]">{error.text}</p>}</div>
                <div className="mt-[50px]">
                    <Button variant="contained" onClick={handleAddField} disabled={disabledAdd || disableTable}>
                        Додај случај
                    </Button>
                    <div className="h-[26px]">
                        {error.text == 'Додајте случај' && <p className="text leading-5 ml-[15px] mt-[5px] text-[#d32f2f] text-[0.75rem]">{error.text}</p>}
                    </div>
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step17
