import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input, Box, Button, Typography } from '@mui/material'
import { removeLeadingZeros } from '../../../utils/mathUtils'

interface PositiveNumberInputInterface {
    name: string
    control?: any
    defaultValue?: string
    decimal?: boolean
    isMultipleTable?: boolean
    height?: number
    borderColor?: string
    errors?: any
    isRequired?: any
    isArray?: any
    isStep5?: boolean
    precentage?: boolean
    max?: number
    min?: number
    maxW?: number
    disabled?: boolean
    placeholder?: string
}

function TutorialNumberInput({
    name,
    control,
    defaultValue = '',
    decimal = false,
    isMultipleTable,
    errors = {},
    isRequired = false,
    height,
    borderColor = '#00000010',
    isArray = false,
    precentage = false,
    max,
    min,
    disabled = false,
    maxW = 75,
    placeholder = 'Цео број*',
}: PositiveNumberInputInterface) {
    const [shownValue, setShowValue] = useState<string | undefined>(defaultValue)

    const parts = name.split('.')
    const arrayName = parts[0]
    const index = parseInt(parts[1], 10)
    const fieldName = parts[2]

    const getBorderClass = () => {
        if ((!isArray && errors && errors[name]) || (!isArray && errors && errors[arrayName])) {
            return 'border-[#d32f2f]'
        } else if (errors && !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName]) {
            return 'border-[#d32f2f]'
        } else {
            return 'border-[#D9D9D9]'
        }
    }
    function getPlaceholder(decimal: boolean, percentage: boolean, isRequired: boolean) {
        const suffix = isRequired ? '*' : ''
        if (placeholder === 'Цео број*' || placeholder === 'Цео број') {
            if (percentage) {
                return `0-100${suffix}`
            } else if (decimal) {
                return `0.00${suffix}`
            } else {
                return `Цео број${suffix}`
            }
        } else {
            return placeholder
        }
    }

    const getColor = () => {
        if (isArray) {
            return !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName] ? '#d32f2f' : 'inherit'
        } else {
            return !!errors[arrayName] ? '#d32f2f' : 'inherit'
        }
    }

    return (
        <div className="flex flex-row h-full w-full">
            <div
                className={` cursor-default border ${getBorderClass()} w-full flex justify-center items-center ${
                    maxW ? `max-w-[${maxW}px] min-w-[${maxW}px] p-[1px]` : 'min-w-[100px] p-[15px]'
                }`}
                style={{ height: height ? height : '100%' }}
            >
                <Input
                    disabled
                    type="tel"
                    value={shownValue}
                    className="!bg-[transparent] border-none"
                    placeholder={getPlaceholder(decimal, precentage, isRequired)}
                    style={{ fontSize: isMultipleTable ? '14px' : '', width: '100%' }}
                    sx={{
                        textAlign: 'right',
                        '& .MuiInputBase-input': {
                            textAlign: 'right',
                            padding: '12px',
                            color: getColor(),
                            '&::placeholder': {
                                color: getColor(),
                                opacity: 1,
                            },
                        },
                        '&.Mui-disabled': {
                            '& .MuiInputBase-input::placeholder': {
                                color: getColor(),
                                opacity: 1,
                            },
                        },
                        '&.MuiInputBase-root:before': {
                            borderBottom: 'none',
                        },
                        '&.MuiInputBase-root:after': {
                            borderBottom: 'none',
                        },
                        '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            borderBottom: 'none',
                        },
                    }}
                />
            </div>
            {precentage && (
                <Typography
                    variant="body1"
                    style={{
                        color: getColor(),
                        marginLeft: '8px',
                        marginTop: '12px',
                        fontSize: '18px',
                        opacity: disabled ? 0.5 : 1,
                    }}
                >
                    %
                </Typography>
            )}
        </div>
    )
}

export default TutorialNumberInput
