import React, { useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_3Entity } from '../../../../../models/Form7/Entities/Form7_3.entity'

export interface StepInterface {
    control?: Control<Form7_3Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

interface RowInterface {
    label: string
    fields: (keyof Form7_3Entity)[]
}

const rowsTable1: RowInterface[] = [
    { label: 'Инвалидске пензије', fields: ['o7_3_k7_z_invalidske', 'o7_3_k7_m_invalidske'] },
    { label: 'Старосне пензије', fields: ['o7_3_k7_z_starosne', 'o7_3_k7_m_starosne'] },
]

const headerTable1: string[] = ['Просечне пензије', 'Жене', 'Мушкарци']
const allFieldsTable1 = ['o7_3_k7_z_invalidske', 'o7_3_k7_z_starosne', 'o7_3_k7_m_invalidske', 'o7_3_k7_m_starosne'] as const

type AllFieldsType = (typeof allFieldsTable1)[number]

function Step5({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_3Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...allFieldsTable1]
    const [disabled, setDisabled] = useState(false)
    const hasRowErrorsTable1 = rowsTable1.some((row) => row.fields.some((field) => errors?.[field]))

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">7.Просечне пензије у хиљадама динара:</h1>
            <table className="complex-table mt-[30px]">
                <tr className="complex-table-header">
                    {headerTable1.map((head, index) => (
                        <th className="complex-table-cell" style={{ width: index === 0 ? 250 : 200 }} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {rowsTable1.map((row, index) => (
                    <tr className="complex-table-row" key={index}>
                        <td className="complex-table-cell  disabled" style={{ width: 250 }}>
                            {row.label}
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[0] as string}
                                control={control}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={adminView}
                                decimal
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[1] as string}
                                control={control}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={adminView}
                                decimal
                            />
                        </td>
                    </tr>
                ))}
                <div className="h-[20px]"> {hasRowErrorsTable1 && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step5
