import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'

export const NumberOfSteps = 7

export interface Form7_11Entity {
    report_id?: string
    rep_year?: number
    submitted_date?: Date
    submitted_place?: Date
    category?: string
    organisation?: Organization
    status?: string

    // Step 1
    o7_11_k1_fileDescriptions: FileDescription[]
    o7_11_k1_files?: File[]

    //Step 2
    o7_11_k2_prijave?: Prijave[]

    //Step 3
    o7_11_k3_oblici?: Oblik[]
    //Step4
    o7_11_k4_krivicnePrijave?: TrainingReport[]
    o7_11_k4_pravosnaznePresude?: TrainingReport[]
    // Step 5
    o7_11_k5_organizacije?: TrainingReport[]

    // Step 6
    o7_11_k6_programi?: TrainingReport[]

    submitted_by?: SubmittedBy
}

export interface Prijave {
    id?: string
    reportedCases?: number | undefined
    type?: VrstaNasilja
    age?: UzrastZrtve
    gender?: PolPocinioca
    relationship?: MedjusobniOdnos
    description?: string
}

export interface Oblik {
    id?: string
    numberOfVictims?: number
    form?: OblikEksploatacije
    gender?: PolPocinioca
    age?: UzrastZrtve
}

// klasa za vise polja u entity forma7-11
export interface TrainingReport {
    id?: string
    broj?: number
    vrsta?: string
}

export enum VrstaNasilja {
    Option1 = 'Опција 1',
    Option2 = 'Опција 2',
    Option3 = 'Опција 3',
    Option4 = 'Опција 4',
    Option5 = 'Опција 5',
}
export enum UzrastZrtve {
    Option1 = 'Опција 1',
    Option2 = 'Опција 2',
    Option3 = 'Опција 3',
    Option4 = 'Опција 4',
    Option5 = 'Опција 5',
}
export enum PolPocinioca {
    Muski = 'Мушки',
    Zenski = 'Женски',
}
export enum MedjusobniOdnos {
    Nadredjeni = 'Надређени',
    Podredjeni = 'Подређени',
    Kolege = 'Колеге',
    Drugo = 'Друго',
}

export enum OblikEksploatacije {
    Option1 = 'Опција 1',
    Option2 = 'Опција 2',
    Option3 = 'Опција 3',
    Option4 = 'Опција 4',
    Option5 = 'Опција 5',
}
