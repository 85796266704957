import React, { useState } from 'react'

import Step1 from '../TutorialSteps/RegisterSteps/Step1'
import Step2 from '../TutorialSteps/RegisterSteps/Step2'
import Step3 from '../TutorialSteps/RegisterSteps/Step3'
import Step4 from '../TutorialSteps/RegisterSteps/Step4'
import Step5 from '../TutorialSteps/RegisterSteps/Step5'
import Step6 from '../TutorialSteps/RegisterSteps/Step6'
import Step7 from '../TutorialSteps/RegisterSteps/Step7'
import Step8 from '../TutorialSteps/RegisterSteps/Step8'

interface TutorialRegisterInterface {
    skipClick: () => void
    onClose: () => void
    setCheckTourOpen?: any
}

const TutorialRegister = ({ skipClick, onClose }: TutorialRegisterInterface) => {
    const [activeStep, setActiveStep] = useState(0)

    const nextStep = async () => {
        console.log('clicked')
        setActiveStep(activeStep + 1)
    }

    const previousStep = () => setActiveStep(activeStep - 1)

    const finish = async () => {}

    const stepComponent = () => {
        switch (activeStep) {
            case 0:
                return <Step1 nextStep={nextStep} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
            case 1:
                return <Step2 nextStep={nextStep} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
            case 2:
                return <Step3 nextStep={nextStep} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
            case 3:
                return <Step4 nextStep={nextStep} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
            case 4:
                return <Step5 nextStep={nextStep} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
            case 5:
                return <Step6 nextStep={nextStep} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
            case 6:
                return <Step7 nextStep={nextStep} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
            case 7:
                return <Step8 nextStep={onClose} previousStep={previousStep} skipClick={skipClick} onClose={onClose} />
        }
    }
    return (
        <div>
            <div className="mt-[0px]">{stepComponent()}</div>
        </div>
    )
}

export default TutorialRegister
