import React, { useEffect, useState } from 'react'
import { ReactComponent as ChevronDown } from '../../assets/svg/SortUpArrowSVG.svg'
import { getLegalInfo } from '../../server/server'

const QuestionsAndAnswers = () => {
    const [questionAnswerPairs, setQuestionAnswerPairs] = useState([])
    useEffect(() => {
        getLegal()
    }, [])

    const getLegal = async () => {
        const resp = await getLegalInfo()
        if (resp.status === 200) {
            const rawString = resp?.data[0]?.faq

            if (rawString) {
                const formattedPairs = rawString
                    .split('@')
                    .filter(Boolean)
                    .map((pair: any) => {
                        const [question, answer] = pair.split('&').map((s: any) => s.trim())
                        return [question, answer]
                    })

                setQuestionAnswerPairs(formattedPairs)
            }
        }
    }
    return (
        <div className="py-5 px-5">
            <div className="flex justify-center flex-col items-center gap-5">
                <div className="mb-10">
                    <h2 className="text-[24px] leading-6">Често постављана питања</h2>
                </div>
                {questionAnswerPairs.map((pair, index) => {
                    return (
                        <div key={index}>
                            <QuestionAnswerPairs data={pair} index={index} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default QuestionsAndAnswers

const QuestionAnswerPairs = ({ data, index }: any) => {
    const [open, setOpen] = useState(false)
    return (
        <div className="m-4">
            <div onClick={() => setOpen(!open)} className="w-[800px] flex flex-row items-center justify-between gap-5 border-b border-b-primary cursor-pointer" key={index}>
                <p className="text-[20px] flex-1 hover:cursor-pointer px-2 text-black leading-[28px]">{data[0]}</p>
                <div className={`transform transition-transform ${open ? 'rotate-180' : ''}`}>
                    <ChevronDown />
                </div>
            </div>
            {/* {open ? ( */}
            <div className={` px-2 w-[800px] overflow-hidden transition-all   ${open ? 'max-h-40 opacity-100' : 'max-h-[0px] opacity-0'}`}>
                <div className="text-[16px] cursor-default py-5">{data[1]}</div>
            </div>
            {/* ) : (
                <div className="min-h-[20px]"></div>
            )} */}
        </div>
    )
}
