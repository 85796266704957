import React from 'react'
import { StepInterface } from './Step1'
import RadioButtonsControl from '../../../../../components/Common/RadioButtonsControlWithError'
import { Form7_19Entity } from '../../../../../models/Form7/Entities/Form7_19.entity'
import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import Textbox from '../../../../../components/Common/Textbox'

export const Step2 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_19Entity>({ control })

    const fields: ('o7_19_k2_procena' | 'o7_19_k2_opis')[] = ['o7_19_k2_procena', 'o7_19_k2_opis']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    fields.every((e) => console.log('e:', e, watchedValues))

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div className="mb-10">
                <h1 className="step-name mb-[10px]">Молимо Вас да оцените степен уродњавања области иновационе делатности. </h1>

                <RadioButtonsControl
                    control={control}
                    name={'o7_19_k2_procena'}
                    options={['Одличан', 'Добар', 'Довољан', 'Недовољан', 'Лош']}
                    errors={errors}
                    enumerated
                    disabled={adminView}
                />
            </div>

            <div>
                <h1 className="step-name mb-[10px]">Молимo Вас да кратко опишете зашто сте дали овакву оцену?</h1>
                <td className="multiple-input-table-cell editable !border-none !min-w-[650px]">
                    <Textbox
                        name="o7_19_k2_opis"
                        control={control}
                        maxNumberOfCharacters={400}
                        placeholder="Текст (400 карактера)*"
                        errors={errors}
                        isRequired={adminView ? false : 'Попуните обавезно поље.'}
                        disabled={adminView}
                    />
                </td>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
