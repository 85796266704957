import React, { useEffect, useState } from 'react'
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom'
import NavBar from './Nav/NavBar'

function Layout() {
    const navigate = useNavigate()
    const [isUnsupportedBrowser, setIsUnsupportedBrowser] = useState(false)

    useEffect(() => {
        const detectUnsupportedBrowser = () => {
            const ua = navigator.userAgent.toLowerCase()

            // Detect Edge (both modern and legacy)
            const isEdge =
                ua.indexOf('edge/') !== -1 || // Legacy Edge
                ua.indexOf('edg/') !== -1 || // Modern Edge
                Boolean((window.navigator as any).userAgentData?.brands?.find((brand: any) => brand.brand === 'Microsoft Edge'))

            // Detect Internet Explorer
            const isIE = /msie|trident/.test(ua)

            // Detect Opera
            const isOpera = ua.indexOf('opr/') !== -1 || ua.indexOf('opera/') !== -1

            // Detect Firefox
            const isFirefox = ua.indexOf('firefox') !== -1

            // Detect Safari (but not Chrome)
            const isSafari = /^((?!chrome|android).)*safari/i.test(ua)

            return isEdge || isIE || isOpera || isFirefox || isSafari
        }

        setIsUnsupportedBrowser(detectUnsupportedBrowser())
    }, [])

    return (
        <div id="scroll-target" className="home h-screen overflow-y">
            <div className="w-full !h-full ">
                <div className="w-full !h-full flex flex-col">
                    <NavBar />
                    <div id="home-container" className="flex-1 flex flex-col overflow-y-auto">
                        <div className="flex-1 !w-full transition ">
                            <Outlet />
                        </div>
                        {isUnsupportedBrowser && (
                            <div>
                                <p className="px-5 text-center pb-2 text-[13px]">
                                    <i>
                                        <b>*</b> Препоручујемо употребу <b>Google Chrome</b> претраживача. Корисници који користе EDGE су пријављивали чудна абнормална понашања.
                                    </i>
                                </p>
                            </div>
                        )}
                        <div className="h-[50px] bg-[#243964] items-center justify-between flex py-[13px]">
                            <button
                                className="invisible flex flex-row items-center justify-center gap-2 hover:border-[#202020] rounded-full h-8 pl-[5px] pr-[20px] py-[4px] transition"
                                onClick={() => {
                                    navigate('/pitanja')
                                }}
                                type="button"
                            >
                                <p className="font-[16px] text-[#ffffff] leading-[24px] underline">Често постављана питања</p>
                            </button>
                            <p className="text-[#ffffff]">© 2024 Министарство за људска и мањинска права и друштвени дијалог</p>
                            <button
                                className="flex flex-row items-center justify-center gap-2 hover:border-[#202020] rounded-full h-8 pl-[5px] pr-[20px] py-[4px] transition"
                                onClick={() => {
                                    navigate('/pitanja')
                                }}
                                type="button"
                            >
                                <p className="font-[16px] text-[#ffffff] leading-[24px] underline">Често постављана питања</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout
