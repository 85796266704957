import React, { useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { calculateDifference } from '../../../../../utils/mathUtils'
import { Form7_3Entity } from '../../../../../models/Form7/Entities/Form7_3.entity'

export interface StepInterface {
    control?: Control<Form7_3Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

interface RowInterface {
    label: string
    fields: (keyof Form7_3Entity)[]
}

const rowsTable: RowInterface[] = [
    { label: 'Руководиоци (директори), функционери и законодавци', fields: ['o7_3_k4_z_rukovodioci', 'o7_3_k4_m_rukovodioci', 'o7_3_k4_jaz_rukovodioci'] },
    { label: 'Стручњаци и уметници', fields: ['o7_3_k4_z_strucnjaci', 'o7_3_k4_m_strucnjaci', 'o7_3_k4_jaz_strucnjaci'] },
    { label: 'Инжењери, стручни сарадници и техничари', fields: ['o7_3_k4_z_inzenjeri', 'o7_3_k4_m_inzenjeri', 'o7_3_k4_jaz_inzenjeri'] },
    { label: 'Административни службеници', fields: ['o7_3_k4_z_admini', 'o7_3_k4_m_admini', 'o7_3_k4_jaz_admini'] },
    { label: 'Услужна и трговачка занимања', fields: ['o7_3_k4_z_usluga', 'o7_3_k4_m_usluga', 'o7_3_k4_jaz_usluga'] },
    { label: 'Пољопривредници, шумари, рибари и сродни', fields: ['o7_3_k4_z_poljoprivreda', 'o7_3_k4_m_poljoprivreda', 'o7_3_k4_jaz_poljoprivreda'] },
    { label: 'Занатлије и сродни', fields: ['o7_3_k4_z_zanatlije', 'o7_3_k4_m_zanatlije', 'o7_3_k4_jaz_zanatlije'] },
    { label: 'Руковаоци машинама и постројењима, монтери и возачи', fields: ['o7_3_k4_z_rukovaoci', 'o7_3_k4_m_rukovaoci', 'o7_3_k4_jaz_rukovaoci'] },
    { label: 'Једноставна занимања', fields: ['o7_3_k4_z_jednostavna', 'o7_3_k4_m_jednostavna', 'o7_3_k4_jaz_jednostavna'] },
]

const header: string[] = [
    'Групе занимања',
    'Учешће запослених са ниским зарадама у укупном броју запослених Жене %',
    'Учешће запослених са ниским зарадама у укупном броју запослених Мушкарци %',
    'Платни јаз између жена и мушкараца %',
]

const allFieldsTable = [
    'o7_3_k4_z_rukovodioci',
    'o7_3_k4_z_strucnjaci',
    'o7_3_k4_z_inzenjeri',
    'o7_3_k4_z_admini',
    'o7_3_k4_z_usluga',
    'o7_3_k4_z_poljoprivreda',
    'o7_3_k4_z_zanatlije',
    'o7_3_k4_z_rukovaoci',
    'o7_3_k4_z_jednostavna',

    'o7_3_k4_m_rukovodioci',
    'o7_3_k4_m_strucnjaci',
    'o7_3_k4_m_inzenjeri',
    'o7_3_k4_m_admini',
    'o7_3_k4_m_usluga',
    'o7_3_k4_m_poljoprivreda',
    'o7_3_k4_m_zanatlije',
    'o7_3_k4_m_rukovaoci',
    'o7_3_k4_m_jednostavna',

    'o7_3_k4_jaz_rukovodioci',
    'o7_3_k4_jaz_strucnjaci',
    'o7_3_k4_jaz_inzenjeri',
    'o7_3_k4_jaz_admini',
    'o7_3_k4_jaz_usluga',
    'o7_3_k4_jaz_poljoprivreda',
    'o7_3_k4_jaz_zanatlije',
    'o7_3_k4_jaz_rukovaoci',
    'o7_3_k4_jaz_jednostavna',
] as const

type AllFieldsType = (typeof allFieldsTable)[number]

function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_3Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...allFieldsTable]
    const [disabled, setDisabled] = useState(false)
    const hasRowErrors = rowsTable.some((row) => row.fields.some((field) => errors?.[field]))

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">4.Платни јаз и учешће запослених са ниским зарадама у укупном броју запослених по групи занимања:</h1>
            <h1 className="step-name mt-5">* Под запосленима са ниском зарадом сматрају се лица која зарађују мање или једнако 2/3 медијане зараде по часу.</h1>
            <table className="complex-table mt-[30px]">
                <tr className="complex-table-header">
                    {header.map((head, index) => (
                        <th className="complex-table-cell" style={{ width: index === 0 ? 450 : 200 }} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {rowsTable.map((row, index) => (
                    <tr className="complex-table-row" key={index}>
                        <td className="complex-table-cell  disabled" style={{ width: 450 }}>
                            {row.label}
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[0] as string}
                                control={control}
                                decimal={true}
                                precentage
                                form7
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={adminView}
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[1] as string}
                                control={control}
                                decimal={true}
                                precentage
                                form7
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={adminView}
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0, width: 200 }}>
                            <PositiveNumberInputCell
                                name={row.fields[2] as string}
                                control={control}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={adminView}
                                decimal={true}
                            />
                        </td>
                    </tr>
                ))}
                <div className="h-[20px]"> {hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
