import { useEffect, useRef, useState } from 'react'
import Actions from '../../Components/Actions'
import { TutorialStepInterface } from './Step1'
import FirstButton from '../../../../assets/svg/stepButtons/FirstButton'
import FinishButton from '../../../../assets/svg/stepButtons/FinishButton'
import StepButton from '../../../../assets/svg/stepButtons/StepButton'
import { ReactComponent as Check } from '../../../../assets/svg/stepButtons/CompletedButtonCheck.svg'
import { twoDigits } from '../../../../utils/mathUtils'
import { Button, Stack, TextareaAutosize } from '@mui/material'
import { ReactComponent as Trash } from '../../../../assets/svg/TrashLarger.svg'
import Tour from 'reactour'

const Step3 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const [activeStep, setActiveStep] = useState(0)
    const [lastStep, setLastStep] = useState(0)
    const steps = [10, 11, 12, 13, 14, 15]
    const fields: any[] = []
    const fieldsToShowDelete: any[] = ['1']

    const containerRef = useRef<HTMLDivElement | null>(null)
    const selectedItemRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (selectedItemRef.current && containerRef.current) {
            selectedItemRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            })
        }
    }, [activeStep])

    useEffect(() => {
        setLastStep(Math.max(lastStep, activeStep))
    }, [activeStep])

    const getTextColor = (isCompleted: boolean, isActive: boolean) => (isCompleted || isActive ? '#4891CB' : '#00000061')

    const tourSteps = [
        {
            selector: 'div[id="deleteButton"]',
            content: 'Када корисник кликне на дугме „ОБРИШИ“ или на иконицу „КАНТА“, поље које је претходно додато је обрисано.',
        },
        {
            selector: 'div[id="text"]',
            content:
                'Поље за унос текста је елемент који омогућава кориснику да унесе текстуалне податке. Корисник треба да кликне унутар поља и затим куца текст. У зависности од поставки, поље може имати ограничење на број карактера, као што је наведено у овом примеру (2048 карактера). Ако је поље обавезно, корисник мора унети податке како би могао да пређе на следећи корак.',
        },
        {
            selector: 'div[id="addNew"]',
            content: 'Када корисник кликне на ово дугме врши се додавање још једног инпут поља „НАЧИН ПОСТУПАЊА“ у које се може унети текст.',
        },
    ]

    const handleTourClose = () => {
        setIsTourOpen(false)
    }

    return (
        <div className="flex h-full w-full flex-col items-center">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div>
                <div className="border border-borderTutorial p-10">
                    <div className="flex flex-row overflow-x-hidden overflow-y-hidden pb-[5px]">
                        {steps.map((step, index) => {
                            const isFirst = index === 0
                            const isLast = index === steps.length - 1
                            const isActive = index === activeStep
                            const isCompleted = index < lastStep
                            const textColor = getTextColor(isCompleted, isActive)

                            return (
                                <div key={step} className="h-[57px]" ref={isActive ? selectedItemRef : null}>
                                    <button
                                        className="h-[57px] relative cursor-default"
                                        onClick={() => setActiveStep(index)}
                                        disabled={index > lastStep}
                                        data-tooltip-id="step"
                                        data-tooltip-content={`Step ${step}`}
                                    >
                                        {isFirst && <FirstButton fill={isActive ? '#C5FD8D' : 'white'} />}
                                        {!isFirst && !isLast && <StepButton fill={isActive ? '#C5FD8D' : 'white'} />}
                                        {isLast && <FinishButton fill={isActive ? '#C5FD8D' : 'white'} />}
                                        <div className="absolute z-1 top-0 h-full w-full flex flex-row items-center pl-[31px] gap-[6px]">
                                            {isCompleted && <Check />}
                                            {!isCompleted && (
                                                <div
                                                    className="w-[40px] h-[40px] flex items-center justify-center border-[2px] border-inactive rounded-full"
                                                    style={{ borderColor: isActive ? '#4891CB' : '#CFD6DC' }}
                                                >
                                                    <p className="text-center text-inactive" style={{ color: isActive ? '#4891CB' : '#CFD6DC' }}>
                                                        {twoDigits(step)}
                                                    </p>
                                                </div>
                                            )}
                                            <span style={{ color: textColor }}>Корак {step}</span>
                                        </div>
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                    <div className="mt-[10px]">
                        <span className="text-[16px] font-light">
                            Корак {activeStep + 1} од {steps.length}
                        </span>
                    </div>
                    <div>
                        <h1 className="step-name">
                            10. Начин поступања одговорног лица у органима јавне власти и послодавца по поднетим пријавама запослених и радно ангажованих лица о њиховој изложености
                            узнемиравању, сексуалном узнемиравању или уцењивању или неком другом поступању које има за последицу дискриминацију на основу пола, односно рода,
                            разврстаних по полу и старосној доби.
                        </h1>
                        <div className="mt-[20px]">
                            <div className="flex flex-col gap-[20px] mb-[30px]">
                                {fieldsToShowDelete?.map((field, index) => (
                                    <div className="flex flex-row items-center gap-40">
                                        <div className="text-inactive h-[100px] flex items-center">Тренутно нема унетих начина поступања</div>
                                        <div id="deleteButton" className="flex flex-row">
                                            <Button
                                                color="error"
                                                sx={{
                                                    backgroundColor: (theme) => theme.palette.error.main,
                                                    color: (theme) => theme.palette.error.contrastText,
                                                    '&.Mui-disabled': {
                                                        backgroundColor: (theme) => theme.palette.error.main,
                                                        color: (theme) => theme.palette.error.contrastText,
                                                    },
                                                }}
                                                disabled={true}
                                                variant="contained"
                                            >
                                                Обриши
                                            </Button>
                                            <Trash />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className=" flex flex-col w-full">
                                <h4 className="mb-[10px]">Додај начин поступања:</h4>
                                <div id="text">
                                    <TextareaAutosize
                                        // required={!checkDisabled()}
                                        minRows={4}
                                        placeholder={'Текст (2048 карактера)'}
                                        className={`border border-[#D9D9D9]`}
                                        style={{
                                            width: '100%',
                                            padding: '12px 16px',
                                            boxSizing: 'border-box',
                                            borderRadius: '8px',
                                            resize: 'none',
                                        }}
                                        maxLength={2048}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="h-[26px]"></div>
                        </div>
                        <div id="addNew" className="max-w-[700px]">
                            <Button
                                variant="contained"
                                sx={{
                                    cursor: 'default',
                                    opacity: 1,
                                    '&.Mui-disabled': {
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                    },
                                }}
                                disabled={true}
                            >
                                Додај начин поступања
                            </Button>
                        </div>
                    </div>
                    <Stack spacing={'10px'} direction="row" className="mt-[50px]">
                        <div id="prev">
                            <Button
                                variant="contained"
                                sx={{
                                    cursor: 'default',
                                    opacity: 1,
                                    '&.Mui-disabled': {
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                    },
                                }}
                                disabled
                            >
                                Претходни корак
                            </Button>
                        </div>
                        <div id="next">
                            <Button
                                sx={{
                                    cursor: 'default',
                                    opacity: 1,
                                    '&.Mui-disabled': {
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                    },
                                }}
                                disabled
                                variant="contained"
                                type="submit"
                                title="Следећи корак"
                                className="cursor-default"
                            >
                                Следећи корак
                            </Button>
                        </div>
                    </Stack>
                </div>
                <div className="mt-10 w-full">
                    <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
                </div>
            </div>
        </div>
    )
}

export default Step3
