import { Button } from '@mui/material'
import FormSearch from './Tables/FormSearch'
import CorporateEntitiesSearch from './Tables/CorporateEntitiesSearch'
import { useState } from 'react'
import HelpButton from '../../components/Common/HelpButton'

type SelectedTab = 'izvestaji' | 'privredni_entiteti'

const Dashboard = () => {
    const [selectedTab, setSelectedTab] = useState<SelectedTab>('izvestaji')

    return (
        // <div className="!w-full px-[100px] bg-[#ffffff] pt-[52px] relative">
        <div className=" mx-[auto] px-[100px] bg-[#ffffff] pt-[52px] relative">
            <div className="absolute top-5 right-10 ">
                <HelpButton name="dashboard_help" marginTop={0} />
            </div>
            <FormSearch />
        </div>
    )
}

export default Dashboard
