import React, { useState } from 'react'
import { TutorialStepInterface } from './Step1'
import selectSearch from '../../../../assets/images/LoginTutorialImages/RegisterScreen2.png'
import Actions from '../../Components/Actions'
import { RegisterStep2Inputs } from '../../../../components/Login/SignUpSteps/SignUpStep2'
import { Checkbox } from '@mui/material'
import { VirtualizedAutocomplete } from '../../../../components/Common/VirtualizedAutocomplete'
import Tour from 'reactour'

const Step5 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const [cities, setCities] = useState<any>([])
    const fields: { label: string; field: keyof RegisterStep2Inputs }[] = [
        { label: 'ПИБ', field: 'pib' },
        { label: 'Матични број', field: 'mb' },
        { label: 'Назив oбвезника', field: 'subjectName' },
        { label: 'Име овлашћеног лица', field: 'firstName' },
        { label: 'Презиме овлашћеног лица', field: 'lastName' },
        { label: 'Адреса', field: 'address' },
        { label: 'Број телефона', field: 'phoneNumber' },
    ]
    const tourSteps = [
        {
            selector: 'div[id="main"]',
            content: 'Након што потврдите верификациони имејл и пријавите се на систем, бићете преусмерени на страницу за регистрацију. ',
        },
        {
            selector: 'div[id="comission"]',
            content:
                'Означите ову опцију ако сте члан Комисије или Савета за родну равноправност и немате ПИБ/МБ. У том случају, поља за унос ПИБ-а и Матичног броја ће бити онемогућена.',
        },
        {
            selector: 'div[id="0"]',
            content:
                'Унесите ПИБ обвезника. При уносу ПИБ-а претражује се база података АПР-а у којој ће те вероватно пронаћи и моћи да одаберете ваше предузеће. (Ово поље је онемогућено ако сте означили да сте члан Комисије/Савета.)',
        },
        { selector: 'div[id="1"]', content: 'Унесите матични број обвезника. (Ово поље је онемогућено ако сте означили да сте члан Комисије/Савета.)' },
        { selector: 'div[id="2"]', content: 'Унесите назив правног субјекта.' },
        { selector: 'div[id="3"]', content: 'Попуните име овлашћене особе.' },
        { selector: 'div[id="4"]', content: 'Попуните презиме овлашћене особе.' },
        { selector: 'div[id="5"]', content: 'Унесите адресу седишта обвезника.' },
        { selector: 'div[id="6"]', content: 'Унесите контакт број.' },
        { selector: 'div[id="city"]', content: 'Изаберите седиште из падајуће листе.' },
        { selector: 'span[id="city"]', content: 'Уколико тражене информације нису унете или постоји грешка са њима појавиће се текстуална порука са објашњењем грешке.' },
        { selector: 'div[id="cb"]', content: 'Означите обе опције сагласности пре него што наставите. Више информација можете да прочитате кликом на подвучене линкове.' },
        { selector: 'button[id="sub"]', content: 'Кликните на дугме „Региструј се“ да бисте завршили процес.' },
    ]

    const handleTourClose = () => {
        setIsTourOpen(false)
    }
    return (
        <div className="w-full h-full flex items-center flex-col">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div className="pt-[30px] bg-[#ffffff] items-start flex flex-col border border-borderTutorial p-10">
                <h2 className="mb-[20px] text-[24px] leading-[28px] font-light max-w-[520px] text-center">Регистрација обвезника закона о родној равноправности</h2>
                <form className="flex flex-col items-center w-full max-w-[520px]" autoComplete="off">
                    <div className="w-full flex flex-col">
                        <div id="comission" className="flex flex-row  my-[16px] max-w-[520px] min-h-[56px] items-center border border-disabled rounded-[4px]">
                            <Checkbox
                                disabled
                                size="large"
                                sx={{
                                    '&.Mui-disabled': {
                                        color: '#666666',
                                        opacity: 1,
                                    },
                                    '& .MuiSvgIcon-root': {
                                        '&.Mui-disabled': {
                                            color: '#666666',
                                            opacity: 1,
                                        },
                                    },
                                }}
                            />
                            <span className={`text-[14px] leading-[20px] font-light max-w-[520px] cursor-default`}>
                                Члан сам Комисије/Савета за родну равноправност (немам ПИБ/МБ)
                            </span>
                        </div>
                        {fields.map((item, index) => {
                            return (
                                <div id={`${index}`} key={index} className="flex flex-col mb-[16px]">
                                    <input
                                        className={`border border-disabled max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px] `}
                                        placeholder={item.label}
                                        disabled={true}
                                    />
                                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]"></span>
                                </div>
                            )
                        })}
                        <div id="city">
                            <VirtualizedAutocomplete borderColor="#00000061" items={cities} label={'Седиште'} disabled={false} isGuide />
                            <span id="city" className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]">
                                Седиште је обавезно поље
                            </span>
                        </div>

                        <div id="cb">
                            <div className="flex flex-row items-center mt-[16px] max-w-[520px]">
                                <Checkbox
                                    disabled
                                    size="large"
                                    sx={{
                                        color: '#A2CEFF',
                                        '&.Mui-checked': {
                                            color: '#A2CEFF',
                                        },
                                        '&.Mui-disabled': {
                                            color: '#A2CEFF',
                                        },
                                    }}
                                />
                                <span className={`text-[14px] leading-[20px] font-light max-w-[520px]`}>
                                    СЛАЖЕМ СЕ СА
                                    <span className="underline cursor-default">УСЛОВИМА КОРИШЋЕЊА ПОРТАЛА И ПРИХВАТАМ ОПШТЕ УСЛОВЕ И ПОЛИТИКУ ПРИВАТНОСТИ</span>
                                    ПРУЖАЊА УСЛУГЕ ЕЛЕКТРОНСКОГ ИЗВЕШТАВАЊА И ПРАЋЕЊА ПРИМЕНЕ ЗАКОНА О РОДНОЈ РАВНОПРАВНОСТИ
                                </span>
                            </div>

                            <div className="flex flex-row  mt-[16px] max-w-[520px]">
                                <Checkbox
                                    disabled
                                    size="large"
                                    sx={{
                                        color: '#A2CEFF',
                                        '&.Mui-checked': {
                                            color: '#A2CEFF',
                                        },
                                        '&.Mui-disabled': {
                                            color: '#A2CEFF',
                                        },
                                    }}
                                />
                                <span className={`text-[14px] leading-[20px] font-light max-w-[520px]`}>
                                    ПОД МАТЕРИЈАЛНОМ И КРИВИЧНОМ ОДГОВОРНОШЋУ ИЗЈАВЉУЈЕМ ДА СУ СВИ ПОДАЦИ ТАЧНИ И У СКЛАДУ СА ПОЗИТИВНИМ ПРОПИСИМА РЕПУБЛИКЕ СРБИЈЕ
                                </span>
                            </div>
                        </div>

                        <button
                            id="sub"
                            disabled
                            type="submit"
                            className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-default mt-[43px] max-w-[520px]"
                        >
                            РЕГИСТРУЈ СЕ
                        </button>
                    </div>
                </form>
            </div>
            <div className="w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step5
