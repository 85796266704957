import { GRADE } from '../../CommonTypes'
import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'
import { InnovationProgram } from './Common/InnovationPrograms.entity'
import { SupportProgram } from './Common/SupportProgram.entity'

export const NumberOfSteps = 7

export interface Form7_19Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: string
    category: string
    organisation: Organization
    status: string

    o7_19_k1_fileDescription: FileDescription[]
    o7_19_k1_file: File[]

    o7_19_k2_procena: GRADE
    o7_19_k2_opis: string

    o7_19_k3_z1: number
    o7_19_k3_z2: number
    o7_19_k3_m1: number
    o7_19_k3_m2: number

    o7_19_k4_z1: number
    o7_19_k4_z2: number
    o7_19_k4_m1: number
    o7_19_k4_m2: number

    o7_19_k5_programiPodrske: SupportProgram[]
    o7_19_k5_files: File[]
    o7_19_k5_napomena: string

    o7_19_k6_programiInovacije: InnovationProgram[]

    submitted_by?: SubmittedBy
}
