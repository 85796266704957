import { Control, FieldValues, useWatch } from 'react-hook-form'
import { getPercentage, sumValues } from '../../../utils/mathUtils'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'
import TextInputCell from '../../../components/Common/TextInputCell'

const getPercentageDifference = (current: number, previous: number) => {
    if (current === undefined || previous === undefined || previous === 0) {
        return 0
    }
    return (((current - previous) / previous) * 100).toFixed(2)
}
interface RowInterface<T> {
    label: string
    fields: (keyof T)[]
}

interface ComplexTableInterface<T extends FieldValues> {
    rows: RowInterface<T>[]
    control: Control<T> | undefined
    header: string[]
    errors?: any
    disabled?: boolean
    showTotalSum?: boolean
}

function ComplexTable7_17<T extends FieldValues>({ rows, control, header, errors, disabled, showTotalSum = true }: ComplexTableInterface<T>) {
    const watchedValues = useWatch({ control }) as T

    const hasRowErrors = rows.some((row) => row.fields.some((field) => errors?.[field]))

    console.log(watchedValues[rows[0].fields[4]])

    return (
        <table className="complex-table mt-[30px]">
            <tr className="complex-table-header">
                {header.map((head, index) => (
                    <th className="complex-table-cell" key={index} style={{ width: index >= 1 && index <= 6 ? '100px' : index === 9 ? '400px' : undefined }}>
                        {head}
                    </th>
                ))}
            </tr>
            {rows.map((row, index) => (
                <tr className="complex-table-row">
                    <td className="complex-table-cell disabled">{row.label}</td>
                    {row.fields.slice(0, 6).map((field, index) => (
                        <td className="complex-table-cell editable !border-none maxw-[10px]" style={{ width: '100px', padding: '0px' }} key={index}>
                            <PositiveNumberInputCell
                                name={field as string}
                                control={control}
                                isMultipleTable={true}
                                errors={errors}
                                decimal
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                    ))}
                    <td className="complex-table-cell disabled">{getPercentageDifference(watchedValues[row.fields[5]], watchedValues[row.fields[4]])}%</td>
                    <td className="complex-table-cell disabled">{0}</td>
                    <td className="complex-table-cell editable !border-none" style={{ width: '400px', padding: '0px' }}>
                        <TextInputCell
                            name={row.fields[6] as string}
                            control={control}
                            errors={errors}
                            isRequired={'tes'}
                            disabled={disabled}
                            maxNumberOfCharacter={100}
                            placeholder="Текст (100 карактера)"
                        />
                    </td>
                </tr>
            ))}
            <div className="h-[2px]"> {hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
        </table>
    )
}

export default ComplexTable7_17
