import { useEffect, useRef, useState } from 'react'
import Actions from '../../Components/Actions'
import { TutorialStepInterface } from './Step1'
import FirstButton from '../../../../assets/svg/stepButtons/FirstButton'
import FinishButton from '../../../../assets/svg/stepButtons/FinishButton'
import StepButton from '../../../../assets/svg/stepButtons/StepButton'
import { ReactComponent as Check } from '../../../../assets/svg/stepButtons/CompletedButtonCheck.svg'
import { twoDigits } from '../../../../utils/mathUtils'
import { format } from 'date-fns'
import { ReactComponent as TickMark } from '../../../../assets/svg/ModalTickMark.svg'
import { Button, Input, Stack } from '@mui/material'
import Tour from 'reactour'

const Step6 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const [activeStep, setActiveStep] = useState(5)
    const [lastStep, setLastStep] = useState(0)
    const steps = [10, 11, 12, 13, 14, 15]
    const fields: any[] = []
    const fieldsToShowDelete: any[] = ['1']

    const containerRef = useRef<HTMLDivElement | null>(null)
    const selectedItemRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (selectedItemRef.current && containerRef.current) {
            selectedItemRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            })
        }
    }, [activeStep])

    useEffect(() => {
        setLastStep(Math.max(lastStep, activeStep))
    }, [activeStep])

    const getTextColor = (isCompleted: boolean, isActive: boolean) => (isCompleted || isActive ? '#4891CB' : '#00000061')

    const tourSteps = [
        {
            selector: 'div[id="endWindow"]',
            content:
                'Уколико сте попунили сва обавезна поља, када кликнете на ово дугме, унос је завршен и извештај је сачуван, извештај више не можете мењати већ само гледати. Поље „НАЗИВ МЕСТА“ се попуњава аутоматски на основу унетог седишта субјекта као и поље „ДАТУМ“.',
        },
        {
            selector: 'div[id="anketa"]',
            content: 'Након успешног попуњавања извештаја појавиће се прозор са поруком који пружа две опције.',
        },
        {
            selector: 'Button[id="anketaButton"]',
            content: 'Кликом на ово дугме можете приступити краткој анкети о вашем искуству са попуњавањем форме.',
        },
        {
            selector: 'Button[id="start"]',
            content: 'Кликом на ово дугме бићете враћени на почетну страницу.',
        },
    ]

    const handleTourClose = () => {
        setIsTourOpen(false)
    }
    return (
        <div className="flex h-full w-full flex-col items-center">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div>
                <div id="endWindow" className="border border-borderTutorial p-10">
                    <div className="flex flex-row overflow-x-hidden overflow-y-hidden pb-[5px]">
                        {steps.map((step, index) => {
                            const isFirst = index === 0
                            const isLast = index === steps.length - 1
                            const isActive = index === activeStep
                            const isCompleted = index < lastStep
                            const textColor = getTextColor(isCompleted, isActive)

                            return (
                                <div key={step} className="h-[57px]" ref={isActive ? selectedItemRef : null}>
                                    <button
                                        className="h-[57px] relative cursor-default"
                                        onClick={() => setActiveStep(index)}
                                        disabled={index > lastStep}
                                        data-tooltip-id="step"
                                        data-tooltip-content={`Step ${step}`}
                                    >
                                        {isFirst && <FirstButton fill={isActive ? '#C5FD8D' : 'white'} />}
                                        {!isFirst && !isLast && <StepButton fill={isActive ? '#C5FD8D' : 'white'} />}
                                        {isLast && <FinishButton fill={isActive ? '#C5FD8D' : 'white'} />}
                                        <div className="absolute z-1 top-0 h-full w-full flex flex-row items-center pl-[31px] gap-[6px]">
                                            {isCompleted && <Check />}
                                            {!isCompleted && (
                                                <div
                                                    className="w-[40px] h-[40px] flex items-center justify-center border-[2px] border-inactive rounded-full"
                                                    style={{ borderColor: isActive ? '#4891CB' : '#CFD6DC' }}
                                                >
                                                    <p className="text-center text-inactive" style={{ color: isActive ? '#4891CB' : '#CFD6DC' }}>
                                                        {twoDigits(step)}
                                                    </p>
                                                </div>
                                            )}
                                            <span style={{ color: textColor }}>Корак {step}</span>
                                        </div>
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        <h1 className="step-name mt-10">19. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља</h1>
                        <div className="flex flex-row items-center mt-[100px] gap-[5px]">
                            <p>У</p>

                            <div className="min-w-[100px] border border-[#00000010]  h-[50px] flex items-center p-[15px]">
                                <Input
                                    value={'Београд'}
                                    className="!bg-[transparent] w-full"
                                    disabled={true}
                                    // error={true}
                                    sx={{
                                        textAlign: 'left',
                                        '& .MuiInputBase-input': {
                                            textAlign: 'left',
                                        },
                                        '&.MuiInputBase-root:before': {
                                            borderBottom: 'none',
                                        },
                                        '&.MuiInputBase-root:after': {
                                            borderBottom: 'none',
                                        },
                                        '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                            borderBottom: 'none',
                                        },
                                    }}
                                />
                            </div>

                            <p>, дана {format(new Date(), 'dd.MM.yyyy.')}</p>
                        </div>
                        <Stack spacing={'10px'} direction="row" className="mt-[50px]">
                            <div id="prev">
                                <Button
                                    variant="contained"
                                    sx={{
                                        cursor: 'default',
                                        opacity: 1,
                                        '&.Mui-disabled': {
                                            backgroundColor: '#1976d2',
                                            color: 'white',
                                        },
                                    }}
                                    disabled
                                >
                                    Претходни корак
                                </Button>
                            </div>
                            <div id="next">
                                <Button
                                    sx={{
                                        cursor: 'default',
                                        opacity: 1,
                                        '&.Mui-disabled': {
                                            backgroundColor: '#1976d2',
                                            color: 'white',
                                        },
                                    }}
                                    disabled
                                    variant="contained"
                                    type="submit"
                                    title="Следећи корак"
                                    className="cursor-default"
                                >
                                    Заврши и пошаљи
                                </Button>
                            </div>
                        </Stack>
                    </div>
                </div>
                <hr className="text-borderTutorial mt-10" />
                <div id="anketa" className="border border-borderTutorial p-10 flex items-center justify-center mt-10">
                    <div className="w-[650px] h-[510px] bg-[white] px-[65px] py-[60px] rounded-[4px] flex flex-col items-center gap-[20px] text-center">
                        <>
                            <TickMark />
                            <h2 className="font-bold text-[20px]">Ваши одговори су успешно сачувани!</h2>
                            <p className="text-[16px]">Хвала вам што сте попунили Образац 1 - Евиденција података о остваривању родне равноправности.</p>
                            <p className="text-[16px]">Ваше мишљење нам је важно! Молимо вас да попуните кратку анкету о вашем искуству са попуњавањем овог обрасца.</p>

                            <div className="flex flex-col gap-[20px] mt-[30px]">
                                <Button id="anketaButton" variant="contained" color="info">
                                    Попуните анкету
                                </Button>
                                <Button id="start" variant="contained" size="medium">
                                    почетна страница
                                </Button>
                            </div>
                        </>
                    </div>
                </div>
            </div>
            <div className="mt-10 w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} lastStep closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step6
