import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_16Entity } from '../../../../../models/Form7/Entities/Form7_16.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'

export function Step5({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_16Entity>({ control })

    const fields: ('o7_16_k5_brojKontanta' | 'o7_16_k5_brojMera' | 'o7_16_k5_brojObuka')[] = ['o7_16_k5_brojKontanta', 'o7_16_k5_brojMera', 'o7_16_k5_brojObuka']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')
    const goToNextStep = () => {
        if (!disabledNext && !errors?.o7_16_k4_brojObuka) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px] ">
                Број обука и број представника из сектора правосуђа, одбране и безбедности који су похађали обуке за реформу сектора безбедности, разоружање и реинтеграцију,
                хуманитарну помоћ, добру управу, људска права и транзициону правду.
            </h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_16_k5_brojKontanta" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
                    {errors['o7_16_k5_brojKontanta']?.type === 'required' && (
                        <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 w-[200px]">{'Попуните обавезно поље.'}</p>
                    )}
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">Број именованих и обучених контакт особа за примену НАП 1325 у органима државне управе и локалне самоуправе на свим нивоима</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_16_k5_brojMera" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
                    {errors['o7_16_k5_brojMera']?.type === 'required' && (
                        <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 w-[200px]">{'Попуните обавезно поље.'}</p>
                    )}
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">
                Број израђених и усвојених ЛАП-ова и уграђених мера које подржавају специфичне безбедносне потребе жена, девојака и девојчица у локалним заједницама посебно из
                вишеструко дискриминисаних и рањивих група
            </h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_16_k5_brojObuka" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
                    {errors['o7_16_k5_brojObuka']?.type === 'required' && (
                        <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 w-[200px]">{'Попуните обавезно поље.'}</p>
                    )}
                </div>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
