import React, { useState } from 'react'
import { StepInterface } from './Step1'
import { Form7_19Entity } from '../../../../../models/Form7/Entities/Form7_19.entity'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Button } from '@mui/material'
import Step7TableComponent from '../Components/Step7TableComponent'
import ActionButtons from '../../../../../components/ActionButtons'

export const Step6 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_19Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_19_k6_programiInovacije',
    })
    const name = 'o7_19_k6_programiInovacije'

    let disabledNext = watchedValues[name]?.length === 0

    fields.forEach((item, index) => {
        const { programName, applicationsZ, applicationsM, applicationsV, applicationsD, grantsZ, grantsM, grantsV, grantsD } = watchedValues[name]?.[index] || {}

        if (grantsZ === undefined || grantsZ === null || grantsZ?.toString() === '') {
            disabledNext = true
        } else if (grantsM === undefined || grantsM === null || grantsM?.toString() === '') {
            disabledNext = true
        } else if (grantsV === undefined || grantsV === null || grantsV?.toString() === '') {
            disabledNext = true
        } else if (grantsD === undefined || grantsD === null || grantsD?.toString() === '') {
            disabledNext = true
        } else if (applicationsZ === undefined || applicationsZ === null || applicationsZ?.toString() === '') {
            disabledNext = true
        } else if (applicationsM === undefined || applicationsM === null || applicationsM?.toString() === '') {
            disabledNext = true
        } else if (applicationsV === undefined || applicationsV === null || applicationsV?.toString() === '') {
            disabledNext = true
        } else if (applicationsD === undefined || applicationsD === null || applicationsD?.toString() === '') {
            disabledNext = true
        } else if (programName === undefined || programName === null || programName === '') {
            disabledNext = true
        } else disabledNext = false
    })

    const headers = ['', 'Укупно пријава', 'Проценат пријава %', 'Одобрене дотације', 'Проценат дотација %', 'Однос дотација и пријава %']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({
            programName: '',
            applicationsZ: undefined,
            applicationsM: undefined,
            applicationsV: undefined,
            applicationsD: undefined,
            grantsZ: undefined,
            grantsM: undefined,
            grantsV: undefined,
            grantsD: undefined,
        })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div>
                <h1 className="step-name">Програми подршке женама и мушкарцима од стране Фонда за иновациону делатност током године извештавања</h1>
                <div>
                    <Step7TableComponent fields={fields} headers={headers} adminView={adminView} name={name} control={control} errors={errors} remove={remove} />

                    <div className="flex flex-col mt-[56px]">
                        <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                            Додај програм
                        </Button>
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
                    </div>
                </div>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
