import { Button, Stack } from '@mui/material'
import React from 'react'

interface ActionButtonsInterface {
    nextButton: () => void
    previousButton: () => void
    nextButtonDisabled?: boolean
    previousButtonDisabled: boolean
    lastStep?: boolean
    isAdmin?: boolean
}
function ActionButtons({ nextButton, nextButtonDisabled = false, previousButton, previousButtonDisabled, lastStep = false, isAdmin = false }: ActionButtonsInterface) {
    return (
        <Stack spacing={'10px'} direction="row" className="mt-[50px]">
            <Button variant="contained" disabled={previousButtonDisabled} onClick={previousButton}>
                Претходни корак
            </Button>
            {lastStep ? (
                <Button variant="contained" type="submit" title="Заврши и пошаљи" disabled={nextButtonDisabled} onClick={nextButton}>
                    {isAdmin ? 'Заврши преглед' : 'Заврши и пошаљи'}
                </Button>
            ) : (
                <Button variant="contained" type="submit" title="Следећи корак" disabled={nextButtonDisabled} onClick={nextButton}>
                    Следећи корак
                </Button>
            )}
        </Stack>
    )
}

export default ActionButtons
