import { useEffect } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { TblOblast5 } from '../../../models/Form5/Form5.entity'
import ActionButtons from '../../../components/ActionButtons'
import { Button } from '@mui/material'
import ManagementPositionTable from '../Components/ManagementPositionTable'
import UnionPartyBodyTable from '../Components/UnionPartyBodyTable'
import { StepInterface } from './Step1'

const HEADER_LIST = ['Орган/тело', 'Врста', 'Мушкарци', 'Мушкарци %', 'Жене', 'Жене %']

function Step5({ control, nextStep, previousStep, previousButtonDisabled = false, isParty, errors, setValue, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast5>({ control })

    const {
        fields: firstArrayFields,
        append: appendToFirstArray,
        remove: removeFromFirstArray,
    } = useFieldArray({
        control,
        name: 'o5_k6_pozicije',
    })

    const handleAddFirstArrayField = () => {
        appendToFirstArray({ text: '', man: false, woman: false })
    }

    const handleAddSecondArrayField = () => {
        appendToSecondArray({ text: '', man: null, woman: null })
    }

    const {
        fields: secondArrayFields,
        append: appendToSecondArray,
        remove: removeFromSecondArray,
    } = useFieldArray({
        control,
        name: 'o5_k6_tela',
    })

    let noCategoryCheck = watchedValues?.o5_k6_tela?.[0]?.category === 'Нема тела' || false

    const handleCheckboxPress = (name: string, checked: boolean) => {
        const parts = name.split('.')

        const arrayName = parts[0]
        const index = parseInt(parts[1], 10)
        const fieldName = parts[2]

        if (fieldName === 'man') {
            setValue(`o5_k6_pozicije.${index}.woman`, !checked)
        } else {
            setValue(`o5_k6_pozicije.${index}.man`, !checked)
        }
    }

    useEffect(() => {
        if (!firstArrayFields.length) appendToFirstArray([{ text: '', man: false, woman: false }])
        if (!secondArrayFields.length) appendToSecondArray([{ text: '', man: null, woman: null }])
    }, [])

    const validateFirstArrayFields = (): boolean => {
        const newMap = watchedValues.o5_k6_pozicije?.map((field) => field.text != '' && (field.man || field.woman))
        return newMap?.includes(false) ? true : false
    }

    const validateFirstArrayErrors = (): boolean => {
        const newMap: any[] = errors.o5_k6_pozicije?.map((field: any) => !!field?.text || (!!field?.man && !!field?.woman))
        return newMap?.includes(true) ? true : false
    }

    const validateSecondArrayFields = (): boolean => {
        if (noCategoryCheck) {
            return false
        }
        const newMap = watchedValues.o5_k6_tela?.map(
            (field) => field.text != '' && !!field.category && (!!field.man || field.man === 0) && (!!field.woman || field.woman === 0) && !(field.man === 0 && field.woman === 0)
        )
        return newMap?.includes(false) ? true : false
    }

    const disabledNext = validateFirstArrayFields() || validateSecondArrayFields()
    const disabledAddMoreToFirstArray = validateFirstArrayFields()
    const disabledAddMoreToSecondArray = validateSecondArrayFields()

    const goToNexStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">
                {isParty
                    ? 'Навести кључне руководеће позиције у вашој политичкој странци и да ли се на њима налазе мушкарци или жене'
                    : 'Навести кључне руководеће позиције у вашој синдикалној организацији и да ли се на њима налазе мушкарци или жене '}
            </h1>
            <ManagementPositionTable
                fields={firstArrayFields}
                remove={removeFromFirstArray}
                control={control}
                errors={errors}
                handleCheckBox={handleCheckboxPress}
                watchedValues={watchedValues}
                disabled={adminView}
            />
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">
                {disabledAddMoreToFirstArray && validateFirstArrayErrors() ? 'Попуните обавезно поље и изаберите једну од понуђених опција.' : ''}
            </p>
            <Button variant="contained" onClick={handleAddFirstArrayField} disabled={disabledAddMoreToFirstArray} style={{ marginTop: 20 }}>
                Додај позицију
            </Button>
            <UnionPartyBodyTable
                fields={secondArrayFields}
                remove={removeFromSecondArray}
                control={control}
                header={HEADER_LIST}
                errors={errors}
                disabled={adminView}
                noCategory={noCategoryCheck}
            />
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">
                {errors['o5_k6_tela'] ? 'Попуните обавезно поље и изаберите једну од понуђених опција.' : ''}
            </p>
            <Button variant="contained" onClick={handleAddSecondArrayField} disabled={disabledAddMoreToSecondArray || noCategoryCheck} style={{ marginTop: 20 }}>
                Додај орган или тело
            </Button>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step5
