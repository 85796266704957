export const FORM1_TITLE = 'Образац 1 - Евиденција података о остваривању родне равноправности'
export const FORM2_TITLE = 'Образац 2 - Евиденција података о остваривању родне равноправности'
export const FORM3_TITLE = 'Образац 3 - Годишњи извештај о остваривању родне равноправности'
export const FORM4_TITLE_COUNCIL = 'Образац 4 - Годишњи извештај о остваривању  родне равноправности'
export const FORM4_TITLE_COMMISION = 'Образац 4 - Годишњи извештај комисије за родну равноправност о остваривању  родне равноправности'
export const FORM4_TITLE_BODY = 'Образац 4 - Годишњи извештај тела за родну равноправност о остваривању  родне равноправности'
export const FORM5_TITLE_STRANKE = 'Образац 5 - Извештај политичке странке о остваривању родне равноправности'
export const FORM5_TITLE_SINDIKATI = 'Образац 5 - Извештај синдикалне организације о остваривању родне равноправности'
export const FORM6_TITLE = 'Образац  6 - Извештај о спровођењу плана управљања ризицима од повреде принципа родне равноправности'
export const FORM7_TITLE = 'Образац 7 - Министарство надлежно за области рада, запошљавања, борачка и социјална питања'
