import React from 'react'
import Step7TableRowComponent from './Step7TableRowComponent'
import { Button } from '@mui/material'
import TextInputCell from '../../../../../components/Common/TextInputCell'
import { useWatch } from 'react-hook-form'
import { getPercentage } from '../../../../../utils/mathUtils'

interface Step7TableComponentInterface {
    fields: any[]
    headers: any[]
    adminView: boolean | undefined
    name: string
    control: any
    errors: any
    remove: any
}
const Step7TableComponent = ({ fields, headers, adminView, name, control, errors, remove }: Step7TableComponentInterface) => {
    const watchedValues = useWatch({
        control,
        name: name,
    })
    return (
        <table className="multiple-input-table mt-[20px]">
            {fields.map((field, index) => {
                const currentRowValues = watchedValues?.[index] || {}

                const applicationColumnTotal =
                    Number(currentRowValues.applicationsZ || 0) +
                    Number(currentRowValues.applicationsM || 0) +
                    Number(currentRowValues.applicationsV || 0) +
                    Number(currentRowValues.applicationsD || 0)

                const grantsColumnTotal =
                    Number(currentRowValues.grantsZ || 0) + Number(currentRowValues.grantsM || 0) + Number(currentRowValues.grantsV || 0) + Number(currentRowValues.grantsD || 0)

                return (
                    <div className="flex flex-row " key={index}>
                        <div className="flex !flex-col">
                            <tr className={`multiple-input-table-header mb-2`}>
                                {headers.map((head, indexH) => (
                                    <th className={`multiple-input-table-cell ${indexH === 0 && '!bg-white !border-none !p-0 min-w-[500px]'}`} key={indexH}>
                                        {indexH === 0 ? (
                                            <TextInputCell
                                                name={`${name}.${index}.programName`}
                                                control={control}
                                                placeholder="Назив програма, текст (50 карактера)*"
                                                errors={errors}
                                                isRequired={!adminView}
                                                maxNumberOfCharacter={50}
                                                height={70}
                                                disabled={adminView}
                                            />
                                        ) : (
                                            head
                                        )}
                                    </th>
                                ))}
                            </tr>
                            <Step7TableRowComponent
                                name={`${name}.${index}.applicationsZ`}
                                name1={`${name}.${index}.grantsZ`}
                                control={control}
                                index={index}
                                errors={errors}
                                isRequired={!adminView}
                                text="Жена"
                                precentageValue={getPercentage(currentRowValues.applicationsZ, applicationColumnTotal)}
                                precentage1Value={getPercentage(currentRowValues.grantsZ, grantsColumnTotal)}
                                precentage2Value={getPercentage(currentRowValues.grantsZ, currentRowValues.applicationsZ)}
                            />
                            <Step7TableRowComponent
                                name={`${name}.${index}.applicationsM`}
                                name1={`${name}.${index}.grantsM`}
                                control={control}
                                index={index}
                                errors={errors}
                                isRequired={!adminView}
                                text="Мушкарац"
                                precentageValue={getPercentage(currentRowValues.applicationsM, applicationColumnTotal)}
                                precentage1Value={getPercentage(currentRowValues.grantsM, grantsColumnTotal)}
                                precentage2Value={getPercentage(currentRowValues.grantsM, currentRowValues.applicationsM)}
                            />
                            <Step7TableRowComponent
                                name={`${name}.${index}.applicationsV`}
                                name1={`${name}.${index}.grantsV`}
                                control={control}
                                index={index}
                                errors={errors}
                                isRequired={!adminView}
                                text="Подједнако власништво"
                                precentageValue={getPercentage(currentRowValues.applicationsV, applicationColumnTotal)}
                                precentage1Value={getPercentage(currentRowValues.grantsV, grantsColumnTotal)}
                                precentage2Value={getPercentage(currentRowValues.grantsV, currentRowValues.applicationsV)}
                            />
                            <Step7TableRowComponent
                                name={`${name}.${index}.applicationsD`}
                                name1={`${name}.${index}.grantsD`}
                                control={control}
                                index={index}
                                errors={errors}
                                isRequired={!adminView}
                                text="Друго"
                                precentageValue={getPercentage(currentRowValues.applicationsD, applicationColumnTotal)}
                                precentage1Value={getPercentage(currentRowValues.grantsD, grantsColumnTotal)}
                                precentage2Value={getPercentage(currentRowValues.grantsD, currentRowValues.applicationsD)}
                            />
                        </div>
                        {fields.length >= 2 ? (
                            <div className="ml-[20px] flex justify-center items-center ">
                                <Button color="error" variant="contained" onClick={() => remove(index)} disabled={adminView}>
                                    Обриши
                                </Button>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )
            })}

            <div className="h-[2px]"> {errors[name] && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
        </table>
    )
}

export default Step7TableComponent
