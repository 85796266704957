import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import '../../../assets/styles/SimpleTable.css'
import { SelectYesNoComponent } from '../../../components/Common/SelectComponent'
import SimpleInputRowForObject from '../../Form2/Components/SimpleImputRowForObject'
import UploadFile from '../../../components/UploadFile'
import HiddenDatePicker from '../../../components/Common/DatePicker'
import { StepInterface } from './Step1'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControlWithError'

function Step18({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })

    //o1_k18_employeeCount
    const fields: ('o1_k18_firstName' | 'o1_k18_lastName' | 'o1_k18_phoneNumber' | 'o1_k18_email')[] = ['o1_k18_firstName', 'o1_k18_lastName', 'o1_k18_phoneNumber', 'o1_k18_email']
    const disabledFields: ('o1_k18_education_date' | 'o1_k18_education_name' | 'o1_k18_education_org' | 'o1_k18_education_teacher')[] = [
        'o1_k18_education_date',
        'o1_k18_education_name',
        'o1_k18_education_org',
        'o1_k18_education_teacher',
    ]

    const disabledNext = !fields.every((e) => !!watchedValues && !!watchedValues[e])
    const disabledNextWithDisabledFields = !disabledFields.every((e) => !!watchedValues && !!watchedValues[e])

    const goToNextStep = () => {
        if (adminView) {
            nextStep()
        } else if (watchedValues.o1_k18_has_education) {
            if (!disabledNext && !disabledNextWithDisabledFields) {
                nextStep()
            }
        } else if (!watchedValues.o1_k18_employeeCount) {
            nextStep()
        } else {
            if (!disabledNext) {
                nextStep()
            }
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    const renderErrorMessage = (field: string) => {
        const error = errors?.[field]

        if (!error) return null

        let message = 'Попуните обавезно поље'

        if (error?.type === 'pattern') {
            message += ', Електронска адреса није исправно попуњена.'
        } else {
            message += '.'
        }

        return <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">{message}</p>
    }

    const checkDisabled = () => {
        return !watchedValues?.o1_k18_has_education || adminView
    }

    const showData = watchedValues.o1_k18_employeeCount || false
    return (
        <div onKeyDown={handleKeyDown}>
            <h1 className="step-name">
                18. Подаци о лицу задуженом за родну равноправност код органа јавне власти који имају више од 50 запослених и јавно ангажованих лица (уколико постоје у бази за ово
                правно лице повући ће се аутоматски, а корисник може да едитује):
            </h1>
            <div className="mt-5">
                <p>Да ли ваша организација има:</p>
                <RadioButtonsControl control={control} disabled={adminView} name="o1_k18_employeeCount" options={['Мање од 50 запослених', 'Више од 50 запослених']} boolean />
            </div>
            {showData && (
                <>
                    <div className="mt-[20px] flex flex-col gap-[15px]">
                        <SimpleInputRowForObject
                            title="Име"
                            name="o1_k18_firstName"
                            maxNumberOfCharacter={50}
                            control={control}
                            errors={errors}
                            isRequired={showData}
                            isName
                            disabled={adminView}
                            width={800}
                        />
                        <SimpleInputRowForObject
                            title="Презиме"
                            name="o1_k18_lastName"
                            maxNumberOfCharacter={50}
                            control={control}
                            errors={errors}
                            isRequired={showData}
                            isName
                            disabled={adminView}
                            width={800}
                        />
                        <SimpleInputRowForObject
                            title="Контакт телефон"
                            name="o1_k18_phoneNumber"
                            maxNumberOfCharacter={50}
                            control={control}
                            errors={errors}
                            isRequired={showData}
                            disabled={adminView}
                            width={800}
                        />
                        <SimpleInputRowForObject
                            title="Емаил"
                            name="o1_k18_email"
                            maxNumberOfCharacter={100}
                            control={control}
                            errors={errors}
                            isRequired={showData}
                            isEmail
                            disabled={adminView}
                            width={800}
                        />
                        {/* <SimpleInputRowForObject
                    title="Назив радног места"
                    name="submitted_by.workplace_name"
                    maxNumberOfCharacter={200}
                    control={control}
                    errors={errors}
                    isRequired={true}
                /> */}
                    </div>
                    <div className="h-[2px]">
                        {errors.o1_k18_firstName && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o1_k18_firstName')}</p>}
                        {!errors.o1_k18_firstName && errors.o1_k18_lastName && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o1_k18_lastName')}</p>}
                        {!errors.o1_k18_firstName && !errors.o1_k18_lastName && errors.o1_k18_phoneNumber && (
                            <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o1_k18_phoneNumber')}</p>
                        )}
                        {!errors.o1_k18_firstName && !errors.o1_k18_lastName && !errors.o1_k18_phoneNumber && errors.o1_k18_email && (
                            <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o1_k18_email')}</p>
                        )}
                    </div>
                    <h2 className="mt-[50px]">Прилагање одлуке о именовању у ПДФ формату</h2>
                    <UploadFile control={control} name={'o1_k18_files'} disabled={adminView} />
                    <div className="mb-[50px]">
                        <p className="mt-[20px] mb-[20px]">
                            Да ли је лице задужено за родну равноправност у години извештавања прошло стручну обуку из области родне равноправности?
                        </p>
                        <SelectYesNoComponent control={control} name="o1_k18_has_education" value={watchedValues.o1_k18_has_education} />
                    </div>
                    <div className="flex flex-col gap-[15px]">
                        <p className="mb-[5px]">Уколико је одговор да - када под којим називом, и ко је био организатор и реализатор поменуте обуке:</p>
                        <div className="flex flex-row gap-[10px] h-[50px]">
                            <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                                <p className="text-center mx-auto">Датум обуке</p>
                            </div>
                            <HiddenDatePicker
                                tabIndex={0}
                                name={`o1_k18_education_date`}
                                value={watchedValues?.o1_k18_education_date}
                                control={control}
                                errors={errors}
                                isRequired={checkDisabled() ? false : true}
                                lastStep
                                disabled={checkDisabled()}
                            />
                        </div>
                        <SimpleInputRowForObject
                            title="Назив обуке"
                            name="o1_k18_education_name"
                            maxNumberOfCharacter={200}
                            control={control}
                            disabled={checkDisabled()}
                            errors={errors}
                            isRequired={checkDisabled() ? false : true}
                        />
                        <SimpleInputRowForObject
                            title="Организатор обуке"
                            name="o1_k18_education_org"
                            maxNumberOfCharacter={200}
                            control={control}
                            disabled={checkDisabled()}
                            errors={errors}
                            isRequired={checkDisabled() ? false : true}
                        />
                        <SimpleInputRowForObject
                            title="Реализатор обуке"
                            name="o1_k18_education_teacher"
                            maxNumberOfCharacter={200}
                            control={control}
                            disabled={checkDisabled()}
                            errors={errors}
                            isRequired={checkDisabled() ? false : true}
                        />
                    </div>
                    <div className="h-[2px]">
                        {watchedValues.o1_k18_has_education && (
                            <>
                                {errors.o1_k18_education_date && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o1_k18_education_date')}</p>}
                                {!errors.o1_k18_education_date && errors.o1_k18_education_name && (
                                    <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o1_k18_education_name')}</p>
                                )}
                                {!errors.o1_k18_education_date && !errors.o1_k18_education_name && errors.o1_k18_education_org && (
                                    <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o1_k18_education_org')}</p>
                                )}
                                {!errors.o1_k18_education_date && !errors.o1_k18_education_name && !errors.o1_k18_education_org && errors.o1_k18_education_teacher && (
                                    <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o1_k18_education_teacher')}</p>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step18
