import React, { useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_2Entity } from '../../../../../models/Form7/Entities/Form7_2.entity'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { calculateDifference, calculateSumOfTwoValues } from '../../../../../utils/mathUtils'

export interface StepInterface {
    control?: Control<Form7_2Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

interface RowInterface {
    label: string
    fields: (keyof Form7_2Entity)[]
}

const rowsTable: RowInterface[] = [
    { label: '+15 година живота', fields: ['o7_2_k5_z_0_14', 'o7_2_k5_m_0_14'] },
    { label: '15-24 година живота', fields: ['o7_2_k5_z_15_24', 'o7_2_k5_m_15_24'] },
    { label: '25-44 година живота', fields: ['o7_2_k5_z_25_44', 'o7_2_k5_m_25_44'] },
    { label: '45-54 година живота', fields: ['o7_2_k5_z_45_54', 'o7_2_k5_m_45_54'] },
    { label: '55-64 година живота', fields: ['o7_2_k5_z_55_64', 'o7_2_k5_m_55_64'] },
    { label: '65+ година живота', fields: ['o7_2_k5_z_65', 'o7_2_k5_m_65'] },
]

const header: string[] = ['Старосна доб', 'Жене', 'Мушкарци', 'Србија']

const allFieldsTable = [
    'o7_2_k5_z_0_14',
    'o7_2_k5_z_15_24',
    'o7_2_k5_z_25_44',
    'o7_2_k5_z_45_54',
    'o7_2_k5_z_55_64',
    'o7_2_k5_z_65',

    'o7_2_k5_m_0_14',
    'o7_2_k5_m_15_24',
    'o7_2_k5_m_25_44',
    'o7_2_k5_m_45_54',
    'o7_2_k5_m_55_64',
    'o7_2_k5_m_65',
] as const

type AllFieldsType = (typeof allFieldsTable)[number]

function Step5({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_2Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...allFieldsTable]
    const [disabled, setDisabled] = useState(false)
    const hasRowErrors = rowsTable.some((row) => row.fields.some((field) => errors?.[field]))

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">5. Процентуални удео времена које се проводи у вршењу неплаћених послова у домаћинству и бризи о другима, према старости и полу:</h1>
            <table className="complex-table mt-[30px]">
                <tr className="complex-table-header">
                    {header.map((head, index) => (
                        <th className="complex-table-cell" style={{ width: index === 0 ? 450 : 140 }} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {rowsTable.map((row, index) => (
                    <tr className="complex-table-row" key={index}>
                        <td className="complex-table-cell  disabled" style={{ width: 450 }}>
                            {row.label}
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[0] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={adminView}
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[1] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={adminView}
                            />
                        </td>
                        <td className="complex-table-cell disabled">{calculateSumOfTwoValues(watchedValues[row.fields[0]], watchedValues[row.fields[1]], true)}</td>
                    </tr>
                ))}
                <div className="h-[20px]"> {hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step5
