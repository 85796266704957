import { Modal, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TutorialRegister from './TutorialSegments/TutorialRegister'
import { ReactComponent as CloseFilled } from '../../assets/svg/CloseFilled.svg'
import TutorialDashboard from './TutorialSegments/TutorialDashboard'
import TutorialForm from './TutorialSegments/TutorialForm'
import ReactModal from 'react-modal'

interface TutorialInterface {
    segment: string
    open: boolean
    onClose: () => void
    forceOpen?: boolean
}

const TutorialModal = ({ segment, open, onClose, forceOpen }: TutorialInterface) => {
    const [isSkipped, setIsSkipped] = useState<boolean>(false)
    const [checkTourOpen, setCheckTourOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        const tutorialSkipped = localStorage.getItem(`tutorialSkipped-${segment}`)
        if (tutorialSkipped === 'true') {
            setIsSkipped(true)
        }
        setLoading(false)
    }, [segment])

    const skipClick = () => {
        localStorage.setItem(`tutorialSkipped-${segment}`, 'true')
    }

    if ((loading || isSkipped) && !forceOpen) return null

    return (
        <ReactModal
            ariaHideApp={false}
            //closeTimeoutMS={600}
            isOpen={open}
            onAfterOpen={() => {
                const el = document.getElementById('scroll-target')
                if (el) el.classList.add('no-scroll-behind')
            }}
            onRequestClose={onClose}
            className="no-scrollbar outline-0"
            style={{
                overlay: {
                    zIndex: 5,
                    background: '#000000A6',
                },
                content: {
                    outline: 'none',
                    overflowY: checkTourOpen ? 'hidden' : 'auto',
                    padding: 0,
                    opacity: 1,
                    minWidth: '50%',
                    maxWidth: '70%',
                    margin: 'auto',
                    backgroundColor: 'transparent',
                    height: '100%',
                    // maxHeight: '90%',
                    border: '0px',
                    boxShadow: 'none',
                    paddingTop: 30,
                    paddingBottom: 30,
                    overflow: 'auto',
                },
            }}
        >
            <div className="w-full  bg-[white] px-[30px] py-[40px] rounded-[4px] relative">
                <Button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '20px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'black',
                    }}
                >
                    <CloseFilled />
                </Button>
                <div className="flex-1">{segment === 'login' && <TutorialRegister skipClick={skipClick} onClose={onClose} />}</div>
                <div className="flex-1">{segment === 'home' && <TutorialDashboard skipClick={skipClick} onClose={onClose} />}</div>
                <div className="flex-1">{segment === 'form' && <TutorialForm skipClick={skipClick} onClose={onClose} setCheckTourOpen={setCheckTourOpen} />}</div>
            </div>
        </ReactModal>
    )
}

export default TutorialModal
