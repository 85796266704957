import { useEffect, useState } from 'react'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import { Button } from '@mui/material'
import DocumentsTable from '../../../Components/DocumentsTable'
import ActionButtons from '../../../../../components/ActionButtons'
import UploadFile from '../../../../../components/UploadFile'
import { Form7_11Entity } from '../../../../../models/Form7/Entities/Form7_11.entity'

export interface StepInterface {
    control?: Control<Form7_11Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

export interface RowInterface {
    label: string
    subtitle: string
    field: number
}
const fileTypes = ['PDF']
const arrayName = 'o7_11_k1_fileDescriptions'

const HEADER_LIST = ['Редни број', 'Однос жртве и учиниоца кривичног дела', 'Средство којим је извршено кривично дело', 'Линк (ако постоји)']

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, setValue, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_11Entity>({ control })

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: arrayName,
    })

    useEffect(() => {
        if (!fields.length) append([{ fileName: '', type: '', link: '' }])
    }, [])

    const handleAddField = () => {
        append({ fileName: '', type: '', link: '' })
    }

    const validateFields = (): boolean => {
        if (adminView) {
            return true
        }
        const newMap = watchedValues.o7_11_k1_fileDescriptions?.map((field) => !!field.fileName && !!field.type)
        return newMap?.includes(false) ? true : false
    }

    const disabledNext = validateFields()
    const disabledAddMore = validateFields()

    const goToNexStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <h1 className="step-name mb-1">1. Пример докумената:</h1>
            <ul className="list-disc list-inside space-y-1 ">
                <li>
                    Усвојени или измењени прописи којима се унапређује ефикасност институција у спречавању и заштити жена и мушкараца, девојчица и дечака, у јавној и приватној
                    сфери, од свих облика родно заснованог насиља
                </li>
                <li>Усвојени или измењени прописи којима се обезбеђује усклађивање законских дефиниција различитих облика насиља</li>
                <li>Усвојени или измењени прописи којима се унапређује координисани одговор институција на све облике родно заснованог насиља</li>
                <li>Увид у усвојене или измењене прописе везане за спречавање и сузбијање родно заснованог насиља и њихову усклађеност са међународним стандардима (ЕУ, УН, СЕ)</li>
                <li>Извештаји Министарства правде (МП), група за координацију и сарадњу, извештај МУП, ЦСР</li>
                <li>Извештаји Министарства унутрашњих послова (МУП) и МП, и извештаји специјализованих ОЦД</li>
                <li>Истраживање о преваленци насиља у Републици Србији, извештај о ефектима јавних кампања, анализе о извештавању медија о случајевима родно заснованог насиља</li>
                <li>CEDAW извештај и пратећи извештај, GREVIO извештај и пратећи извештај</li>
            </ul>
            <h1 className="mt-5">Број убијених жена од стране мушкараца услед родно заснованог насиља</h1>
            <DocumentsTable name={arrayName} fields={fields} remove={remove} control={control} header={HEADER_LIST} errors={errors} disabled={adminView} />
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{errors['o7_11_k1_fileDescriptions'] ? 'Попуните обавезно поље.' : ''}</p>
            <div className="flex flex-row items-center gap-10 mt-5">
                <Button variant="contained" onClick={handleAddField} disabled={disabledAddMore}>
                    Додај још
                </Button>
                <p>* линк није обавезан</p>
            </div>
            <UploadFile control={control} name={'o7_11_k1_files'} disabled={adminView} fileTypes={fileTypes} errors={errors} isSignRequired={false} />

            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
