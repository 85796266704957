// Console disabler should be first

// Then all your imports
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import AppProviders from './components/AppProvider'

if (process.env.REACT_APP_ENV === 'prod_gov_prod') {
    const noop = (): void => {}

    // Save original console with proper typing
    const originalConsole: Console = { ...console }

    // Type safe way to replace console methods
    ;(['log', 'info', 'warn', 'debug'] as (keyof Console)[]).forEach((method) => {
        ;(console[method] as unknown) = noop
    })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <AppProviders>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AppProviders>
)

reportWebVitals()
