import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_17Entity } from '../../../../../models/Form7/Entities/Form7_17.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import { useEffect, useState } from 'react'

export function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_17Entity>({ control })

    const [percentage, setPercentage] = useState<number | null>(null)

    const calculateDifferencePercentage = (first: number, second: number) => {
        if (first === 0) return 0
        return Math.round(((second - first) / first) * 10000) / 100
    }

    useEffect(() => {
        if (watchedValues.o7_17_k2_indikatoriPrethodna != null && watchedValues.o7_17_k2_indikatoriTrekuca != null) {
            console.log(calculateDifferencePercentage(watchedValues.o7_17_k2_indikatoriPrethodna, watchedValues.o7_17_k2_indikatoriTrekuca))
            setPercentage(calculateDifferencePercentage(watchedValues.o7_17_k2_indikatoriPrethodna, watchedValues.o7_17_k2_indikatoriTrekuca))
        } else {
            setPercentage(null)
        }
    }, [watchedValues.o7_17_k2_indikatoriPrethodna, watchedValues.o7_17_k2_indikatoriTrekuca])

    const fields: ('o7_17_k2_indikatoriPrethodna' | 'o7_17_k2_indikatoriTrekuca' | 'o7_17_k2_opis')[] = [
        'o7_17_k2_indikatoriPrethodna',
        'o7_17_k2_indikatoriTrekuca',
        'o7_17_k2_opis',
    ]

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')
    const goToNextStep = () => {
        if (!disabledNext && !errors?.o7_17_k4_brojObuka) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px] ">
                Износ/проценат средстава намењен финансирању примене НАП 1325 у буџетима на свим нивоима власти, као и проценат средстава који се издваја за финансирање услуга које
                пружају ОЦД:
            </h1>
            <h1 className="step-name mb-[20px] ">Број родно одговорних индикатора у претходној години</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px]  justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_17_k2_indikatoriPrethodna" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
                    {errors['o7_17_k2_indikatoriPrethodna']?.type === 'required' && (
                        <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 w-[200px]">{'Попуните обавезно поље.'}</p>
                    )}
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">Број родно одговорних индикатора у текућој години години</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell name="o7_17_k2_indikatoriTrekuca" control={control} errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
                    {errors['o7_17_k2_indikatoriTrekuca']?.type === 'required' && (
                        <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 w-[200px]">{'Попуните обавезно поље.'}</p>
                    )}
                </div>
            </div>
            <h1 className="step-name mb-[20px] ">Процентуална разлика броја индикатора у односу на претходну годину</h1>
            <div className=" flex flex-row gap-5 mb-[40px]">
                <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                    <PositiveNumberInputCell
                        name=""
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={52}
                        disabled={true}
                        precentage
                        decimal
                        placeholder={percentage !== null ? percentage.toString() : ''}
                    />
                </div>
            </div>

            <div className="mt-[50px] ">
                <h4 className="mb-[10px]">Да ли сте и како у години извештавања унапредили прикупљање података у вези са родно осетљивом статистиком? </h4>
                <div className="flex flex-col">
                    <Textbox
                        name="o7_17_k2_opis"
                        control={control}
                        placeholder="Текст (500 карактера)*"
                        errors={errors}
                        isRequired={adminView ? false : 'Попуните обавезно поље.'}
                        maxNumberOfCharacters={500}
                        disabled={adminView}
                    />
                </div>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
