import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { Entity } from './types'
import { convertToCyrillic, convertToLatin } from '../../utils/searchTranslation'
import { useInView } from 'react-intersection-observer'

interface EntitiesListProps {
    entities: Entity[]
    onSelect: (id: string) => void
    type?: 'category' | 'subcategory' | 'entity' | 'pib'
    searchTerm: string
    setSearchTerm: (term: string) => void
    pibSearch: string
    setPibSearch: (term: string) => void
    onLoadMore: () => void
}

export const EntitiesList: React.FC<EntitiesListProps> = ({ entities, onSelect, type, searchTerm, pibSearch, setPibSearch, setSearchTerm, onLoadMore }) => {
    const { ref, inView } = useInView({
        threshold: 0.1,
    })

    useEffect(() => {
        if (inView) {
            onLoadMore()
        }
    }, [inView])
    return (
        <div className="flex-1 h-full  max-h-[600px] min-h-[600px] flex flex-col ">
            <TextField
                label="Претрага по називу"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '100%', marginBottom: 10 }}
            />
            <TextField label="Претрага по ПИБ-у" variant="outlined" value={pibSearch} onChange={(e) => setPibSearch(e.target.value)} style={{ width: '100%', marginBottom: 10 }} />
            <div className=" flex-1 border overflow-y-auto border-[gray] rounded-md gap-[5px] flex flex-col p-[10px]">
                {entities.map((entity) => (
                    <Button variant="contained" color={entity.selected ? 'primary' : 'inherit'} key={entity.id} onClick={() => onSelect(entity.id)}>
                        <div>{entity.subjectName}</div>
                        {!!entity.pib && <span className="ml-[5px]">ПИБ: {entity.pib}</span>}
                    </Button>
                ))}
                <div ref={ref} style={{ height: 20 }} />
            </div>
        </div>
    )
}
