import React, { useState } from 'react'
import { TutorialStepInterface } from './Step1'
import selectSearch from '../../../../assets/images/LoginTutorialImages/SelectSearch.png'
import Actions from '../../Components/Actions'
import { Button, FormControl, FormControlLabel, FormHelperText, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Tour from 'reactour'

const Step6 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const categories = [
        {
            id: 1,
            name: 'Категорија 1',
        },
        {
            id: 2,
            name: 'Категорија 2',
        },
        {
            id: 3,
            name: 'Категорија 3',
        },
        {
            id: 4,
            name: 'Категорија 4',
        },
        {
            id: 5,
            name: 'Категорија 5',
        },
    ]
    const tourSteps = [
        {
            selector: 'div[id="main"]',
            content: 'Након клика на дугме „Региструј се“, приказује се екран за одабир категорије обвезника у оквиру извештавања о родној равноправности.',
        },
        {
            selector: 'div[id="cats"]',
            content: 'Корисник има могућност избора једне од понуђених подкатегорија.',
        },
        {
            selector: 'button[id="next"]',
            content: 'Након избора одговарајуће подкатегорије, корисник треба да кликне на дугме „ДАЉЕ“ које се налази испод листе подкатегорија како би прешао на следећи корак.',
        },
    ]

    const handleTourClose = () => {
        setIsTourOpen(false)
    }
    return (
        <div className="w-full h-full flex items-center flex-col">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div id="main" className="w-full h-full flex items-center justify-center flex-col border border-borderTutorial p-10">
                <div className="max-w-[650px] min-w-[600px]">
                    <p>Поштовани, како бисте током извештавања правилно испунили своју законску обавезу молимо Вас да пажљиво одаберете којој категорији обвезника припадате.</p>
                    <p className="mt-[20px]">Овај чаробњак ће Вам помоћи да се на лак начин правилно разврстате:</p>
                    <div id="cats" className="!mt-[40px]">
                        <FormControl>
                            <p className="mb-[20px]">Да ли је организација - Организација:</p>
                            <RadioGroup>
                                {categories.map((cat: any, index) => (
                                    <FormControlLabel
                                        disabled
                                        value={cat.id}
                                        checked={index === 0}
                                        control={
                                            <Radio
                                                sx={{
                                                    color: '#1976d2',
                                                    '&.Mui-disabled': {
                                                        color: '#1976d2',
                                                    },
                                                }}
                                            />
                                        }
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                color: 'black',
                                                '&.Mui-disabled': {
                                                    color: 'black',
                                                },
                                            },
                                        }}
                                        label={cat.name}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="flex flex-row justify-between mt-[30px] w-full max-w-[600px]">
                        <button
                            disabled
                            className=" invisible w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]"
                        >
                            НАЗАД
                        </button>
                        <button
                            id="next"
                            disabled
                            className="w-full h-[42px] flex items-center justify-center rounded-[4px] !bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer' mt-[43px] max-w-[200px]"
                        >
                            ДАЉЕ
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step6
