import { Organization } from '../Organization.entity'
import { SubmittedBy } from '../SubmittedBy.entity'
import { File } from '../File.entity'
import { GRADE, YESNOOTHER_ENUM } from '../CommonTypes'

export const NumberOfSteps = 10
export type TblOblast3Keys = keyof TblOblast3
export interface TblOblast3 {
    report_id?: string // uuid
    rep_year?: number // year
    submitted_date?: Date
    submitted_place?: Date
    category?: string // category
    organization?: Organization // uuid
    status?: number // rep_status
    // Korak 1
    o3_k1_ima_plan?: YESNOOTHER_ENUM
    o3_k1_d_donosenja?: Date
    o3_k1_d_objavljivanja?: Date
    o3_k1_link?: string // nvarchar(2048)
    o3_k1_br_glasnika?: string // nvarchar(100)
    o3_k1_d_izlaska_glasnika?: Date
    o3_k1_plan_file?: File[]
    // Korak 2
    o3_k2_ocena?: GRADE
    // Korak 3
    o3_k3_spisak?: string // nvarchar(2048)
    // Korak 4
    o3_k4_razlozi?: string // nvarchar(2048)
    // Korak 5
    o3_k5_pocetak?: string // nvarchar(100)
    // Korak 6
    o3_k6_nacin?: string // nvarchar(2048)
    // Korak 7
    o3_k7_prestanak?: string // nvarchar(100)
    // Korak 8
    o3_k8_razlozi?: string // nvarchar(2048)
    // Korak 9
    submitted_by?: SubmittedBy // uuid

    o3_k9_firstName?: string

    o3_k9_lastName?: string

    o3_k9_phoneNumber?: string

    o3_k9_email?: string

    o3_k9_has_education?: boolean

    o3_k9_education_name?: string

    o3_k9_education_date?: Date

    o3_k9_education_org?: string

    o3_k9_education_teacher?: string

    o3_k9_files?: File[]
}
