import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/ComplexTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../components/Tables/ComplexTable'
import { StepInterface } from './Step1'
import Textbox from '../../../components/Common/Textbox'
import { isNotEmpty } from '../../../utils/Validation'
import { useEffect } from 'react'

interface RowInterface {
    label: string
    fields: (keyof TblOblast1)[]
}

const rows: RowInterface[] = [
    { label: 'Од 15-30 година живота', fields: ['o1_k9_z_15_30', 'o1_k9_m_15_30'] },
    { label: 'Од 31-40 година живота', fields: ['o1_k9_z_31_40', 'o1_k9_m_31_40'] },
    { label: 'Од 41-50 година живота', fields: ['o1_k9_z_41_50', 'o1_k9_m_41_50'] },
    { label: 'Од 51-60 година живота', fields: ['o1_k9_z_51_60', 'o1_k9_m_51_60'] },
    { label: 'Од 61-70 година живота и више', fields: ['o1_k9_z_61_70', 'o1_k9_m_61_70'] },
]

const allFields = [
    'o1_k9_m_15_30',
    'o1_k9_z_15_30',
    'o1_k9_m_31_40',
    'o1_k9_z_31_40',
    'o1_k9_m_41_50',
    'o1_k9_z_41_50',
    'o1_k9_m_51_60',
    'o1_k9_z_51_60',
    'o1_k9_m_61_70',
    'o1_k9_z_61_70',
    'o1_k9_razlog',
] as const

const allMaleFields = [...allFields.filter((e) => e.includes('_m_'))] as const
const allFemaleFields = [...allFields.filter((e) => e.includes('_z_'))] as const

type AllFieldsType = (typeof allFields)[number]

function Step9({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const filteredFields = allFields.filter((key) => key !== 'o1_k9_razlog')

    const tbDisabled = filteredFields.every((key) => watchedValues[key] === 0)

    const allFieldsHaveValidValues = filteredFields.every((key) => {
        const value = watchedValues[key]
        return value === 0 || isNotEmpty(value)
    })

    const shouldRequireTextbox = !tbDisabled && !adminView
    const textboxFilled = isNotEmpty(watchedValues.o1_k9_razlog)

    const goToNextStep = () => {
        const canProceed = (allFieldsHaveValidValues && (!shouldRequireTextbox || textboxFilled)) || adminView

        if (canProceed) {
            nextStep()
        }
    }

    const checkDisabled = () => {
        return tbDisabled || adminView
    }

    useEffect(() => {
        if (tbDisabled) {
            clearErrors('o1_k9_razlog')
        }
    }, [watchedValues])

    return (
        <div>
            <h1 className="step-name">
                9. Број примљених пријава запослених и радно ангажованих лица о њиховој изложености узнемиравању, сексуалном узнемиравању или уцењивању или неком другом поступању
                које има за последицу дискриминацију на основу пола, односно рода, разврстаних по полу и старосној доби:
            </h1>
            <ComplexTable
                rows={rows}
                control={control}
                allFemaleFields={[...allFemaleFields]}
                allMaleFields={[...allMaleFields]}
                allFields={[...allFields]}
                header={['Старост', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']}
                errors={errors}
                disabled={adminView}
            />
            <div className="mt-[50px]">
                <h4 className="mb-[10px]">Разлози:* </h4>
                <Textbox
                    name="o1_k9_razlog"
                    control={control}
                    placeholder="Текст (200 карактера)"
                    errors={errors}
                    isRequired={!checkDisabled()}
                    rules={checkDisabled() ? {} : { required: 'Обавезно попуните ово поље' }}
                    maxNumberOfCharacters={200}
                    disabled={checkDisabled()}
                />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step9
