import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { STATUS } from '../../../../models/CommonTypes'
import { TutorialStepInterface } from './Step1'
import Tour from 'reactour'
import Actions from '../../Components/Actions'

export const ObrazacButton = ({ path, text, color = '#32a869', disabled = false }: { path: string; text: string; color?: string; disabled?: boolean }) => {
    return (
        <Button
            disabled
            variant="contained"
            className="cursor-default hover:cursor-default"
            sx={{
                '&.Mui-disabled': {
                    opacity: 1,
                    backgroundColor: '#1976d2',
                    color: 'white',
                    pointerEvents: 'none',
                },
            }}
            style={{ backgroundColor: disabled ? '#e0e0e0' : '#0D47A1' }}
        >
            {text}
        </Button>
    )
}

const Step3 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const user = {
        subjectName: 'Фирма 1',
    }
    const remainingForms = [
        { name: 'Образац 2', path: 'obrazac2' },
        { name: 'Образац 3', path: 'obrazac3' },
    ]

    const startedForms = [
        { name: 'Образац 1', path: 'obrazac1' },
        { name: 'Образац 4', path: 'obrazac4' },
        { name: 'Образац 6', path: 'obrazac6' },
    ]
    const headerMapping = [
        { header: 'Година извештаја', value: 'year' },
        // { header: 'Категорија подносиоца', value: 'category' },
        // { header: 'Подкатегорија подносиоца', value: 'subCategory' },
        { header: 'Назив подносиоца', value: 'name', width: 300 },
        { header: 'Статус извештаја', value: 'status' },
        { header: 'Образац 1', value: 'f1' },
        { header: 'Образац 2', value: 'f2' },
        { header: 'Образац 3', value: 'f3' },
        { header: 'Образац 4', value: 'f4' },
        { header: 'Образац 5', value: 'f5' },
        { header: 'Образац 6', value: 'f6' },
        { header: 'Образац 7', value: 'f7' },
    ]
    const finishedForms = [
        {
            id: 1,
            report_year: '2024',
            submittedBy: {
                subjectName: 'Фирма 1',
            },
            status: 0,
            obrasci: [[{ id: '1a', report_id: '101' }], [{ id: '1b', report_id: null }]],
        },
        {
            id: 2,
            report_year: '2023',
            submittedBy: {
                subjectName: 'Фирма 1',
            },
            status: 1,
            obrasci: [[{ id: '2a', report_id: '202' }], [{ id: '2b', report_id: '203' }]],
        },
        {
            id: 3,
            report_year: '2022',
            submittedBy: {
                subjectName: 'Фирма 1',
            },
            status: 2,
            obrasci: [[{ id: '2a', report_id: '202' }], [{ id: '2b', report_id: '203' }]],
        },
    ]

    const tourSteps = [
        {
            selector: 'div[id="main"]',
            content: 'Ова страница приказује интерфејс за подношење извештаја за Обвезника Закона о родној равноправности.',
        },
        {
            selector: 'h2[id="title"]',
            content: 'У овом пољу приказано је име обвезника за којег попуњавате извештај као и година за коју се извештај подноси.',
        },
        {
            selector: 'div[id="notStarted"]',
            content:
                'Испод имена обвезника, у делу са насловом „Потребно је да попуните следеће обрасце“, налази се листа свих обавезних образаца које обвезник треба да попуни у складу са законом. Кликом на дугме одређеног обрасца, корисник ће бити преусмерен на страницу за попуњавање тог обрасца.',
        },
        {
            selector: 'div[id="started"]',
            content:
                'У делу „Започети обрасци“ приказани су сви обрасци које је обвезник започео, али још није довршио. Обвезник може наставити унос података у било који од ових образаца. Када заврши са уносом, попуњен образац ће се аутоматски преместити у секцију „Поднети обрасци“, где ће бити јасно приказан на одговарајућем месту.',
        },
        {
            selector: 'div[id="completed"]',
            content:
                'У табели су приказани сви извештаји које је обвезник поднео. Корисник је у могућности да отвори и измени обрасце у извештају док му се статус извештаја не промени на "Закључан". Извештаји се закључавају сваког 15.01 и сматрају се да су поднети за претходну извештајну годину (15.01.2025 у 00:00 се закључавају извештаји за 2024 годину).',
        },
    ]

    const handleTourClose = () => {
        setIsTourOpen(false)
    }
    return (
        <div className="flex flex-col items-center">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div id="main" className="!w-full px-[100px] bg-[#ffffff] relative border border-borderTutorial p-10 !max-h-[80%]">
                <h2 id="title" className="text-[24px] font-light max-w-[1200px]">
                    Подношење извештаја за: <b>{user.subjectName}</b> за {''}
                    {new Date().getMonth() === 0 && new Date().getDate() <= 15 ? new Date().getFullYear() - 1 : new Date().getFullYear()} годину
                </h2>
                <div id="notStarted" className="pt-[10px]">
                    {remainingForms.length > 0 && (
                        <>
                            <p>Потребно је да попуните следеће обрасце:</p>
                            <div className="pb-[20px] pt-[20px] gap-[10px] flex flex-row">
                                {remainingForms.map((button, index) => (
                                    <ObrazacButton key={index} path={button.path} text={button.name} />
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <div id="started">
                    {!!startedForms.length && (
                        <>
                            <div className="flex flex-row items-center gap-4">
                                <h2 className="text-[24px] font-light max-w-[800px]">Започети обрасци</h2>
                            </div>
                            <div className="pt-[10px]">
                                <p>Потребно је да завршите унос следећих образаца:</p>

                                <div className="pb-[20px] pt-[20px] gap-[10px] flex flex-row">
                                    {startedForms.map((button, index) => (
                                        <ObrazacButton key={index} path={button.path} text={button.name} />
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="flex flex-row items-center gap-4">
                    <h2 className="text-[24px] font-light max-w-[800px]">Поднети обрасци</h2>
                </div>
                <div id="completed" className="!w-full min-h-full">
                    <Paper>
                        <TableContainer>
                            <Table className="overflow-x-auto w-full">
                                <TableRow>
                                    {headerMapping.map((item, index) => (
                                        <TableCell className="min-w-[150px]" key={index} style={{ minWidth: item.width ?? 150 }}>
                                            <div
                                                className={`!flex !flex-row justify-center items-center px-2 text-center ${
                                                    item.header.includes('бра') ? 'justify-center' : 'justify-between'
                                                } `}
                                            >
                                                {item.header}
                                            </div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableBody>
                                    {finishedForms.map((r) => (
                                        <TableRow key={r.id}>
                                            <TableCell height={52}>
                                                <p className="px-2 text-center">{r.report_year}</p>
                                            </TableCell>
                                            <TableCell height={52} width={400}>
                                                <p className="px-2">{r.submittedBy.subjectName}</p>
                                            </TableCell>
                                            <TableCell height={52} align="center">
                                                {STATUS[r.status as STATUS]}
                                            </TableCell>
                                            {r?.obrasci?.map((items: any, index: number) => {
                                                const item = !!items?.length ? items[0] : {}
                                                return (
                                                    <TableCell align="center" className={`!min-w-[150px] !max-w-[150px]`}>
                                                        {!!item.report_id ? (
                                                            <Button
                                                                style={{ width: '74px', backgroundColor: '#0D47A1' }}
                                                                className="cursor-default hover:cursor-default"
                                                                key={item.id}
                                                                variant="contained"
                                                                sx={{
                                                                    '&.Mui-disabled': {
                                                                        opacity: 1,
                                                                        backgroundColor: '#1976d2',
                                                                        color: 'white',
                                                                        pointerEvents: 'none',
                                                                    },
                                                                }}
                                                                disabled
                                                            >
                                                                Отвори
                                                            </Button>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
            <div className="w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} lastStep />
            </div>
        </div>
    )
}

export default Step3
