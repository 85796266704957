import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'

export const NumberOfSteps = 5

export interface Form7_18Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: string
    category: string
    organisation: Organization
    status: string
    submitted_by?: SubmittedBy

    o7_18_k1_budzet: boolean

    o7_18_k2_rodniBudzet?: boolean
    o7_18_k2_ukupanBudzet?: number
    o7_18_k2_deoBudzeta?: number
    o7_18_k2_vrstePrograma?: Program[]

    o7_18_k3_posedujeAkte: boolean
    o7_18_k3_akti: Act[]
    o7_18_k3_rodnoOsetljiviProgrami: number
    o7_18_k3_ukuonoPrograma: number
    o7_18_k3_opis: string //max2048

    o7_18_k4_nedostatakSaznanja: boolean
    o7_18_k4_nejasnaRegulativa: boolean
    o7_18_k4_losSoftverZaPlaniranje: boolean
    o7_18_k4_nedostatakObuke: boolean
    o7_18_k4_organizacionePrepreke: boolean
    o7_18_k4_nedovoljnoVremena: boolean
    o7_18_k4_nedovoljnoSredstvaUBudzetu: boolean
    o7_18_k4_svest: boolean
    o7_18_k4_opis: string //max200
}

interface Program {
    id?: string
    vrsta?: string
    naziv?: string
}

interface Act {
    id?: string
    cilj?: string
    indikator?: string
}
