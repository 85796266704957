import Tour from 'reactour'
import Actions from '../../Components/Actions'
import TutorialSelectComponent from '../../Components/TutorialSelect'
import { TutorialStepInterface } from './Step1'
import { Button, Input, MenuItem, Select } from '@mui/material'
import { useState } from 'react'

const Step4 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const fields: any[] = []

    const tourSteps = [
        {
            selector: 'div[id="selectClosed"]',
            content:
                'Када кликнете на падајући мени са натписом „ИЗАБЕРИТЕ“, отвориће се листа опција. Када се листа опција појави, пронађите и кликните на опцију коју желите да изаберете. Након клика на жељену опцију, падајући мени ће се затворити, а изабрана опција ће бити приказана у пољу.',
        },
        {
            selector: 'div[id="addNew"]',
            content: 'Када кликнете на дугме „ДОДАЈ СЛУЧАЈ“, додаје се нови ред у табели. Ово вам омогућава да унесете додатне информације или вредности које су вам потребне. ',
        },
    ]

    const handleTourClose = () => {
        setIsTourOpen(false)
    }
    return (
        <div className="flex h-full w-full flex-col items-center">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div className="border border-borderTutorial p-10">
                <div className="mt-[20px]">
                    <h1 className="step-name">
                        17. Број пријављених случајева насиља у органу јавне власти или код послодавца по основу пола, односно рода, разврстаних по облику насиља, полу жртве насиља
                        и полу извршиоца насиља, као и међусобном односу жртве и извршиоца насиља, у години вођења евиденције:
                    </h1>
                    <div>
                        <table className="simple-table mt-[30px]">
                            <tr className="simple-table-header">
                                <th className="simple-table-cell min-w-[300px]">Пријављени случајеви</th>
                                <th className="simple-table-cell !w-[186px]">Облик насиља</th>
                                <th className="simple-table-cell !w-[186px]">Пол жртве</th>
                                <th className="simple-table-cell !w-[186px]">Пол учиоца</th>
                                <th className="simple-table-cell !w-[186px]">Међусобни однос</th>
                            </tr>
                            {fields.map((row, index) => (
                                <tr className="simple-table-row">
                                    <td className="simple-table-cell disabled !w-[146px]">{row.occurrences_no}</td>
                                    <td className="simple-table-cell disabled !w-[146px]">{row.occurrences_no}%</td>
                                    <td className="simple-table-cell disabled !w-[186px]">{row.violence_form}</td>
                                    <td className="simple-table-cell disabled !w-[186px]">{row.victim_gender}</td>
                                    <td className="simple-table-cell disabled !w-[186px]">{row.perpetrator_gender}</td>
                                    <td className="simple-table-cell disabled !w-[186px]">{row.relation}</td>
                                    <td className="!border-none">
                                        <Button
                                            color="error"
                                            variant="contained"
                                            disabled
                                            sx={{
                                                backgroundColor: (theme) => theme.palette.error.main,
                                                color: (theme) => theme.palette.error.contrastText,
                                                '&.Mui-disabled': {
                                                    backgroundColor: (theme) => theme.palette.error.main,
                                                    color: (theme) => theme.palette.error.contrastText,
                                                },
                                            }}
                                        >
                                            Обриши
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>

                    <div className="mt-[50px] flex flex-row gap-[8px]">
                        <div className="w-[300px] flex flex-row items-center gap-[8px]">
                            <div className={`border border-[#00000010] w-[140px] h-[50px] flex items-center justify-center`}>
                                <Input
                                    value={'2'}
                                    disabled
                                    className="!bg-[transparent] border-none"
                                    placeholder="Цео број"
                                    sx={{
                                        color: 'black',
                                        textAlign: 'center',
                                        '& .MuiInputBase-input': {
                                            textAlign: 'center',
                                            color: 'black',
                                            WebkitTextFillColor: 'black',
                                            opacity: 1,
                                        },
                                        '&.MuiInputBase-root:before': {
                                            borderBottom: 'none',
                                        },
                                        '&.MuiInputBase-root:after': {
                                            borderBottom: 'none',
                                        },
                                        '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                            borderBottom: 'none',
                                        },
                                        '&.Mui-disabled': {
                                            color: 'black',
                                        },
                                    }}
                                />
                            </div>
                            <div className={`border border-[#00000010] w-[140px] h-[50px] flex items-center justify-center`}>2%</div>
                        </div>

                        <div id="selectClosed">
                            <TutorialSelectComponent
                                open={false}
                                disabled={true}
                                value={undefined}
                                elements={['Психичко насиље', 'Физичко насиље', 'Сексуално узнемиравање', 'Друго']}
                            />
                        </div>
                        <TutorialSelectComponent open={false} disabled={true} value={'Жена'} elements={['Мушкарац', 'Жена']} />
                        <TutorialSelectComponent open={false} disabled={true} value={'Мушкарац'} elements={['Мушкарац', 'Жена']} />
                        <TutorialSelectComponent open={false} disabled={true} value={'Надређени'} elements={['Надређени', 'Подређени', 'Колеге', 'Друго']} />
                    </div>
                    <div className="h-[26px]"></div>
                    <div id="addNew" className="mt-[50px] max-w-[150px] flex items-center">
                        <Button
                            variant="contained"
                            sx={{
                                cursor: 'default',
                                opacity: 1,
                                '&.Mui-disabled': {
                                    backgroundColor: '#1976d2',
                                    color: 'white',
                                },
                            }}
                            disabled={true}
                        >
                            Додај случај
                        </Button>
                        <div className="h-[26px]"></div>
                    </div>
                </div>
            </div>
            <div className="mt-10 w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step4
