import React, { useState } from 'react'
import { TutorialStepInterface } from './Step1'
import validationImage from '../../../../assets/images/LoginTutorialImages/EmailValidation.png'
import Actions from '../../Components/Actions'
import { Button } from '@mui/material'
import Tour from 'reactour'

const Step4 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const tourSteps = [
        {
            selector: 'div[id="main"]',
            content: 'Након што корисник кликне на дугме „НАСТАВИ СА РЕГИСТРАЦИЈОМ” на екрану за регистрацију, приказује се обавештење о верификацији имејл адресе.',
        },
        {
            selector: 'p[id="exp"]',
            content:
                'Систем обавештава корисника да је на унету имејл адресу нпр.(korisnik@gmail.com) послат имејл који садржи линк за верификацију имејл адресе. Корисник треба да оде на своју имејл адресу и кликне на линк у послатом имејл-у како би наставио процес пријаве и коришћења система. Кориснику се препоручује да провери и spam фолдер уколико не може пронаћи имејл.',
        },
        {
            selector: 'p[id="tut"]',
            content: 'Систем обавештава корисника како да завриши верификацију своје имејл адресе да би могао да настави са даљом регистрацијом.',
        },
        // { selector: 'p[id="resend"]', content: 'Кориснику се препоручује да провери и spam фолдер уколико не може пронаћи e-mail.' },
        {
            selector: 'Button[id="resendButton"]',
            content:
                'У случају да e-mail није стигао, корисник може кликнути на дугме „САЧЕКАЈТЕ [преостало време] СЕК” након што истекне тајмер, како би поново затражио слање e-mail-а.',
        },
    ]
    const [isTourOpen, setIsTourOpen] = useState(true)

    const handleTourClose = () => {
        setIsTourOpen(false)
    }
    return (
        <div className="w-full h-full flex items-center flex-col">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div className="h-full w-full flex items-center justify-center flex-col gap-[10px] border border-borderTutorial p-10">
                <p id="exp" className="w-[700px] text-center">
                    Поштовани корисниче, на имејл адресу коју сте унели (korisnik@gmail.com) Вам је послат имејл који у себи садржи линк за верификацију имејл адресе. Молимо Вас да
                    проверите и спам фолдер уколико не можете да пронађете овај имејл.
                </p>
                <p id="tut" className="w-[700px] text-center">
                    Потребно је да одете на пријављену имејл адресу и да кликнете на линк у имејл-у за верификацију. Након верификације бићете преусмерени на страницу за пријаву.
                </p>
                <p id="resend" className="w-[700px] text-center">
                    Уколико вам имејл није стигао на адресу (korisnik@gmail.com) кликните на дугме пошаљи опет и сачекајте неколико минута.
                </p>
                <div className="flex flex-row max-w-[700px] gap-[50px] mt-[20px]">
                    <Button
                        id="resendButton"
                        disabled
                        className="w-full h-[52px] !min-w-[150px] flex items-center justify-center rounded-[4px] !bg-[#243964] text-[15px] !text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[520px]"
                    >
                        Сачекајте 176 сек
                    </Button>
                </div>
            </div>
            <div className="w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step4
