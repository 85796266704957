import React, { useCallback, useState } from 'react'
import CategorySelectionStep1 from './CategorySelectionStep1'
import CategorySelectionStep2 from './CategorySelectionStep2'
import CategorySelectionScreen3 from './CategorySelectionScreen3'
import { assignUserCategory, whoAmI } from '../../../server/server'
import { useUser } from '../../../auth/UseUser'

export const SpecialCasesMap: Record<'region' | 'rest' | 'associations' | 'syndicate' | 'parties', string> = {
    region: 'Регионални органи',
    rest: 'Остали',
    associations: 'Удружења',
    syndicate: 'Синдикалне организације',
    parties: 'Политичке странке',
}

interface Category {
    name: string
    id: string
}

const CategorySelectionScreen = () => {
    const [step, setStep] = useState(0)
    const [selected, setSelected] = useState<Category>()
    const [subSelected, setSubSelected] = useState<Category>()
    const [specialCase, setSpecialCase] = useState<string | null>(null)
    const { user, setUser } = useUser()

    const nextStep = () => {
        if (specialCase === 'syndicate' || specialCase === 'parties' || specialCase === 'associations' || specialCase === 'rest') {
            setStep(2)
        } else {
            setStep(step + 1)
        }
    }
    const prevStep = () => {
        if (specialCase === 'syndicate' || specialCase === 'parties' || specialCase === 'associations' || specialCase === 'rest') {
            setStep(0)
            setSelected(undefined)
        } else if (step === 2) {
            setStep(1)
            setSubSelected(undefined)
        } else if (step === 1) {
            setSelected(undefined)
            setSubSelected(undefined)
            setStep(0)
        }
    }
    const finish = useCallback(async () => {
        try {
            const data = {
                categoryId: selected?.id,
                subCategoryId: subSelected?.id,
            }
            const resp = await assignUserCategory(data)
            if (resp.status !== 201) {
                return 'error'
            }

            setUser((prevUser: any) => ({
                ...prevUser,
                category: selected,
                subCategory: subSelected,
            }))
        } catch (error) {
            return 'error'
        }
    }, [selected, subSelected])

    const checkSpecialCase = (categoryName: string | undefined) => {
        const specialCaseKey = Object.keys(SpecialCasesMap).find((key) => SpecialCasesMap[key as keyof typeof SpecialCasesMap] === categoryName)

        if (specialCaseKey) {
            setSpecialCase(specialCaseKey)
        } else {
            setSpecialCase(null)
        }
    }

    const handleCategorySelection = (category: Category) => {
        setSelected(category)
        checkSpecialCase(category?.name)
    }
    return (
        <div className="my-[40px]">
            {step === 0 && <CategorySelectionStep1 setSelected={handleCategorySelection} nextStep={nextStep} />}
            {step === 1 && <CategorySelectionStep2 nextStep={nextStep} selected={selected} prevStep={prevStep} setSubSelected={setSubSelected} specialCase={specialCase} />}
            {step === 2 && <CategorySelectionScreen3 finish={finish} subSelected={subSelected} prevStep={prevStep} selected={selected} specialCase={specialCase} />}
        </div>
    )
}

export default CategorySelectionScreen
