import React from 'react'
import { RowInterface, StepInterface } from './Step1'
import SimpleSubtractionTable from '../../../Components/SimpleSubtractionTable'
import ActionButtons from '../../../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import { Form7_4Entity } from '../../../../../models/Form7/Entities/Form7_4.entity'

const rows: RowInterface[] = [
    { label: 'Информационе и комуникационе технологије (ИКТ)', fields: ['o7_4_k7_z1', 'o7_4_k7_m1'] },
    { label: 'Биохемијске технологије', fields: ['o7_4_k7_z2', 'o7_4_k7_m2'] },
    { label: 'Производња микрочипова', fields: ['o7_4_k7_z3', 'o7_4_k7_m3'] },
    { label: 'Роботика', fields: ['o7_4_k7_z4', 'o7_4_k7_m4'] },
    { label: 'Вештачка интелигенција', fields: ['o7_4_k7_z5', 'o7_4_k7_m5'] },
    { label: 'Електронска трговина и логистика', fields: ['o7_4_k7_z6', 'o7_4_k7_m6'] },
    { label: 'Енергетика и обновљиви извори енергије', fields: ['o7_4_k7_z7', 'o7_4_k7_m7'] },
]

const allFields = [
    'o7_4_k7_z1',
    'o7_4_k7_z2',
    'o7_4_k7_z3',
    'o7_4_k7_z4',
    'o7_4_k7_z5',
    'o7_4_k7_z6',
    'o7_4_k7_z7',
    'o7_4_k7_m1',
    'o7_4_k7_m2',
    'o7_4_k7_m3',
    'o7_4_k7_m4',
    'o7_4_k7_m5',
    'o7_4_k7_m6',
    'o7_4_k7_m7',
] as const

const allMaleFields = [...allFields.filter((e) => e.includes('_m'))] as const
const allFemaleFields = [...allFields.filter((e) => e.includes('_z'))] as const

type AllFieldsType = (typeof allFields)[number]

const Step7 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_4Entity>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const disabledNext = !fields.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <h1 className="step-name">Удео предузећа у власништву жена у високорастућим секторима.</h1>
            <SimpleSubtractionTable
                rows={rows}
                control={control}
                allFemaleFields={[...allFemaleFields]}
                allMaleFields={[...allMaleFields]}
                allFields={[...allFields]}
                header={['Сектор', 'Профитабилност Жене %', 'Профитабилност Мушкарци %', 'Разлика М-Ж']}
                errors={errors}
                disabled={adminView}
                decimal
                form7={true}
                clearErrors={clearErrors}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step7
