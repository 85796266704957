import React from 'react'
import { Form7_17Entity } from '../../../../../models/Form7/Entities/Form7_17.entity'
import { Control, useWatch } from 'react-hook-form'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import RadioButtonsControl from '../../../../../components/Common/RadioButtonsControlWithError'
import Textbox from '../../../../../components/Common/Textbox'
import ActionButtons from '../../../../../components/ActionButtons'

export interface StepInterface {
    control?: Control<Form7_17Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export const Step1 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_17Entity>({ control })

    const fields: ('o7_17_k1_ocena' | 'o7_17_k1_opis')[] = ['o7_17_k1_ocena', 'o7_17_k1_opis']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name mb-[10px]">
                Како оцењујете степен унапређења родне статистике у свим областима које прати РСЗ у односу на план за унапређење родне статистике
            </h1>
            <div className="flex flex-row gap-2 ">
                <RadioButtonsControl
                    control={control}
                    name={'o7_17_k1_ocena'}
                    options={['Одличан', 'Добар', 'Довољан', 'Недовољан', 'Лош']}
                    errors={errors}
                    enumerated
                    disabled={adminView}
                />
            </div>
            <div className="mt-[50px] ">
                <h4 className="mb-[10px]">Молимо Вас да кратко опишете зашто сте дали овакву оцену?</h4>
                <div className="flex flex-col">
                    <Textbox
                        name="o7_17_k1_opis"
                        control={control}
                        placeholder="Текст (400 карактера)*"
                        errors={errors}
                        isRequired={adminView ? false : 'Попуните обавезно поље.'}
                        maxNumberOfCharacters={400}
                        disabled={adminView}
                    />
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
