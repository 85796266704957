import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Stepper from '../../components/Common/Stepper/Stepper'
import { NumberOfSteps, TblOblast5 } from '../../models/Form5/Form5.entity'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'

import Step7 from './Steps/Step7'
import Step8 from './Steps/Step8'
import { getForm5, getUnfinishedForm5, saveForm5 } from '../../server/server'
import { FORM5_PARTY_NAMES, FORM5_UNION_NAMES, HELP_BUTTON_5 } from '../../models/Form5/Form5Names'
import { FORM5_TITLE_STRANKE, FORM5_TITLE_SINDIKATI } from '../../models/FormTitles'
import Step5 from './Steps/Step5'
import FinishOfFormModal from '../../components/Common/FinishOfFormModal'
import LoadingSpinner from '../../components/Common/LoadingSpinner'
import { isNotEmpty } from '../../utils/Validation'
import { useUser } from '../../auth/UseUser'
import TutorialModal from '../Tutorial/TutorialModal'
import HelpButton from '../../components/Common/HelpButton'

function Form5Screen() {
    let navigate = useNavigate()
    const { user } = useUser()
    const adminView = user.admin

    const [finishModalVisible, setFinishModalVisible] = useState(false)
    let { category } = useParams()
    const [formCompleted, setFormCompleted] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    let [searchParams, setSearchParams] = useSearchParams()
    const [tutorialModalOpen, setTutorialModalOpen] = useState(!adminView)
    const [loading, setLoading] = useState(false)
    const adminUsage = searchParams.get('adminUsage') === '1'
    const {
        handleSubmit,
        control,
        getValues,
        reset,
        setValue,
        unregister,
        trigger,
        formState: { errors },
    } = useForm<TblOblast5>()

    const formId = searchParams.get('formId')

    useEffect(() => {
        removeAllFields()
        setActiveStep(0)
        const formValues = getValues()
        const isNewForm = isNotEmpty(formValues.o5_k1_r1)
        if (!isNewForm) {
            getUnfinishedForm()
        }
    }, [category])

    const getUnfinishedForm = async () => {
        setLoading(true)
        let resp
        if (!adminView) {
            resp = await getUnfinishedForm5(category)
        } else {
            resp = await getForm5(formId)
        }
        const form = resp.data.form

        if (resp.status === 200 && resp.data) {
            Object.keys(form).forEach((key) => {
                setValue(key as keyof TblOblast5, form[key])

                if (key === 'report') {
                    setValue('submitted_by.firstName', form[key].submittedBy.firstName)
                    setValue('submitted_by.lastName', form[key].submittedBy.lastName)
                    setValue('submitted_by.phoneNumber', form[key].submittedBy.phoneNumber)
                    setValue('submitted_by.email', form[key].submittedBy.email)
                }
            })
            setFormCompleted(resp.data.form.status !== 0)
            setActiveStep(Number(resp.data.currentStep))
        }
        setLoading(false)
    }

    const onSelectStep = (step: number) => setActiveStep(step)
    const nextStep = async (skip?: boolean) => {
        const form = getValues()
        if (!adminView) {
            await save()
            if (activeStep === 0 && form.status === undefined) getUnfinishedForm()
        }
        if (NumberOfSteps > activeStep + 1) {
            setActiveStep(skip ? activeStep + 2 : activeStep + 1)
        }
    }
    const previousStep = () => setActiveStep(activeStep - 1)

    const onSubmit = async () => {
        // console.log('on submit get values ', getValues())
    }

    const save = async () => {
        const resp = await saveForm5(getValues(), activeStep + 1, category)
        console.log('🚀 ~ save ~ resp:', resp)
        if (resp.status == 201) {
            setValue('report_id', resp.data.report_id)
        } else {
            alert(`Грешка при чувању података: ${resp.status}`)
        }
    }

    const removeAllFields = () => {
        const fields: any = Object.keys(getValues())
        fields.forEach((field: any) => unregister(field))
    }

    const finish = async () => {
        if (!adminView) {
            await save()
        }
        setFinishModalVisible(true)
    }

    const resetValuesAndGoToFirstStep = () => {
        setFinishModalVisible(false)
        setActiveStep(0)
        reset()
        navigate('/')
    }

    const isParty = useMemo(() => category === 'stranka', [category])

    const stepComponent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Step1
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        previousButtonDisabled={activeStep === 0}
                        isParty={isParty}
                        errors={errors}
                        adminView={adminView}
                    />
                )
            case 1:
                return <Step2 control={control} nextStep={nextStep} previousStep={previousStep} isParty={isParty} errors={errors} adminView={adminView} />
            case 2:
                return (
                    <Step3
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        isParty={isParty}
                        errors={errors}
                        trigger={trigger}
                        adminView={adminView}
                    />
                )
            case 3:
                return <Step4 control={control} nextStep={nextStep} previousStep={previousStep} setValue={setValue} isParty={isParty} errors={errors} adminView={adminView} />
            case 4:
                return <Step5 control={control} nextStep={nextStep} previousStep={previousStep} setValue={setValue} isParty={isParty} errors={errors} adminView={adminView} />
            case 5:
                return <Step7 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 6:
                return <Step8 control={control} nextStep={finish} previousStep={previousStep} errors={errors} adminView={adminView} setValue={setValue} />

            default:
                return null
        }
    }

    return (
        <div className="max-w-[1556px] mx-[auto] px-[40px] pb-[20px]">
            <div className="pt-[40px] pb-[55px] flex flex-row gap-5 items-start justify-between">
                <h1 className="text-[24px] font-light">{category === 'stranka' ? FORM5_TITLE_STRANKE : FORM5_TITLE_SINDIKATI}</h1>
                <HelpButton name={HELP_BUTTON_5[activeStep]} marginTop={5} />
            </div>
            <div>
                <Stepper
                    numberOfSteps={NumberOfSteps}
                    onSelectStep={onSelectStep}
                    activeStep={activeStep}
                    formName={category === 'stranka' ? FORM5_PARTY_NAMES : FORM5_UNION_NAMES}
                    unlockEveryStep={!!adminUsage || adminView || formCompleted}
                />
            </div>
            {!loading ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-[55px]">{stepComponent()}</div>
                </form>
            ) : (
                <div className="flex justify-center items-center h-full mt-[100px]">
                    <LoadingSpinner />
                </div>
            )}
            <TutorialModal open={tutorialModalOpen} onClose={() => setTutorialModalOpen(false)} segment="form" />
            <FinishOfFormModal
                modalIsOpen={finishModalVisible}
                closeModal={resetValuesAndGoToFirstStep}
                infoText={category === 'stranka' ? FORM5_TITLE_STRANKE : FORM5_TITLE_SINDIKATI}
                adminView={adminView}
            />
        </div>
    )
}

export default Form5Screen
