import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useUser } from '../../auth/UseUser'
import { ReactComponent as ShowPasswordIcon } from '../../assets/svg/EyeFilled.svg'
import { ReactComponent as HidePasswordIcon } from '../../assets/svg/EyeHidden.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getLegalEntities, login, resendValidationEmail, signInLegalEntity, whoAmI } from '../../server/server'

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { baseUrl } from '../../server/connections'
import HelpButton from '../../components/Common/HelpButton'
import Loading from '../../components/Common/LoadingSpinner'
import { convertToCyrillic, convertToLatin } from '../../utils/searchTranslation'

type Inputs = {
    email: string
    password: string
}
const SelectLegalEntityScreen = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const [legalEntities, setLegalEntities] = useState([])
    const [loading, setLoading] = useState(true)
    const { setUser, user } = useUser()

    useEffect(() => {
        setUserName()
        loadData()
    }, [])

    const setUserName = () => {
        setUser({ ...user, legalEntityId: null, subjectName: null })
    }

    const loadData = async () => {
        const resp = await getLegalEntities()
        if (resp?.status === 200) {
            setLegalEntities(resp.data)
            setLoading(false)
        }
    }

    const openLegalEntity = async (id: string, index: number) => {
        setLegalEntities((prev: any) => {
            return prev.map((item: any, i: number) => {
                if (i === index) {
                    return { ...item, selected: true }
                }
                return { ...item, selected: false }
            })
        })
        const resp = await signInLegalEntity(id)
        if (resp.status == 201) {
            const data = resp.data
            const oldToken = localStorage.getItem('accessToken') ?? ''
            localStorage.setItem('oldToken', oldToken)
            localStorage.setItem('accessToken', data['accessToken'])
            const response = await whoAmI()
            if (response.status === 200) {
                const user = response.data
                console.log('user:', user)
                setUser(user)
                if (!user?.hasChosenCategory && !user?.admin) {
                    navigate('/user/category-selection')
                } else {
                    navigate('/')
                }
            }
            setLegalEntities((prev: any) => prev.map((item: any, i: number) => ({ ...item, selected: false })))
        }
    }
    const filterEntity = (entity: any) => {
        const searchCyrillic = convertToCyrillic(search)
        const searchLatin = convertToLatin(search)

        const subjectNameLower = entity.subjectName.toLowerCase()

        const pibMatches = `${entity?.pib}`?.includes(search)

        const subjectNameMatches =
            subjectNameLower.includes(search.toLowerCase()) || subjectNameLower.includes(searchCyrillic.toLowerCase()) || subjectNameLower.includes(searchLatin.toLowerCase())

        return pibMatches || subjectNameMatches
    }

    return (
        <div className="flex justify-center h-full relative">
            <div className="absolute top-5 right-10 ">
                <HelpButton name="user_login_help" />
            </div>
            <div className="px-[99px] py-[45px] bg-[#ffffff] items-center flex flex-col w-full max-w-[1600px]">
                <h2 className="mb-[32px] text-[24px] leading-[28px] font-light">Изабери обвезника закона о родној равноправности</h2>

                <div className="flex flex-col items-center w-full">
                    <div className="w-full flex flex-row gap-[10px]">
                        <input
                            className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px] flex-1"
                            placeholder="Претрага по ПИБ-у или називу обвезника"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    {!loading && (
                        <div className="!w-full py-10 min-h-full">
                            {!!legalEntities.length && (
                                <Paper>
                                    <TableContainer>
                                        <Table className="overflow-x-auto w-full">
                                            <TableHead>
                                                <TableRow className="flex flex-row !font-bold text-[1.5rem]">
                                                    <TableCell className="min-w-[90px] " style={{ minWidth: 90 }}>
                                                        <div className=" !flex !flex-row justify-between items-center px-2"></div>
                                                    </TableCell>
                                                    <TableCell className="min-w-[250px]  !max-w-[250px]" style={{ minWidth: 250, maxWidth: '250px !important' }}>
                                                        <div className=" !flex !flex-row justify-between items-center px-2">
                                                            <p>ПИБ</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="min-w-[300px] flex-1" style={{ minWidth: 300 }}>
                                                        <div className=" !flex !flex-row justify-between items-center px-2">Назив обвезника</div>
                                                    </TableCell>
                                                    <TableCell className="min-w-[300px] flex-1" style={{ minWidth: 300 }}>
                                                        <div className=" !flex !flex-row justify-between items-center px-2">Назив овлашћеног лица</div>
                                                    </TableCell>

                                                    <TableCell className="min-w-[150px] text-center" style={{ minWidth: 150, textAlign: 'right' }}>
                                                        <Link to="/register/step2">
                                                            <Button variant="contained">Додај новог обвезника</Button>
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {legalEntities?.filter(filterEntity)?.map((legalEntity: any, index) => (
                                                    <TableRow key={legalEntity.id}>
                                                        <TableCell height={52}>
                                                            <p className="text-center">{index + 1}.</p>
                                                        </TableCell>
                                                        <TableCell height={52}>
                                                            <div className=" px-2">{legalEntity?.pib ?? '-'}</div>
                                                        </TableCell>
                                                        <TableCell height={52}>
                                                            <p className="px-2">{legalEntity?.subjectName}</p>
                                                        </TableCell>
                                                        <TableCell height={52}>
                                                            <p className="px-2">
                                                                {legalEntity?.firstName} {legalEntity?.lastName}
                                                            </p>
                                                        </TableCell>

                                                        <TableCell height={52} className="flex justify-center items-center text-right" style={{ textAlign: 'right' }}>
                                                            <Button
                                                                variant="contained"
                                                                className="self-center"
                                                                onClick={() => openLegalEntity(legalEntity.id, index)}
                                                                disabled={legalEntity.selected}
                                                            >
                                                                {legalEntity.selected ? <Loading height={20} /> : 'Изабери'}
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            )}
                            {!legalEntities.length && (
                                <div className="h-full flex flex-col justify-center items-center gap-[20px]">
                                    <p>Потребно je да додате новог обвезника закона о родној равноправности</p>
                                    <Link to="/register/step2">
                                        <Button variant="contained">Додај новог обвезника</Button>
                                    </Link>
                                </div>
                            )}
                        </div>
                    )}
                    {loading && (
                        <div className="h-full mt-[18%]">
                            <Loading height={50} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SelectLegalEntityScreen
