import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'

export const NumberOfSteps = 3

export interface Form7_12Entity {
    report_id?: string
    rep_year?: number
    submitted_date?: Date
    submitted_place?: Date
    category?: string
    organisation?: Organization
    status?: string

    // Step 1
    o7_12_k1_tekuca?: number
    o7_12_k1_prethodna?: number

    //Step 2
    o7_12_k2_z_tg_1?: number
    o7_12_k2_z_tg?: number

    o7_12_k2_m_tg_1?: number
    o7_12_k2_m_tg?: number

    submitted_by?: SubmittedBy
}
