import React from 'react'
import { Control } from 'react-hook-form'
import NoAnalytics from '../../Common/NoAnalytics'
import ActionButtons from '../../../../components/ActionButtons'
import { TblOblast6 } from '../../../../models/Form6/Form6.entity'
import { StepInterface } from './Step1'

const Step2 = ({
    control,
    nextStep,
    previousStep,
    previousButtonDisabled = false,
    errors,
    adminView,
    countType,
    setValue,
    selectedCategories,
    selectedSubCategories,
}: StepInterface) => {
    return (
        <div>
            <h1 className="step-name">Резултати процене ризика који угрожавају примену принципа родне равноправности</h1>
            <NoAnalytics />
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
