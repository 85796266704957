import { TblOblast4 } from '../../../models/Form4/Form4.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import SimpleInputRow from '../../../components/Common/SimpleInputRow'
import { StepInterface } from './Step1'

function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast4>({ control })
    const fields: ('o4_k2_br1' | 'o4_k2_br2' | 'o4_k2_br3' | 'o4_k2_br4')[] = ['o4_k2_br1', 'o4_k2_br2', 'o4_k2_br3', 'o4_k2_br4']
    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[10px]">'Број, врсте и сажети опис посебних мера иницираних ради остваривања и унапређивања родне равноправности:'</h1>
            <div className="mt-[20px] mb-[40px] flex flex-col gap-[8px] ml-[5px]">
                <SimpleInputRow title="Укупан број посебних мера" name="o4_k2_br1" type="tel" control={control} errors={errors} isRequired={!adminView} disabled={adminView} />
                <SimpleInputRow
                    title="Број мера које се одређују и спроводе у случајевима осетно неуравнотежене заступљености полова"
                    name="o4_k2_br2"
                    type="tel"
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                />
                <SimpleInputRow
                    title="Број подстицајних мера, којима се дају посебне погодности или уводе посебни подстицаји у циљу унапређења положаја и обезбеђивања једнаких могућности за жене и мушкарце у свим областима друштвеног живота"
                    name="o4_k2_br3"
                    type="tel"
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                />
                <SimpleInputRow
                    title="Број програмских мера, којима се операционализују програми за остваривање и унапређење родне равноправности"
                    name="o4_k2_br4"
                    type="tel"
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
