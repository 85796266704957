import { TblOblast3 } from '../../../models/Form3/Form3.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import { YESNOOTHER_ENUM } from '../../../models/CommonTypes'
import UploadFile from '../../../components/UploadFile'
import HiddenDatePicker from '../../../components/Common/DatePicker'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControlWithError'
import SimpleInputRowForObject from '../../Form2/Components/SimpleImputRowForObject'
import Textbox from '../../../components/Common/Textbox'

export interface StepInterface {
    control?: Control<TblOblast3>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

const fileTypes = ['PDF']
const radioOptions = ['Да', 'Не', 'План за остваривање родне равноправности је посебно донет']

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast3>({ control })

    const disabledFields: ('o3_k1_br_glasnika' | 'o3_k1_d_izlaska_glasnika')[] = ['o3_k1_br_glasnika', 'o3_k1_d_izlaska_glasnika']

    const hasPlan = watchedValues?.o3_k1_ima_plan === YESNOOTHER_ENUM.Yes || watchedValues.o3_k1_ima_plan === YESNOOTHER_ENUM.Other

    const disabledNext = hasPlan
        ? !(
              watchedValues?.o3_k1_d_donosenja !== null &&
              watchedValues?.o3_k1_d_donosenja !== undefined &&
              watchedValues?.o3_k1_d_objavljivanja !== null &&
              watchedValues?.o3_k1_d_objavljivanja !== undefined
          )
        : !(watchedValues?.o3_k1_ima_plan !== undefined && watchedValues?.o3_k1_ima_plan !== null)

    const disabledNextWithDisabledFields = hasPlan
        ? false
        : !disabledFields.filter((e) => e !== 'o3_k1_br_glasnika' && e !== 'o3_k1_d_izlaska_glasnika').every((e) => !!watchedValues[e])

    const goToNextStep = () => {
        if (adminView) {
            nextStep()
        } else if (hasPlan) {
            if (!disabledNext && !disabledNextWithDisabledFields) {
                nextStep()
            }
        } else {
            if (!disabledNext) {
                nextStep()
            }
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    const checkDisabled = () => {
        return !hasPlan || adminView
    }

    return (
        <div onKeyDown={handleKeyDown}>
            <h1 className="step-name">
                Да ли орган јавне власти и послодавац има донет план или програм за остваривање родне равноправности у оквиру годишњих планова и програма рада?
            </h1>
            <div className="mt-[20px] ">
                <RadioButtonsControl control={control} name={'o3_k1_ima_plan'} options={radioOptions} errors={errors} enumerated disabled={adminView} />
            </div>
            <h1 className="mt-[20px]">Уколико је одговор Да или је план посебно донет молимо Вас да попуните следећа поља</h1>
            <div className="mt-[20px] mb-[40px] flex flex-col gap-[15px] ml-[5px]">
                <div className="flex flex-row gap-[10px] h-[50px]">
                    <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                        <p className="text-center mx-auto">Датум доношења</p>
                    </div>
                    <HiddenDatePicker
                        name={`o3_k1_d_donosenja`}
                        value={watchedValues?.o3_k1_d_donosenja}
                        control={control}
                        errors={errors}
                        lastStep
                        isRequired={!checkDisabled()}
                        disabled={checkDisabled()}
                        tabIndex={hasPlan ? 0 : -1}
                    />
                </div>
                <div className="flex flex-row gap-[10px] h-[50px]">
                    <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                        <p className="text-center mx-auto">Датум јавног објављивања</p>
                    </div>
                    <HiddenDatePicker
                        name={`o3_k1_d_objavljivanja`}
                        value={watchedValues?.o3_k1_d_objavljivanja}
                        errors={errors}
                        control={control}
                        lastStep
                        isRequired={!checkDisabled()}
                        disabled={checkDisabled()}
                        tabIndex={hasPlan ? 0 : -1}
                    />
                </div>
                <div className="h-[5px]">
                    {hasPlan && (errors?.o3_k1_d_donosenja || errors?.o3_k1_d_objavljivanja) && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">Попуните обавезно поље</p>}
                </div>
                <div className="mt-[20px]">
                    <h4 className="mb-[10px]">Линк на којем се може преузети:</h4>
                    <Textbox name="o3_k1_link" control={control} disabled={checkDisabled()} placeholder="Текст (2048 карактера)" maxNumberOfCharacters={2048} />
                </div>
                <SimpleInputRowForObject
                    title="Назив и број службеног гласила"
                    name="o3_k1_br_glasnika"
                    maxNumberOfCharacter={150}
                    control={control}
                    disabled={checkDisabled()}
                    errors={errors}
                    isRequired={false}
                />
                <div className="flex flex-row gap-[10px] h-[50px]">
                    <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                        <p className="text-center mx-auto">Датум објављивања службеног гласила</p>
                    </div>
                    <HiddenDatePicker
                        tabIndex={hasPlan ? 0 : -1}
                        name={`o3_k1_d_izlaska_glasnika`}
                        value={watchedValues?.o3_k1_d_izlaska_glasnika}
                        control={control}
                        errors={errors}
                        isRequired={false}
                        lastStep
                        disabled={checkDisabled()}
                    />
                </div>
            </div>

            <h1>Уколико план за остваривање родне равноправности није јавно објављен молим Вас приложите план у ПДФ формату до 8МБ</h1>

            <UploadFile control={control} name="o3_k1_plan_file" fileTypes={fileTypes} maxSize={8} disabled={checkDisabled()} />

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
