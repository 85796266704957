import React from 'react'
import grbImage from '../../../../assets/images/ministarstvoGrb.png'
import logo from '../../../../assets/images/ministarstvoLogo.png'
import gizLogo from '../../../../assets/images/gizLogo.png'
import Actions from '../../Components/Actions'

export interface TutorialStepInterface {
    nextStep: () => void
    previousStep: () => void
    nextButtonDisabled?: boolean
    previousButtonDisabled?: boolean
    lastStep?: boolean
    skipClick: () => void
    onClose: () => void
}

const Step1 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    return (
        <div>
            <div className="flex items-center justify-center h-full w-full flex-col">
                <div className="flex flex-row items-center max-w-[400px] ">
                    <img src={grbImage} alt="Грб са називом министарства" width={400} />
                </div>
                <div className="mt-2 gap-10 flex flex-col items-center">
                    <span className="font-bold max-w-[80%] text-center">Упутство за коришћење ИТ система за извештавање и праћење примене Закона о родној равноправности</span>
                    <span className="max-w-[75%] flex flex-col gap-5">
                        <p>Драги корисници,</p>
                        <p>Како бисмо вам олакшали пријаву и регистрацију, припремили смо вам овај водич. Молимо Вас да га прегледате, сматрамо да ће Вам бити корисно.</p>
                        <p>Желимо вам успешно попуњавање извештаја.</p>
                    </span>
                </div>
                <div className="flex flex-row mt-10 border border-borderTutorial items-center p-2 divide-x-2 divide-borderTutorial">
                    <div className="h-full">
                        <img src={grbImage} alt="Грб са називом министарства" width={250} />
                    </div>

                    <div className="h-full">
                        <img src={logo} alt="Грб са називом министарства" width={160} />
                    </div>
                    <div className="h-full p-3">
                        <img src={gizLogo} alt="Грб са називом министарства" width={310} />
                    </div>
                </div>
                <div className="w-full max-w-[70%]">
                    <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={true} skipButton={skipClick} closeModal={onClose} />
                </div>
            </div>
        </div>
    )
}

export default Step1
