import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getLegalEntity, getUser } from '../../server/server'
import { useForm } from 'react-hook-form'
import { EditCategoryModal } from './Components/EditCategoryModal'
import { TextareaAutosize } from '@mui/material'
import HelpButton from '../../components/Common/HelpButton'

interface CategoryInterface {
    id: string
    name: string
}

type UserDetails = {
    pib: number
    mb: number
    subjectName: string
    email: string
    admin: boolean
    firstName: string
    lastName: string
    address: string
    phoneNumber: string
    city: string
    category: CategoryInterface | undefined
    subCategory: CategoryInterface | undefined
}

const LegalEntityDetails = () => {
    const {
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors },
        setValue,
    } = useForm<UserDetails>()
    let [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [userData, setUserData] = useState<UserDetails | null>(null)

    const [formsDetails, setFormsDetails] = useState<any>({})

    const fields: { label: string; field: keyof UserDetails }[] = [
        { label: 'ПИБ', field: 'pib' },
        { label: 'Матични број', field: 'mb' },
        { label: 'Назив обвезника', field: 'subjectName' },
        { label: 'Име овлашћеног лица', field: 'firstName' },
        { label: 'Презиме овлашћеног лица', field: 'lastName' },
        { label: 'Адреса', field: 'address' },
        { label: 'Број телефона', field: 'phoneNumber' },
        { label: 'Седиште', field: 'city' },
        { label: 'Категорија', field: 'category' },
        { label: 'Подкатегорија', field: 'subCategory' },
    ]

    const userId = searchParams.get('legalEntityId')

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        if (userId) loadUser()
    }, [userId])

    const loadUser = async () => {
        const resp = await getLegalEntity(userId)
        const data = resp?.data
        if (resp.status === 200 && data) {
            console.log(data)
            setFormsDetails(data?.submittedForms ?? {})
            setUserData(data)
            Object.keys(data).forEach((key) => {
                setValue(key as keyof UserDetails, data[key])
            })
        }
    }

    return (
        <div className="flex items-center mt-[20px] w-full h-full flex-col">
            <h2 className="mb-[32px] text-[24px] leading-[28px] text-center font-light max-w-[200px]">Детаљи</h2>
            <form className="flex flex-col items-center w-full max-w-[520px]" autoComplete="off">
                <div className="flex flex-col w-full">
                    {userData?.admin ? (
                        <>
                            <div className="flex flex-col mb-[16px]">
                                <div className="flex flex-row items-end justify-between">
                                    <label>Име</label>
                                    <div className="mb-[5px]">
                                        <HelpButton name="user_details_help" />
                                    </div>
                                </div>
                                <input
                                    className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                    placeholder="Име"
                                    {...register('firstName')}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex flex-col mb-[16px]">
                                <label>Презиме</label>
                                <input
                                    className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                    placeholder="Презиме"
                                    {...register('lastName')}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex flex-col mb-[16px]">
                                <label>Имејл</label>
                                <input
                                    className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                    placeholder="Имејл"
                                    {...register('email')}
                                    disabled={true}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            {fields.map((item, index) => (
                                <div key={index} className="flex flex-col mb-[16px]">
                                    <label>{item.label}</label>
                                    {item.field === 'category' || item.field === 'subCategory' ? (
                                        <TextareaAutosize
                                            className="border border-[#0000003B] max-w-[520px] h-full px-3 rounded-[4px] outline-none text-[16px] leading-[24px] resize-none overflow-hidden min-h-[56px]"
                                            placeholder={item.label}
                                            {...register(item.field)}
                                            disabled={true}
                                            // rows={item.field === 'subCategory' ? 5 : 3}
                                            value={item.field === 'category' ? getValues('category')?.name : getValues('subCategory')?.name}
                                        ></TextareaAutosize>
                                    ) : (
                                        <input
                                            className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                            placeholder={item.label}
                                            {...register(item.field)}
                                            disabled={true}
                                        />
                                    )}
                                </div>
                            ))}
                            <div className="flex flex-col mb-[16px]">
                                <h2 className="mb-[32px] text-[24px] leading-[28px] text-center font-light ">Детаљи о образцима</h2>
                                <div className="mt-2">
                                    <label htmlFor="">
                                        Сви обрасци поднети: <b>{formsDetails?.submittedAllForms ? 'Да' : 'Не'}</b>
                                    </label>
                                </div>
                                <div className="">
                                    <label htmlFor="">Предвиђени обрасци:</label>
                                    <ul>
                                        {formsDetails?.assignedForms?.map((form: any, index: number) => (
                                            <li key={index}>- {form.name}</li>
                                        ))}
                                    </ul>
                                </div>
                                {!!formsDetails?.remaining?.length && (
                                    <div className="">
                                        <label htmlFor="">Преостали обрасци:</label>
                                        <ul>
                                            {formsDetails?.remaining?.map((form: any, index: number) => (
                                                <li key={index}>- {form.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="flex flex-row items-center justify-between w-full mb-[20px]">
                    <button
                        onClick={() => navigate('/admin/legal-entities')}
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]"
                    >
                        ПРЕТХОДНА СТРАНИЦА
                    </button>
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] border bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[200px]"
                    >
                        ИЗМЕНА КАТЕГОРИЈЕ
                    </button>
                </div>
            </form>
            {/* {open && ( */}
            <EditCategoryModal
                isOpen={open}
                onClose={() => {
                    setOpen(false)
                    loadUser()
                }}
                currentCategory={userData?.category}
                currentSubCategory={userData?.subCategory}
                userId={userId}
            />
            {/* )} */}
        </div>
    )
}

export default LegalEntityDetails
