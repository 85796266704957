import { File } from '../../File.entity'
import { Activity } from '../../Form2/Form2.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'

export const NumberOfStepsForm7_3 = 8

export interface Form7_3Entity {
    report_id?: string
    rep_year?: number
    submitted_date?: Date
    submitted_place?: Date
    category?: string
    organisation?: Organization
    status?: string

    // Step 1
    o7_3_k1_z_tg_2?: number
    o7_3_k1_z_tg_1?: number
    o7_3_k1_z_tg?: number

    o7_3_k1_m_tg_2?: number
    o7_3_k1_m_tg_1?: number
    o7_3_k1_m_tg?: number

    //Step 2
    o7_3_k2_z_15_29?: number
    o7_3_k2_z_30_39?: number
    o7_3_k2_z_40_49?: number
    o7_3_k2_z_50_59?: number
    o7_3_k2_z_60?: number

    o7_3_k2_m_15_29?: number
    o7_3_k2_m_30_39?: number
    o7_3_k2_m_40_49?: number
    o7_3_k2_m_50_59?: number
    o7_3_k2_m_60?: number

    o7_3_k2_jaz_15_29?: number
    o7_3_k2_jaz_30_39?: number
    o7_3_k2_jaz_40_49?: number
    o7_3_k2_jaz_50_59?: number
    o7_3_k2_jaz_60?: number

    //Step 3
    o7_3_k3_z_osnovna?: number
    o7_3_k3_z_srednja?: number
    o7_3_k3_z_visa?: number
    o7_3_k3_z_visoka?: number

    o7_3_k3_m_osnovna?: number
    o7_3_k3_m_srednja?: number
    o7_3_k3_m_visa?: number
    o7_3_k3_m_visoka?: number

    o7_3_k3_jaz_osnovna?: number
    o7_3_k3_jaz_srednja?: number
    o7_3_k3_jaz_visa?: number
    o7_3_k3_jaz_visoka?: number

    //Step4
    o7_3_k4_z_rukovodioci?: number
    o7_3_k4_z_strucnjaci?: number
    o7_3_k4_z_inzenjeri?: number
    o7_3_k4_z_admini?: number
    o7_3_k4_z_usluga?: number
    o7_3_k4_z_poljoprivreda?: number
    o7_3_k4_z_zanatlije?: number
    o7_3_k4_z_rukovaoci?: number
    o7_3_k4_z_jednostavna?: number

    o7_3_k4_m_rukovodioci?: number
    o7_3_k4_m_strucnjaci?: number
    o7_3_k4_m_inzenjeri?: number
    o7_3_k4_m_admini?: number
    o7_3_k4_m_usluga?: number
    o7_3_k4_m_poljoprivreda?: number
    o7_3_k4_m_zanatlije?: number
    o7_3_k4_m_rukovaoci?: number
    o7_3_k4_m_jednostavna?: number

    o7_3_k4_jaz_rukovodioci?: number
    o7_3_k4_jaz_strucnjaci?: number
    o7_3_k4_jaz_inzenjeri?: number
    o7_3_k4_jaz_admini?: number
    o7_3_k4_jaz_usluga?: number
    o7_3_k4_jaz_poljoprivreda?: number
    o7_3_k4_jaz_zanatlije?: number
    o7_3_k4_jaz_rukovaoci?: number
    o7_3_k4_jaz_jednostavna?: number

    // Step 5
    o7_3_k5_z_invalidske?: number
    o7_3_k5_z_starosne?: number

    o7_3_k5_m_invalidske?: number
    o7_3_k5_m_starosne?: number

    o7_3_k5_z_tg_2?: number
    o7_3_k5_z_tg_1?: number
    o7_3_k5_z_tg?: number

    o7_3_k5_m_tg_2?: number
    o7_3_k5_m_tg_1?: number
    o7_3_k5_m_tg?: number

    // Step 6
    o7_3_k6_z_broj_sve_starosne?: number
    o7_3_k6_z_broj_sve_invalidske?: number
    o7_3_k6_z_broj_zaposleni_starosne?: number
    o7_3_k6_z_broj_zaposleni_invalidske?: number
    o7_3_k6_z_broj_samostalne_starosne?: number
    o7_3_k6_z_broj_samostalne_invalidske?: number
    o7_3_k6_z_broj_poljop_starosne?: number
    o7_3_k6_z_broj_poljop_invalidske?: number

    o7_3_k6_z_prosek_sve_starosne?: number
    o7_3_k6_z_prosek_sve_invalidske?: number
    o7_3_k6_z_prosek_zaposleni_starosne?: number
    o7_3_k6_z_prosek_zaposleni_invalidske?: number
    o7_3_k6_z_prosek_samostalne_starosne?: number
    o7_3_k6_z_prosek_samostalne_invalidske?: number
    o7_3_k6_z_prosek_poljop_starosne?: number
    o7_3_k6_z_prosek_poljop_invalidske?: number

    o7_3_k6_m_prosek_sve_starosne?: number
    o7_3_k6_m_prosek_sve_invalidske?: number
    o7_3_k6_m_prosek_zaposleni_starosne?: number
    o7_3_k6_m_prosek_zaposleni_invalidske?: number
    o7_3_k6_m_prosek_samostalne_starosne?: number
    o7_3_k6_m_prosek_samostalne_invalidske?: number
    o7_3_k6_m_prosek_poljop_starosne?: number
    o7_3_k6_m_prosek_poljop_invalidske?: number

    o7_3_k6_m_prosek_penzije_sve_starosne?: number
    o7_3_k6_m_prosek_penzije_sve_invalidske?: number
    o7_3_k6_m_prosek_penzije_zaposleni_starosne?: number
    o7_3_k6_m_prosek_penzije_zaposleni_invalidske?: number
    o7_3_k6_m_prosek_penzije_samostalne_starosne?: number
    o7_3_k6_m_prosek_penzije_samostalne_invalidske?: number
    o7_3_k6_m_prosek_penzije_poljop_starosne?: number
    o7_3_k6_m_prosek_penzije_poljop_invalidske?: number

    //Step 7
    o7_3_k7_z_invalidske?: number
    o7_3_k7_z_starosne?: number

    o7_3_k7_m_invalidske?: number
    o7_3_k7_m_starosne?: number

    submitted_by?: SubmittedBy
}
