import Actions from '../../Components/Actions'
import { TutorialStepInterface } from './Step1'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { ReactComponent as Trash } from '../../../../assets/svg/Trash.svg'
import Tour from 'reactour'
import { useEffect, useState } from 'react'
import { MoonLoader } from 'react-spinners'
import { FileUploader } from 'react-drag-drop-files'
import { ReactComponent as UploadCloud } from '../../../../assets/svg/UploadCloud.svg'

const Step5 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose, setCheckTourOpen }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    setCheckTourOpen(isTourOpen)
    const disabled = false
    const radioOptions = ['Одлично', 'Врло добро', 'Задовољава', 'Недовољно']
    const files: any[] = [{ name: 'Fajl2.pdf', status: 'ok' }]
    const tourSteps = [
        {
            selector: 'div[id="picker"]',
            content:
                'Календар омогућава корисницима прецизан избор датума са приказом године, месеца и дана. У врху календара приказани су тренутни месец и година. Кликом на то поље отвара се падајући мени за одабир године. Након избора године, потребно је да изаберете жељени месец. За кретање између месеци користите стрелице лево и десно. Сви дани у месецу су приказани као бројеви у календару. Кликом на жељени датум, тај дан ће бити означен кружићем, као што је овде случај са 17. децембром 2024. Изабрани датум се аутоматски преноси у поље за датум.',
        },
        {
            selector: 'div[id="radio"]',
            content: 'Ово су дугмад која омогућавају кориснику да изабере једну од понуђених опција. За одабир је потребно кликнути на кружић који се налази поред жељене опције.',
        },
        {
            selector: 'div[id="fileUpload"]',
            content:
                'Поље за додавање датотеке. Да би сте додали датотеку можете превући и отпустити датотеку у облачић на средини екрана или кликнути на дугме „ПРЕТРАЖИТЕ“ и одабрати жељену датотеку из свог рачунара. Подржани формат је ПДФ до 2 МБ величине. Други формати нису подржани. Можете додати више датотека. Уколико додате датотеку која је већа од 2 МБ или није у подржаном формату добићете обавештење у виду алерта на горњем делу странице. ',
        },
        {
            selector: 'div[id="success"]',
            content:
                'Након што сте додали датотеку, иста ће се појавити у формату као што је приказано. Можете кликнути на дугме ОТВОРИ како бисте видели датотеку или на дугме ОБРИШИ како бисте обрисали датотеку. ',
        },
    ]

    const handleTourClose = () => {
        setIsTourOpen(false)
    }
    return (
        <div className="flex h-full w-full flex-col items-center">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div className="border border-borderTutorial p-10 w-full h-full">
                <div id="picker" className="flex items-start h-[350px]">
                    <div className={`w-full h-full  flex flex-row items-center justify-center self-center flex-1 max-w-[186px] min-h-[50px]`}>
                        <div className={`px-3 border border-[#D9D9D9] cursor-default flex flex-row items-center justify-center w-[500px] h-[50px]`}>
                            <p className={`text-[16px] leading-5 text-[#D9D9D9]`}>
                                <span>{'Датум'}</span>
                            </p>
                        </div>
                        <div className={`w-full h-full `}>
                            <div className="w-full h-full relative">
                                <div className="invisible absolute top-[-50px] left-10">
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr-cyrl">
                                        <DatePicker
                                            open={true}
                                            maxDate={dayjs()}
                                            value={dayjs('2024-12-17')}
                                            disabled
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'inherit',
                                                    },
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: 'inherit',
                                                    WebkitTextFillColor: 'inherit',
                                                    opacity: 1,
                                                },
                                                '&.Mui-disabled': {
                                                    color: 'inherit',
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="text-borderTutorial mt-10" />
                <div className="mt-10" id="radio">
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="options" name="radio-buttons-group" sx={{ marginLeft: '20px', marginTop: '10px', marginBottom: '40px' }}>
                            {radioOptions.map((option, index) => (
                                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </div>
                <hr className="text-borderTutorial mt-10" />
                <div id="fileUpload" className="mt-10">
                    <div className={`mt-[20px] mb-[10px] ml-[5px] w-[445px] cursor-default`}>
                        <FileUploader
                            onTypeError={() => window.alert('Фајл који сте послали није доброг формата!')}
                            name="file"
                            classes="bg-primary"
                            hoverTitle="​"
                            disabled={true}
                            onSizeError={() => window.alert('Фајл који сте послали је превелик!')}
                        >
                            <div
                                className={`h-[202px] w-[445px] border border-primary  border-dashed rounded-[4px] flex items-center justify-center flex-col gap-[20px]
                                    cursor-default`}
                                style={disabled ? { filter: 'grayscale(100%)' } : {}}
                            >
                                <UploadCloud />
                                <div className="flex flex-col gap-[8px]">
                                    <p className="font-medium text-[18px] text-center">
                                        Превуците и отпустите датотеке или
                                        <button disabled className={`text-primary cursor-default`}>
                                            Претражите
                                        </button>
                                    </p>
                                    <p className="text-disabled font-bold text-[14px] text-center">Подржани формати: ПДФ</p>
                                </div>
                            </div>
                        </FileUploader>
                    </div>
                    <div className="h-[10px]"></div>

                    <p className="opacity-[87%] font-thin text-[14px] mt-[20px]">Напомена: Дозвољени формат ПДФ до 2МБ.</p>

                    {!!files.length && (
                        <div className="mt-[20px] mb-[40px]">
                            <p className="mb-[10px]">Послати фајлови:</p>
                            <div id="success" className="flex flex-col gap-[10px]">
                                {files.map((e, index) => (
                                    <div key={index} className="flex flex-row">
                                        <div className="h-[46px] rounded-[4px] border w-[445px] flex flex-row items-center px-[10px] border-[#C5FD8D] mr-[15px]">
                                            <p className="flex-1 text-[16px]">{e.name}</p>
                                            {e.status === 'uploading' ? (
                                                <MoonLoader size={20} color={'#123abc'} loading={true} className="ml-2" />
                                            ) : e.status === 'error' ? (
                                                <i>Дошло је до грешке</i>
                                            ) : null}
                                        </div>
                                        {e.status === 'error' && (
                                            <button>
                                                <Trash />
                                            </button>
                                        )}
                                        {e.status === 'ok' && !disabled && (
                                            <>
                                                <Button
                                                    sx={{
                                                        backgroundColor: (theme) => theme.palette.error.main,
                                                        color: (theme) => theme.palette.error.contrastText,
                                                        '&.Mui-disabled': {
                                                            backgroundColor: (theme) => theme.palette.error.main,
                                                            color: (theme) => theme.palette.error.contrastText,
                                                        },
                                                    }}
                                                    disabled
                                                    variant="contained"
                                                    className="!bg-textError !mr-[10px]"
                                                >
                                                    Обриши
                                                </Button>
                                                <Button
                                                    disabled
                                                    sx={{
                                                        cursor: 'default',
                                                        opacity: 1,
                                                        '&.Mui-disabled': {
                                                            backgroundColor: '#1976d2',
                                                            color: 'white',
                                                        },
                                                    }}
                                                    variant="contained"
                                                >
                                                    Отвори
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-10 w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step5
