import React from 'react'
import { Form7_18Entity } from '../../../../../models/Form7/Entities/Form7_18.entity'
import { Control, useWatch } from 'react-hook-form'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import RadioButtonsControl from '../../../../../components/Common/RadioButtonsControlWithError'
import Textbox from '../../../../../components/Common/Textbox'
import ActionButtons from '../../../../../components/ActionButtons'

export interface StepInterface {
    control?: Control<Form7_18Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
    setError?: any
}

interface StepInterfaceStep1 extends StepInterface {
    nextStepTwice: () => void
}

export const Step1 = ({ control, nextStep, previousStep, nextStepTwice, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterfaceStep1) => {
    const watchedValues = useWatch<Form7_18Entity>({ control })
    const fields: 'o7_18_k1_budzet'[] = ['o7_18_k1_budzet']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const nextStepFunction = () => {
        if (watchedValues.o7_18_k1_budzet === false) {
            nextStepTwice()
        } else {
            nextStep()
        }
    }

    const goToNextStep = () => {
        if (!disabledNext) nextStepFunction()
        else if (adminView) nextStepFunction()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px]">Да ли као организација за контролу финансирања поседујете буџет?</h1>
            <div className="flex flex-row gap-2 ">
                <RadioButtonsControl
                    control={control}
                    name={'o7_18_k1_budzet'}
                    options={['Не', 'Да']}
                    errors={watchedValues.o7_18_k1_budzet == undefined ? errors : {}}
                    boolean
                    disabled={adminView}
                />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
