import { TblOblast1 } from '../../../../models/Form1/Form1.entity'
import { Control, useForm, useWatch } from 'react-hook-form'
import Actions from '../../Components/Actions'
import { ReactComponent as Check } from '../../../../assets/svg/stepButtons/CompletedButtonCheck.svg'
import { TutorialStepInterface } from './Step1'
import { Button, Stack, Tooltip } from '@mui/material'
import TutorialNumberInput from '../../Components/TutorialNumberInput'
import Tour from 'reactour'
import { useEffect, useMemo, useRef, useState } from 'react'
import FirstButton from '../../../../assets/svg/stepButtons/FirstButton'
import FinishButton from '../../../../assets/svg/stepButtons/FinishButton'
import StepButton from '../../../../assets/svg/stepButtons/StepButton'
import { twoDigits } from '../../../../utils/mathUtils'
export interface RowInterface {
    label: string
    field: keyof TblOblast1
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o1_k1_m' },
    { label: 'Жене', field: 'o1_k1_z' },
]

function Step2({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const { control } = useForm<TblOblast1>({})
    const fields: ('o1_k1_m' | 'o1_k1_z')[] = ['o1_k1_m', 'o1_k1_z']

    const errors = { o1_k1_z: { type: 'required', message: '' } }

    const header = ['Пол', 'Број', 'Проценат']

    const [activeStep, setActiveStep] = useState(1)
    const [lastStep, setLastStep] = useState(0)
    const steps = [1, 2, 3, 4, 5]

    const containerRef = useRef<HTMLDivElement | null>(null)
    const selectedItemRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (selectedItemRef.current && containerRef.current) {
            selectedItemRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            })
        }
    }, [activeStep])

    useEffect(() => {
        setLastStep(Math.max(lastStep, activeStep))
    }, [activeStep])

    const tourSteps = [
        {
            selector: 'div[id="main"]',
            content: 'Пример корака за попуњавање обрасца',
        },
        {
            selector: 'div[id="nav"]',
            content:
                'Навигациона трака за кораке у обрасцу. Уколико су испуњени услови за прелазак на следећи корак, сви подаци који су унесени су сачувани при промени корака и увек можете да прекинете ваш унос и да наставите касније.',
        },
        {
            selector: 'div[id="firstButton"]',
            content: 'Овако изгледа претходни корак који је успешно сачуван.',
        },
        {
            selector: 'div[id="activeButton"]',
            content: 'Aктивни корак на тренутној страници.',
        },
        {
            selector: 'h1[id="question"]',
            content: 'Питање које се односи на тренутни корак.',
        },
        {
            selector: 'div[id="help"]',
            content: 'Када кликнете на модул „Упутство“, биће вам приказан попуп прозор са правилницима.',
        },
        {
            selector: 'td[id="head"]',
            content: 'Поља која су обележена сивом бојом се не попуњавају.',
        },
        {
            selector: 'td[id="active"]',
            content: 'Обавезна поља која су обележена звездицом у која треба унети вредност.',
        },
        {
            selector: 'td[id="error"]',
            content: 'Уколико не унесете вредност, поља су црвене боје и не можете прећи на следећи корак.',
        },
        {
            selector: 'div[id="prev"]',
            content: 'Када корисник кликне на ово дугме, враћа се на претходни корак.',
        },
        {
            selector: 'div[id="next"]',
            content: 'Уколико сте унели вредности у сва обавезна поља и кликнете на поменуто дугме, подаци су сачувани и прелазите на следећи корак. ',
        },
    ]

    const handleTourClose = () => {
        setIsTourOpen(false)
    }
    return (
        <div>
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div id="main" className="border border-borderTutorial p-10">
                <div id="nav" ref={containerRef} className="flex flex-row overflow-x-auto overflow-y-hidden pb-[5px]">
                    <div className="h-[57px]" ref={activeStep === 0 ? selectedItemRef : null}>
                        <button
                            className="h-[57px] relative cursor-default"
                            onClick={() => setActiveStep(0)}
                            disabled={0 > lastStep}
                            data-tooltip-hidden={!fields}
                            data-tooltip-id="step"
                            data-tooltip-content={`Step 1`}
                        >
                            <div id="firstButton">
                                <FirstButton fill={activeStep === 0 ? '#C5FD8D' : 'white'} />
                            </div>
                            <div className="absolute z-1 top-0 h-full w-full flex flex-row items-center pl-[31px] gap-[6px]">
                                {0 < lastStep && <Check />}
                                {0 >= lastStep && (
                                    <div
                                        className="w-[40px] h-[40px] flex items-center justify-center border-[2px] border-inactive rounded-full"
                                        style={{ borderColor: activeStep === 0 ? '#4891CB' : '#CFD6DC' }}
                                    >
                                        <p className="text-center text-inactive" style={{ color: activeStep === 0 ? '#4891CB' : '#CFD6DC' }}>
                                            {twoDigits(1)}
                                        </p>
                                    </div>
                                )}
                                <span style={{ color: activeStep === 0 ? '#4891CB' : '#00000061' }}>Корак 1</span>
                            </div>
                        </button>
                    </div>

                    <div className="h-[57px]" ref={activeStep === 1 ? selectedItemRef : null}>
                        <button
                            className="h-[57px] relative cursor-default"
                            onClick={() => setActiveStep(1)}
                            disabled={1 > lastStep}
                            data-tooltip-hidden={!fields}
                            data-tooltip-id="step"
                            data-tooltip-content={`Step 2`}
                        >
                            <div id="activeButton">
                                <StepButton fill={activeStep === 1 ? '#C5FD8D' : 'white'} />
                            </div>
                            <div className="absolute z-1 top-0 h-full w-full flex flex-row items-center pl-[31px] gap-[6px]">
                                {1 < lastStep && <Check />}
                                {1 >= lastStep && (
                                    <div
                                        className="w-[40px] h-[40px] flex items-center justify-center border-[2px] border-inactive rounded-full"
                                        style={{ borderColor: activeStep === 1 ? '#4891CB' : '#CFD6DC' }}
                                    >
                                        <p className="text-center text-inactive" style={{ color: activeStep === 1 ? '#4891CB' : '#CFD6DC' }}>
                                            {twoDigits(2)}
                                        </p>
                                    </div>
                                )}
                                <span style={{ color: activeStep === 1 ? '#4891CB' : '#00000061' }}>Корак 2</span>
                            </div>
                        </button>
                    </div>

                    <div className="h-[57px]" ref={activeStep === 2 ? selectedItemRef : null}>
                        <button
                            className="h-[57px] relative cursor-default"
                            onClick={() => setActiveStep(2)}
                            disabled={2 > lastStep}
                            data-tooltip-hidden={!fields}
                            data-tooltip-id="step"
                            data-tooltip-content={`Step 3`}
                        >
                            <div id="completeButton">
                                <StepButton fill={activeStep === 2 ? '#C5FD8D' : 'white'} />
                            </div>
                            <div className="absolute z-1 top-0 h-full w-full flex flex-row items-center pl-[31px] gap-[6px]">
                                {2 < lastStep && <Check />}
                                {2 >= lastStep && (
                                    <div
                                        className="w-[40px] h-[40px] flex items-center justify-center border-[2px] border-inactive rounded-full"
                                        style={{ borderColor: activeStep === 2 ? '#4891CB' : '#CFD6DC' }}
                                    >
                                        <p className="text-center text-inactive" style={{ color: activeStep === 2 ? '#4891CB' : '#CFD6DC' }}>
                                            {twoDigits(3)}
                                        </p>
                                    </div>
                                )}
                                <span style={{ color: activeStep === 2 ? '#4891CB' : '#00000061' }}>Корак 3</span>
                            </div>
                        </button>
                    </div>

                    <div className="h-[57px]" ref={activeStep === 3 ? selectedItemRef : null}>
                        <button
                            className="h-[57px] relative cursor-default"
                            onClick={() => setActiveStep(3)}
                            disabled={3 > lastStep}
                            data-tooltip-hidden={!fields}
                            data-tooltip-id="step"
                            data-tooltip-content={`Step 4`}
                        >
                            <div id="completeButton">
                                <StepButton fill={activeStep === 3 ? '#C5FD8D' : 'white'} />
                            </div>
                            <div className="absolute z-1 top-0 h-full w-full flex flex-row items-center pl-[31px] gap-[6px]">
                                {3 < lastStep && <Check />}
                                {3 >= lastStep && (
                                    <div
                                        className="w-[40px] h-[40px] flex items-center justify-center border-[2px] border-inactive rounded-full"
                                        style={{ borderColor: activeStep === 3 ? '#4891CB' : '#CFD6DC' }}
                                    >
                                        <p className="text-center text-inactive" style={{ color: activeStep === 3 ? '#4891CB' : '#CFD6DC' }}>
                                            {twoDigits(4)}
                                        </p>
                                    </div>
                                )}
                                <span style={{ color: activeStep === 3 ? '#4891CB' : '#00000061' }}>Корак 4</span>
                            </div>
                        </button>
                    </div>

                    <div className="h-[57px]" ref={activeStep === 4 ? selectedItemRef : null}>
                        <button
                            className="h-[57px] relative cursor-default"
                            onClick={() => setActiveStep(4)}
                            disabled={4 > lastStep}
                            data-tooltip-hidden={!fields}
                            data-tooltip-id="step"
                            data-tooltip-content={`Step 5`}
                        >
                            <div id="completeButton">
                                <FinishButton fill={activeStep === 4 ? '#C5FD8D' : 'white'} />
                            </div>
                            <div className="absolute z-1 top-0 h-full w-full flex flex-row items-center pl-[31px] gap-[6px]">
                                {4 < lastStep && <Check />}
                                {4 >= lastStep && (
                                    <div
                                        className="w-[40px] h-[40px] flex items-center justify-center border-[2px] border-inactive rounded-full"
                                        style={{ borderColor: activeStep === 4 ? '#4891CB' : '#CFD6DC' }}
                                    >
                                        <p className="text-center text-inactive" style={{ color: activeStep === 4 ? '#4891CB' : '#CFD6DC' }}>
                                            {twoDigits(5)}
                                        </p>
                                    </div>
                                )}
                                <span style={{ color: activeStep === 4 ? '#4891CB' : '#00000061' }}>Корак 5</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="mt-[10px]">
                    <span className="text-[16px] font-light">
                        Корак {activeStep + 1} од {steps.length}
                    </span>
                </div>
                <div className="mt-5">
                    <div className=" flex flex-row justify-between">
                        <h1 id="question" className="step-name">
                            1. Број запослених и радно ангажованих лица:
                        </h1>
                        <div id="help">
                            <button
                                className="flex flex-row items-center justify-center gap-2 border border-[#909090] hover:border-[#202020] rounded-full h-8 pl-[5px] pr-[8px] py-[4px] transition"
                                type="button"
                            >
                                <div className="flex items-center justify-center min-w-6 h-6 rounded-full bg-[#606060]">
                                    <p className="font-bold text-white text-[16px] leading-4 mt-[1px]">?</p>
                                </div>
                                <p className="text-[#606060] text-[16px] leading-5">Упутство</p>
                            </button>
                        </div>
                    </div>
                    <table className="simple-table mt-[30px]">
                        <thead>
                            <tr className="simple-table-header">
                                {header?.map((head: any, index: any) => (
                                    <th className="simple-table-cell" key={index}>
                                        {head}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row: any, index: any) => (
                                <tr className="simple-table-row" key={index}>
                                    <td id={index === 0 ? 'head' : ''} className="simple-table-cell disabled  !mt-[8px]">
                                        {row.label}
                                    </td>
                                    <td
                                        id={index === 0 ? 'active' : 'error'}
                                        className="simple-table-cell editable !border-none  !mt-[8px]"
                                        style={{ border: 0, backgroundColor: '', padding: 0 }}
                                    >
                                        <TutorialNumberInput name={row.field as string} isMultipleTable={true} isRequired={true} errors={errors} disabled={true} />
                                    </td>
                                    <td className="simple-table-cell disabled  !mt-[8px]">%</td>
                                </tr>
                            ))}
                            <tr className="simple-table-footer !mt-[8px]">
                                <td className="simple-table-cell">Укупно</td>
                                <td className="simple-table-cell">0</td>
                                <td className="simple-table-cell">%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <Stack spacing={'10px'} direction="row" className="mt-[50px]">
                        <div id="prev">
                            <Button
                                variant="contained"
                                sx={{
                                    cursor: 'default',
                                    opacity: 1,
                                    '&.Mui-disabled': {
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                    },
                                }}
                                disabled
                            >
                                Претходни корак
                            </Button>
                        </div>
                        <div id="next">
                            <Button
                                sx={{
                                    cursor: 'default',
                                    opacity: 1,
                                    '&.Mui-disabled': {
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                    },
                                }}
                                disabled
                                variant="contained"
                                type="submit"
                                title="Следећи корак"
                                className="cursor-default"
                            >
                                Следећи корак
                            </Button>
                        </div>
                    </Stack>
                </div>
            </div>
            <div className="mt-10 w-full">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={true} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step2
