import { Input } from '@mui/material'
import { Controller } from 'react-hook-form'

interface SimpleInputRowInterface {
    name: string
    maxNumberOfCharacter?: number
    control: any
    title: string
    disabled?: boolean
    type?: 'text' | 'date' | 'tel'
    errors?: any
    isRequired?: boolean
    isEmail?: boolean
    isName?: boolean
    width?: number
}

function SimpleInputRowForObject({
    name,
    title,
    maxNumberOfCharacter = 50,
    control,
    disabled = false,
    type = 'text',
    errors = {},
    isRequired = false,
    isEmail = false,
    isName = false,
    width = 1028,
}: SimpleInputRowInterface) {
    const parts = name.split('.')

    const objectName = parts[0]
    const objectField = parts[1]

    const hasError = (!disabled && !!errors[objectName]?.[objectField]) || (!disabled && !!errors[name])

    const emailPattern = isEmail
        ? {
              pattern: {
                  value: /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: 'Електронска адреса није исправно попуњена',
              },
          }
        : {}

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    return (
        <div className="flex flex-row items-center gap-[10px]" onKeyDown={handleKeyDown}>
            <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                <p className="text-center mx-auto">{title}</p>
            </div>
            <div
                className={`border border-[#d9d9d9] h-[50px] flex items-center p-[15px]`}
                style={{
                    borderColor: hasError ? '#d32f2f' : '#d9d9d9',
                    width: `${width}px`,
                }}
            >
                <Controller
                    name={name}
                    control={control}
                    rules={{ required: isRequired && !disabled, ...emailPattern }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            onChange={(event) => {
                                const newValue = event.target.value

                                if (isName) {
                                    const regex = /^[A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ\s]+$/
                                    if (newValue === '' || regex.test(newValue)) {
                                        field.onChange(newValue)
                                    }
                                } else {
                                    field.onChange(newValue)
                                }
                            }}
                            value={field.value}
                            onKeyDown={(event) => {
                                if (isName) {
                                    const key = event.key
                                    const regex = /^[A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ\s]+$/

                                    const controlKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']

                                    if (!regex.test(key) && !controlKeys.includes(key)) {
                                        event.preventDefault()
                                    }
                                }
                            }}
                            type={type}
                            className="!bg-[transparent] border-none w-full"
                            placeholder={`Текст (${maxNumberOfCharacter} карактера)`}
                            disabled={disabled}
                            inputProps={{ maxLength: maxNumberOfCharacter }}
                            sx={{
                                textAlign: 'left',
                                '& .MuiInputBase-input': {
                                    textAlign: 'left',
                                    color: hasError ? '#d32f2f' : 'inherit',
                                    '&::placeholder': {
                                        color: hasError ? '#d32f2f' : 'inherit',
                                    },
                                },
                                '&.MuiInputBase-root:before': {
                                    borderBottom: 'none',
                                },
                                '&.MuiInputBase-root:after': {
                                    borderBottom: 'none',
                                },
                                '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none',
                                },
                            }}
                        />
                    )}
                />
            </div>
        </div>
    )
}

export default SimpleInputRowForObject
