import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import Textbox from '../../../../../components/Common/Textbox'
import { Form7_18Entity } from '../../../../../models/Form7/Entities/Form7_18.entity'
import { StepInterface } from './Step1'
import { useEffect, useState } from 'react'
import CheckboxComponent from '../../../Components/CheckboxComponent'
const titles = [
    'Лош софтвер за планирање',
    'Недостатак обуке',
    'Недостатак сазнања',
    'Недовољно средства у буџету',
    'Недовољно времена',
    'Нејасна регулатива',
    'Организационе препреке',
    'Неадекватна свест',
]

export function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors, setError }: StepInterface) {
    const watchedValues = useWatch<Form7_18Entity>({ control })
    const [hasCompletedRequired, setHasCompletedRequired] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const fields: (
        | 'o7_18_k4_nedostatakSaznanja'
        | 'o7_18_k4_nejasnaRegulativa'
        | 'o7_18_k4_losSoftverZaPlaniranje'
        | 'o7_18_k4_nedostatakObuke'
        | 'o7_18_k4_organizacionePrepreke'
        | 'o7_18_k4_nedovoljnoVremena'
        | 'o7_18_k4_nedovoljnoSredstvaUBudzetu'
        | 'o7_18_k4_svest'
    )[] = [
        'o7_18_k4_losSoftverZaPlaniranje',
        'o7_18_k4_nedostatakObuke',
        'o7_18_k4_nedostatakSaznanja',
        'o7_18_k4_nedovoljnoSredstvaUBudzetu',
        'o7_18_k4_nedovoljnoVremena',
        'o7_18_k4_nejasnaRegulativa',
        'o7_18_k4_organizacionePrepreke',
        'o7_18_k4_svest',
    ]

    const allFields: (
        | 'o7_18_k4_nedostatakSaznanja'
        | 'o7_18_k4_nejasnaRegulativa'
        | 'o7_18_k4_losSoftverZaPlaniranje'
        | 'o7_18_k4_nedostatakObuke'
        | 'o7_18_k4_organizacionePrepreke'
        | 'o7_18_k4_nedovoljnoVremena'
        | 'o7_18_k4_nedovoljnoSredstvaUBudzetu'
        | 'o7_18_k4_svest'
        | 'o7_18_k4_opis'
    )[] = [
        'o7_18_k4_losSoftverZaPlaniranje',
        'o7_18_k4_nedostatakObuke',
        'o7_18_k4_nedostatakSaznanja',
        'o7_18_k4_nedovoljnoSredstvaUBudzetu',
        'o7_18_k4_nedovoljnoVremena',
        'o7_18_k4_nejasnaRegulativa',
        'o7_18_k4_organizacionePrepreke',
        'o7_18_k4_svest',
        'o7_18_k4_opis',
    ]

    const hasCheckedAnyBox = fields.some((field) => watchedValues[field])

    const hasEnteredText = !!watchedValues.o7_18_k4_opis?.toString().trim()

    useEffect(() => {
        const hasCompletedRequired = hasCheckedAnyBox || hasEnteredText
        setHasCompletedRequired(hasCompletedRequired)

        if (hasCompletedRequired) {
            clearErrors(allFields)
            setErrorMessage('')
        }
    }, [hasCheckedAnyBox, hasEnteredText])

    const disabledNext = !hasCompletedRequired

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
        else {
            setErrorMessage('Молимо Вас да одаберете једну од понуђених опција или унесете Вашу препреку у текстуално поље.')
        }
    }

    const validateAllFields = () => {
        console.log('validateAllFields')
        if (!hasCheckedAnyBox && !hasEnteredText) {
            fields.forEach((field) => {
                setError(field, {
                    type: 'required',
                    message: 'Обавезно поље.',
                })
                setErrorMessage('Молимо Вас да одаберете једну од понуђених опција или унесете Вашу препреку у текстуално поље.')
            })

            setError('o7_18_k4_opis', {
                type: 'required',
                message: 'Обавезно поље.',
            })
        } else {
            setErrorMessage('')
            clearErrors([...fields, 'o7_18_k4_opis'])
        }
    }
    return (
        <div>
            <h1 className="step-name mb-[30px] ">Шта су по вама највеће препреке или изазови за успешну примену и унапрећење РОБ ? </h1>

            {fields.map((field, index) => (
                <CheckboxComponent
                    key={field}
                    control={control}
                    errors={errors}
                    isRequired={!hasCheckedAnyBox}
                    name={field}
                    text={titles[index]}
                    onValidate={validateAllFields}
                    disabled={adminView}
                />
            ))}
            <div className="mt-[50px] ">
                <h4 className="mb-[10px]">Друго - молимо Вас да упишете:</h4>
                <div className="flex flex-col">
                    <Textbox
                        name="o7_18_k4_opis"
                        control={control}
                        placeholder="Текст (200 карактера)*"
                        errors={errors}
                        maxNumberOfCharacters={200}
                        isRequired={!hasCheckedAnyBox ? 'Попуните обавезно поље.' : false}
                        disabled={adminView}
                    />
                </div>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px]">{errorMessage}</span>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
