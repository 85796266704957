import React, { useEffect, useState } from 'react'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import Step5 from './Steps/Step5'
import Step6 from './Steps/Step6'
import Step7 from './Steps/Step7'
import Step8 from './Steps/Step8'
import Step9 from './Steps/Step9'
import Step10 from './Steps/Step10'
import Step11 from './Steps/Step11'
import Step12 from './Steps/Step12'
import Step13 from './Steps/Step13'
import Step14 from './Steps/Step14'
import Step15 from './Steps/Step15'
import Step16 from './Steps/Step16'
import Step17 from './Steps/Step17'
import Step18 from './Steps/Step18'
import Step19 from './Steps/Step19'
import Step20 from './Steps/Step20'
import Step21 from './Steps/Step21'
import { getForm2, getUnfinishedForm2, saveForm2 } from '../../server/server'
import { useForm } from 'react-hook-form'
import Stepper from '../../components/Common/Stepper/Stepper'
import { TblOblast2 } from '../../models/Form2/Form2.entity'
import { FORM2_NAMES, HELP_BUTTON_2 } from '../../models/Form2/Form2Names'
import { FORM2_TITLE } from '../../models/FormTitles'
import FinishOfFormModal from '../../components/Common/FinishOfFormModal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isNotEmpty } from '../../utils/Validation'
import LoadingSpinner from '../../components/Common/LoadingSpinner'
import { useUser } from '../../auth/UseUser'

import TutorialModal from '../Tutorial/TutorialModal'

import HelpButton from '../../components/Common/HelpButton'

function Form2Screen() {
    let navigate = useNavigate()
    const { user } = useUser()
    const adminView = user.admin

    const [activeStep, setActiveStep] = useState(0)
    const [finishModalVisible, setFinishModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formCompleted, setFormCompleted] = useState(false)
    const [tutorialModalOpen, setTutorialModalOpen] = useState(!adminView)
    let [searchParams, setSearchParams] = useSearchParams()

    const adminUsage = searchParams.get('adminUsage') === '1'

    const formId = searchParams.get('formId')

    const {
        handleSubmit,
        control,
        getValues,
        reset,
        setValue,
        formState: { errors },
        clearErrors,
    } = useForm<TblOblast2>({
        defaultValues: {
            o2_k10_aktivnosti: [{ level: '', maleNumber: undefined, femaleNumber: undefined }],
            o2_k11_aktivnosti: [{ level: '', maleNumber: undefined, femaleNumber: undefined }],
            o2_k14_realisations: [{ name: '', from: null, to: null, indicator: '' }],
            o2_k16_programskeAktivnosti: [{ text_content: '' }],
            o2_k16_nosioci: [{ text_content: '' }],
            o2_k19_aktivnosti: [{ text_content: '' }],
        },
    })

    const decimalKeys = [
        'o2_k18_kol1_15_30',
        'o2_k18_kol1_31_40',
        'o2_k18_kol1_41_50',
        'o2_k18_kol1_51_60',
        'o2_k18_kol1_61_70',
        'o2_k18_kol3_15_30',
        'o2_k18_kol3_31_40',
        'o2_k18_kol3_41_50',
        'o2_k18_kol3_51_60',
        'o2_k18_kol3_61_70',
        'o2_k18_kol5_15_30',
        'o2_k18_kol5_31_40',
        'o2_k18_kol5_41_50',
        'o2_k18_kol5_51_60',
        'o2_k18_kol5_61_70',
    ]

    useEffect(() => {
        const formValues = getValues()
        const isNewForm = isNotEmpty(formValues.o2_k1_m)
        if (!isNewForm) {
            getUnfinishedForm()
        }
    }, [])

    const getUnfinishedForm = async () => {
        setLoading(true)
        let resp
        if (!adminView) {
            resp = await getUnfinishedForm2()
        } else {
            resp = await getForm2(formId)
        }
        const form = resp.data.form

        if (resp.status === 200 && resp.data) {
            Object.keys(form).forEach((key) => {
                if (decimalKeys.includes(key)) {
                    setValue(key as keyof TblOblast2, parseFloat(form[key]))
                } else {
                    setValue(key as keyof TblOblast2, form[key])
                }

                if (key === 'report') {
                    setValue('submitted_by.firstName', form[key].submittedBy.firstName)
                    setValue('submitted_by.lastName', form[key].submittedBy.lastName)
                    setValue('submitted_by.phoneNumber', form[key].submittedBy.phoneNumber)
                    setValue('submitted_by.email', form[key].submittedBy.email)
                    setValue('submitted_by.files', form[key].submittedBy.files)
                }
            })
            setFormCompleted(resp.data.form.status !== 0)
            setActiveStep(Number(resp.data.currentStep))
        }
        setLoading(false)
    }

    const onSelectStep = (step: number) => setActiveStep(step)

    const nextStep = async () => {
        const form = getValues()
        if (!adminView) {
            await save()
            if (activeStep === 0 && form.status === undefined) getUnfinishedForm()
        }
        setActiveStep(activeStep + 1)
    }
    const previousStep = () => setActiveStep(activeStep - 1)

    const finish = async () => {
        if (!adminView) {
            await save()
        }
        setFinishModalVisible(true)
    }

    const resetValuesAndGoToFirstStep = () => {
        setFinishModalVisible(false)
        setActiveStep(0)
        reset()
        navigate('/')
    }

    const stepComponent = () => {
        switch (activeStep) {
            case 0:
                return <Step1 control={control} nextStep={nextStep} previousStep={previousStep} previousButtonDisabled={activeStep === 0} errors={errors} adminView={adminView} />
            case 1:
                return <Step2 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 2:
                return <Step3 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 3:
                return <Step4 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 4:
                return <Step5 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 5:
                return <Step6 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 6:
                return <Step7 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 7:
                return <Step8 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 8:
                return <Step9 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 9:
                return <Step10 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 10:
                return <Step11 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 11:
                return <Step12 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 12:
                return <Step13 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 13:
                return <Step14 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 14:
                return <Step15 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 15:
                return <Step16 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 16:
                return <Step17 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} clearErrors={clearErrors} adminView={adminView} />
            case 17:
                return <Step18 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 18:
                return <Step19 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 19:
                return <Step20 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 20:
                return <Step21 control={control} nextStep={finish} previousStep={previousStep} errors={errors} adminView={adminView} setValue={setValue} />
            default:
                return null
        }
    }

    const save = async () => {
        const resp = await saveForm2(getValues(), (activeStep + 1) as number)

        if (resp.status === 201) {
            setValue('report_id', resp.data.report_id)
        }
    }

    const onSubmit = async () => {
        //console.log('get values ', getValues())
    }

    return (
        <div className="max-w-[1556px] mx-[auto] px-[40px] pb-[20px]">
            <div className="pt-[40px] pb-[55px] flex flex-row gap-5 items-start justify-between">
                <h1 className="text-[24px] font-light">{FORM2_TITLE}</h1>
                <HelpButton name={HELP_BUTTON_2[activeStep]} marginTop={5} />
            </div>
            <div>
                <Stepper
                    formName={FORM2_NAMES}
                    numberOfSteps={21}
                    onSelectStep={onSelectStep}
                    activeStep={activeStep}
                    unlockEveryStep={!!adminUsage || adminView || formCompleted}
                />
            </div>
            {!loading ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-[55px]">{stepComponent()}</div>
                </form>
            ) : (
                <div className="flex justify-center items-center h-full mt-[100px]">
                    <LoadingSpinner />
                </div>
            )}
            <TutorialModal open={tutorialModalOpen} onClose={() => setTutorialModalOpen(false)} segment="form" />
            <FinishOfFormModal modalIsOpen={finishModalVisible} closeModal={resetValuesAndGoToFirstStep} infoText={FORM2_TITLE} adminView={adminView} />
        </div>
    )
}

export default Form2Screen
