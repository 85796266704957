import { useWatch } from 'react-hook-form'
import ActionButtons from '../../../components/ActionButtons'
import { TblOblast6 } from '../../../models/Form6/Form6.entity'
import SimpleInputRowForObject from '../../Form2/Components/SimpleImputRowForObject'
import { StepInterface } from './Step1'

const REGEX_STRING = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

function Step8({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast6>({ control })

    const fields: ('o6_k8_firstName' | 'o6_k8_lastName' | 'o6_k8_phoneNumber' | 'o6_k8_email')[] = ['o6_k8_firstName', 'o6_k8_lastName', 'o6_k8_phoneNumber', 'o6_k8_email']
    const disabledNext = !fields.every((e) => !!watchedValues && !!watchedValues[e])

    const goToNextStep = () => {
        if (!disabledNext && !errors?.o6_k8_email) nextStep()
        else if (adminView) nextStep()
    }

    const renderErrorMessage = (field: string) => {
        const error = errors?.[field]

        if (!error) return null

        let message = 'Попуните обавезно поље'

        if (error?.type === 'pattern') {
            message += ', Електронска адреса није исправно попуњена.'
        } else {
            message += '.'
        }

        return <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">{message}</p>
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }
    return (
        <div onKeyDown={handleKeyDown}>
            <h1 className="step-name">Лице задужено за сачињавање извежтаја</h1>
            <div className="mt-[20px] flex flex-col gap-[15px]">
                <SimpleInputRowForObject
                    title="Име"
                    name="o6_k8_firstName"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    isName
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Презиме"
                    name="o6_k8_lastName"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    isName
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Контакт телефон"
                    name="o6_k8_phoneNumber"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Емаил"
                    name="o6_k8_email"
                    maxNumberOfCharacter={300}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    isEmail
                    disabled={adminView}
                />
            </div>
            <div className="h-[20px]">
                <div className="h-[2px]">
                    {errors.o6_k8_firstName && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o6_k8_firstName')}</p>}
                    {!errors.o6_k8_firstName && errors.o6_k8_lastName && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o6_k8_lastName')}</p>}
                    {!errors.o6_k8_firstName && !errors.o6_k8_lastName && errors.o6_k8_phoneNumber && (
                        <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o6_k8_phoneNumber')}</p>
                    )}
                    {!errors.o6_k8_firstName && !errors.o6_k8_lastName && !errors.o6_k8_phoneNumber && errors.o6_k8_email && (
                        <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o6_k8_email')}</p>
                    )}
                </div>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step8
