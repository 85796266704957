import { File } from '../../File.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { TextDescription } from '../../TextDescription.entity'

export const NumberOfSteps = 4

export interface Form7_13Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string
    submitted_by?: SubmittedBy

    o7_13_k1_br_propisa: number | undefined
    o7_13_k1_br_mehanizama: number | undefined
    o7_13_k1_odgovorni?: TextDescription[]

    o7_13_k2_z: number | undefined
    o7_13_k2_m: number | undefined
    o7_13_k2_kampanje?: TextDescription[]

    o7_13_k3_fajl: File
}
