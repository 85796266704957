import { Organization } from '../Organization.entity'
import { SubmittedBy } from '../SubmittedBy.entity'
import { GenderEqualityCooperation } from '../GenderEqualityCooperation.entity'
import { GRADE } from '../CommonTypes'
import { SpecialMeasures } from '../SpecialMeasures'

export const NumberOfSteps = 11
export type TblOblast4Keys = keyof TblOblast4
export interface TblOblast4 {
    report_id?: string // uuid
    rep_year?: number // year
    submitted_date?: Date
    submitted_place?: Date
    category?: string // category
    organization?: Organization // uuid
    status?: number // rep_status
    // Korak 1
    o4_k1_ocena?: GRADE
    o4_k1_razlozi?: string // nvachar(2048)

    // Korak 2
    o4_k2_br1?: number
    o4_k2_br2?: number
    o4_k2_br3?: number
    o4_k2_br4?: number

    // Korak 3
    o4_k3_spisak?: string

    // Korak 4
    o4_k4_specialMeasures?: SpecialMeasures[]

    // Korak 5
    o4_k5_nacin?: string

    // Korak 6
    o4_k6_ocena_text?: string
    o4_k6_ocena_broj?: number

    // Korak 7
    o4_k7_procenat?: number
    o4_k7_obrazlozenje?: string

    // Korak 8
    o4_k8_br1?: number
    o4_k8_br2?: number
    o4_k8_br3?: number
    o4_k8_br4?: number
    o4_k8_br5?: number
    o4_k8_br6?: number

    // Korak 9
    o4_k9_saradnja?: GenderEqualityCooperation[]

    // Korak 10
    submitted_by?: SubmittedBy // uuid

    o4_k10_firstName?: string

    o4_k10_lastName?: string

    o4_k10_phoneNumber?: string

    o4_k10_email?: string

    o4_k10_has_education?: boolean

    o4_k10_education_name?: string

    o4_k10_education_date?: Date

    o4_k10_education_org?: string

    o4_k10_education_teacher?: string

    o4_k10_files?: File[]
}
