import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface SelectComponentInterface {
    value: any
    elements: any[]
    o2s4?: boolean
    error?: boolean
    disabled?: boolean
    open: boolean
}

function TutorialSelectComponent({ elements, value, o2s4, error, disabled, open }: SelectComponentInterface) {
    console.log('🚀 ~ TutorialSelectComponent ~ open:', open)
    return (
        <Select
            labelId="select-component"
            disabled={disabled}
            id="select-component"
            open={open}
            value={value}
            className={`min-w-[186px] max-w-[186px] ${o2s4 ? '!h-full' : 'h-[50px]'} select-component-css cursor-default`}
            sx={{
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: error ? '#d32f2f' : '#00000010',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: error ? '#d32f2f' : '#00000010',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: error ? '#d32f2f' : '#00000010',
                },
                '.MuiSvgIcon-root ': {
                    fill: '#0000008F !important',
                },
            }}
            displayEmpty
            renderValue={(selected) => (!selected ? 'Изаберите' : selected)}
        >
            {elements?.map((item) => (
                <MenuItem
                    key={item}
                    value={item}
                    disabled
                    style={{
                        textAlign: 'center',
                        height: 50,
                        justifyContent: 'center',
                        color: 'inherit',
                        opacity: 1,
                    }}
                >
                    {item}
                </MenuItem>
            ))}
        </Select>
    )
}

export default TutorialSelectComponent
