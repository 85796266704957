import React, { useEffect, useState } from 'react'
import { Container, Button, Grid, TextField } from '@mui/material'
import MDEditor from '@uiw/react-md-editor'
import { createLegalInfo, getLegalInfo } from '../../server/server'
import HelpButton from '../../components/Common/HelpButton'

const LegalInfo = () => {
    const [terms, setTerms] = useState<string | undefined>('')
    const [aboutUs, setAboutUs] = useState<string | undefined>('')
    const [faq, setFaq] = useState<string | undefined>('')
    const [privacy, setPrivacy] = useState<string | undefined>('')
    const [id, setId] = useState()

    const [isTermsActive, setIsTermsActive] = useState(true)
    const [isAboutActive, setIsAboutActive] = useState(false)
    const [isFaqActive, setIsFaqActive] = useState(false)
    const [isPrivacyActive, setIsPrivacyActive] = useState(false)

    useEffect(() => {
        fetchSsp()
    }, [])

    const fetchSsp = async () => {
        const resp = await getLegalInfo()

        if (resp.status === 200) {
            setTerms(resp?.data[0]?.terms || '')
            setAboutUs(resp?.data[0]?.aboutUs || '')
            setFaq(resp?.data[0]?.faq || '')
            setPrivacy(resp?.data[0]?.privacyPolicy || '')
            setId(resp?.data[0]?.id)
        } else {
            alert(`Грешка при добијању информација: ${resp.status}`)
        }
    }

    const saveChanges = async () => {
        const activeField = isTermsActive ? 'terms' : isAboutActive ? 'aboutUs' : isFaqActive ? 'faq' : 'privacyPolicy'

        const info = {
            [activeField]: isTermsActive ? terms : isAboutActive ? aboutUs : isFaqActive ? faq : privacy,
        }

        const resp = await createLegalInfo(info)
        if (resp.status === 201) {
            alert('Измене успешно сачуване.')
        } else {
            alert(`Грешка при чувању: ${resp.status}`)
        }
    }

    const handleValueChange = (value: any) => {
        if (isTermsActive) {
            setTerms(value)
        } else if (isAboutActive) {
            setAboutUs(value)
        } else if (isFaqActive) {
            setFaq(value)
        } else if (isPrivacyActive) {
            setPrivacy(value)
        }
    }

    const clearField = () => {
        if (isTermsActive) {
            setTerms('')
        } else if (isAboutActive) {
            setAboutUs('')
        } else if (isFaqActive) {
            setFaq('')
        } else if (isPrivacyActive) {
            setPrivacy('')
        }
    }

    return (
        <div>
            <div className="grid grid-rows-2 py-3 h-full px-20 overflow-auto relative">
                <div className="absolute top-5 right-10 ">
                    <HelpButton name="legal_info_help" />
                </div>
                <div className="h-full flex flex-row justify-between">
                    <div className="flex-1 flex flex-row h-full items-center justify-center">
                        <div className="m-5">
                            <Button
                                variant="contained"
                                className={`${isTermsActive ? '!bg-[#243964]' : '!bg-primary'}`}
                                onClick={() => {
                                    setIsTermsActive(true)
                                    setIsAboutActive(false)
                                    setIsFaqActive(false)
                                    setIsPrivacyActive(false)
                                }}
                            >
                                Услови коришћења
                            </Button>
                        </div>
                        {/* <div className="m-5">
                                <Button
                                    variant="contained"
                                    color={isAboutActive ? 'secondary' : 'primary'}
                                    className="m-5"
                                    onClick={() => {
                                        setIsTermsActive(false)
                                        setIsAboutActive(true)
                                        setIsFaqActive(false)
                                        setIsPrivacyActive(false)
                                    }}
                                >
                                    О нама
                                </Button>
                            </div> */}
                        <div className="m-5">
                            <Button
                                variant="contained"
                                className={`${isFaqActive ? '!bg-[#243964]' : '!bg-primary'}`}
                                onClick={() => {
                                    setIsTermsActive(false)
                                    setIsAboutActive(false)
                                    setIsFaqActive(true)
                                    setIsPrivacyActive(false)
                                }}
                            >
                                Често постављена питања
                            </Button>
                        </div>
                        <div className="m-5">
                            <Button
                                variant="contained"
                                className={`${isPrivacyActive ? '!bg-[#243964]' : '!bg-primary'}`}
                                onClick={() => {
                                    setIsTermsActive(false)
                                    setIsAboutActive(false)
                                    setIsFaqActive(false)
                                    setIsPrivacyActive(true)
                                }}
                            >
                                Политика приватности
                            </Button>
                        </div>
                    </div>
                </div>
                <hr />

                <div>
                    <div className="mb-2 flex justify-between">
                        <Button onClick={clearField} className="!bg-[#243964]" variant="contained">
                            Очисти поље
                        </Button>
                        <Button variant="contained" className="!bg-[#243964]" onClick={saveChanges}>
                            Сачувај измене
                        </Button>
                    </div>
                    <div>
                        {isTermsActive && (
                            <MDEditor value={terms} onChange={handleValueChange} style={{ backgroundColor: 'transparent', color: 'rgb(94, 99, 155)', minHeight: '500px' }} />
                        )}
                        {isAboutActive && (
                            <MDEditor value={aboutUs} onChange={handleValueChange} style={{ backgroundColor: 'transparent', color: 'rgb(94, 99, 155)', minHeight: '500px' }} />
                        )}
                        {isFaqActive && (
                            <div>
                                <MDEditor value={faq} onChange={handleValueChange} style={{ backgroundColor: 'transparent', color: 'rgb(94, 99, 155)', minHeight: '500px' }} />
                                <p>
                                    *Попуњавајте у формату: {'питање'} & {'одговор'} @ {'питање'} & {'одговор'}
                                </p>
                            </div>
                        )}
                        {isPrivacyActive && (
                            <MDEditor value={privacy} onChange={handleValueChange} style={{ backgroundColor: 'transparent', color: 'rgb(94, 99, 155)', minHeight: '500px' }} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegalInfo
