import { useForm, useWatch } from 'react-hook-form'
import { TblAnketa } from '../../models/Anketa/Anketa.entity'
import { saveAnketa } from '../../server/server'
import RadioOptionsControl from '../../components/Common/RadioButtonsControlWithError'
import Textbox from '../../components/Common/Textbox'
import { Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import * as FormTitles from '../../models/FormTitles'

function getTitle(form: string | undefined) {
    switch (form) {
        case 'obrazac1':
            return FormTitles.FORM1_TITLE
        case 'obrazac2':
            return FormTitles.FORM2_TITLE
        case 'obrazac3':
            return FormTitles.FORM3_TITLE
        case 'obrazac4/komisija':
            return FormTitles.FORM4_TITLE_COMMISION
        case 'obrazac4/savet':
            return FormTitles.FORM4_TITLE_COUNCIL
        case 'obrazac5/stranka':
            return FormTitles.FORM5_TITLE_STRANKE
        case 'obrazac5/sindikat':
            return FormTitles.FORM5_TITLE_SINDIKATI
        case 'obrazac6':
            return FormTitles.FORM6_TITLE
        case 'obrazac7':
            return FormTitles.FORM7_TITLE
        default:
            return undefined
    }
}

function AnketaScreen() {
    const {
        handleSubmit,
        control,
        getValues,
        reset,
        setValue,
        register,
        formState: { errors },
    } = useForm<TblAnketa>()

    const watchedValues = useWatch<TblAnketa>({ control })

    const navigate = useNavigate()

    const save = async () => {
        setValue('anketa_date', new Date())
        setValue('anketa_obrazac', '') // ukoliko bude trebalo pamtiti o kom obrazcu je rec {const { '*': form } = useParams();}
        await saveAnketa(getValues())
    }

    const finish = () => {
        save()
        window.alert('Анкета је успешно попуњена')
        reset()
        navigate('/')
    }

    const onSubmit = async () => {
        // console.log('get values ', getValues())
    }

    const radioFields = ['anketa_ui_ocena', 'anketa_jasnoca', 'anketa_lakoca', 'anketa_ima_probleme']

    const disabledSubmit =
        !radioFields.every((field) => watchedValues[field as keyof TblAnketa] !== undefined) || !!(Boolean(watchedValues.anketa_ima_probleme) && !watchedValues.anketa_problemi)

    const radioOptionsUiOcena = ['Одлично', 'Врло добро', 'Добро', 'Задовољава', 'Недовољно']
    const radioOptionsUputstva = ['Потпуно јасна', 'Делимично јасна', 'Неутрално', 'Није било довољно јасно', 'Уопште није било јасно']
    const radioOptionsLakoca = ['Веома лако', 'Лако', 'Није ни тешко ни лако', 'Тешко', 'Веома тешко']
    const radioOptionsImaProbleme = ['Не', 'Да']

    const submit = () => {
        if (!disabledSubmit) {
            finish()
        } else {
            const firstError = document.querySelector('.Mui-error')
            if (firstError) {
                if (firstError) {
                    firstError.scrollIntoView({ behavior: 'smooth' })
                }
                ;(firstError as HTMLElement)?.focus()
            } else {
                window.alert('Попуните обавезна поља.')
            }
        }
    }

    return (
        <div className="max-w-[1556px] mx-[auto] px-[40px] pb-[20px]">
            <div className="pt-[70px] pb-[55px]">
                <h1 className="text-[26px] font-medium" style={{ textAlign: 'center' }}>
                    Анкета о искуству подношења извештаја и предлози за побољшање
                </h1>
                <h1 className="step-name mt-[20px]" style={{ textAlign: 'center' }}>
                    Хвала што учествујете у анкети! Ваши одговори ће помоћи у побољшању система.
                </h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <h1 className="step-name text-[18px] mb-[5px]">1. Како бисте оценили свој укупни утисак о корисничком интерфејсу обрасца?</h1>
                    <RadioOptionsControl name="anketa_ui_ocena" control={control} enumerated errors={errors} options={radioOptionsUiOcena} />

                    <h1 className="step-name text-[18px] mb-[5px] mt-[40px]">2. Колико су била јасна и разумљива упутства за попуњавање обрасца?</h1>
                    <RadioOptionsControl name="anketa_jasnoca" control={control} enumerated errors={errors} options={radioOptionsUputstva} />

                    <h1 className="step-name text-[18px] mb-[5px] mt-[40px]">3. Колико је било лако попунити образац?</h1>
                    <RadioOptionsControl name="anketa_lakoca" control={control} enumerated errors={errors} options={radioOptionsLakoca} />

                    <h1 className="step-name text-[18px] mb-[5px] mt-[40px]">4. Да ли сте наишли на неке проблеме током попуњавања обрасца?</h1>
                    <RadioOptionsControl
                        name="anketa_ima_probleme"
                        control={control}
                        boolean
                        errors={watchedValues.anketa_ima_probleme == undefined ? errors : {}}
                        options={radioOptionsImaProbleme}
                    />

                    <h1 className="step-name text-[18px] mb-[20px] mt-[40px]">5. Ако сте наишли на проблеме, молимо вас да их опишете:</h1>
                    <Textbox
                        name="anketa_problemi"
                        control={control}
                        errors={watchedValues.anketa_ima_probleme ? errors : {}}
                        isRequired={Boolean(watchedValues.anketa_ima_probleme) ? 'Попуните обавезно поље.' : false}
                        maxNumberOfCharacters={2048}
                        disabled={!Boolean(watchedValues?.anketa_ima_probleme)}
                    />

                    <h1 className="step-name text-[18px] mb-[20px] mt-[40px]">6. Да ли имате предлоге за побољшање корисничког интерфејса или самог обрасца?</h1>
                    <Textbox name="anketa_predlozi" control={control} maxNumberOfCharacters={2048} />

                    <h1 className="step-name text-[18px] mb-[20px] mt-[40px]">7. Молимо вас да додате било какве додатне коментаре или сугестије:</h1>
                    <Textbox name="anketa_komentar" control={control} maxNumberOfCharacters={2048} />

                    <div className=" mt-[50px] mb-[80px]">
                        <Button variant="contained" type="submit" onClick={submit} disabled={false}>
                            Заврши и пошаљи
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AnketaScreen
