import { File } from '../../File.entity'
import { Activity } from '../../Form2/Form2.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'
import { ConstantProgram } from './Common/ConstantProgram.entity'
import { Sector } from './Common/Sector.entity'

export const NumberOfSteps1 = 8

export interface Form7_4Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string

    o7_4_k1_z1: number
    o7_4_k1_z2: number
    o7_4_k1_m1: number
    o7_4_k1_m2: number

    o7_4_k2_t1_z1: number
    o7_4_k2_t1_z2: number
    o7_4_k2_t1_m1: number
    o7_4_k2_t1_m2: number

    o7_4_k2_t2_z1: number
    o7_4_k2_t2_z2: number
    o7_4_k2_t2_m1: number
    o7_4_k2_t2_m2: number

    o7_4_k2_t3_z1: number
    o7_4_k2_t3_z2: number
    o7_4_k2_t3_m1: number
    o7_4_k2_t3_m2: number

    o7_4_k3_t1_z1: number
    o7_4_k3_t1_z2: number
    o7_4_k3_t1_m1: number
    o7_4_k3_t1_m2: number

    o7_4_k3_t2_z1: number
    o7_4_k3_t2_z2: number
    o7_4_k3_t2_m1: number
    o7_4_k3_t2_m2: number

    o7_4_k4_t1_z1: number
    o7_4_k4_t1_z2: number
    o7_4_k4_t1_m1: number
    o7_4_k4_t1_m2: number

    o7_4_k4_t2_z1: number
    o7_4_k4_t2_z2: number
    o7_4_k4_t2_m1: number
    o7_4_k4_t2_m2: number

    o7_4_k5_z1: number
    o7_4_k5_z2: number
    o7_4_k5_m1: number
    o7_4_k5_m2: number

    o7_4_k6_sektori?: Sector[]

    o7_4_k7_z1: number
    o7_4_k7_z2: number
    o7_4_k7_z3: number
    o7_4_k7_z4: number
    o7_4_k7_z5: number
    o7_4_k7_z6: number
    o7_4_k7_z7: number
    o7_4_k7_m1: number
    o7_4_k7_m2: number
    o7_4_k7_m3: number
    o7_4_k7_m4: number
    o7_4_k7_m5: number
    o7_4_k7_m6: number
    o7_4_k7_m7: number

    submitted_by?: SubmittedBy
}
