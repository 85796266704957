import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import TextInputCell from '../../../components/Common/TextInputCell'

interface PlanTableInterface {
    header: any[]
    fields: any[]
    control: any
    name: any
    errors: any
    adminView: boolean | undefined
    remove: any
    form7o6?: boolean
    watchedValues?: any
    disabled?: boolean
}

const PlanTable = ({ header, fields, control, errors, name, adminView, remove, form7o6 = false, watchedValues, disabled }: PlanTableInterface) => {
    return (
        <div>
            <table className="multiple-input-table mt-[20px] overflow-x-auto overflow-y-hidden">
                <tr className="multiple-input-table-header">
                    {header.map((head, index) => (
                        <th
                            className={`multiple-input-table-cell ${
                                index === 0
                                    ? '!min-w-[90px] !max-w-[90px]'
                                    : index === 1 || index === 3
                                    ? 'min-w-[450px]'
                                    : index === 2
                                    ? 'min-w-[250px]'
                                    : index === 4
                                    ? '!min-w-[200px]'
                                    : ''
                            }`}
                            key={index}
                        >
                            {head}
                        </th>
                    ))}
                </tr>
                {fields.map((field, index) => {
                    return (
                        <div className="flex flex-row" key={index}>
                            <tr key={field.id} className="multiple-input-table-row">
                                <td className="multiple-input-table-cell disabled max-w-[90px]" style={{ border: 'none' }}>
                                    {index + 1}
                                </td>
                                <td className={`multiple-input-table-cell !border-none !min-w-[450px]`}>
                                    <TextInputCell
                                        name={`${name}.${index}.programName`}
                                        control={control}
                                        placeholder="Текст (150 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={150}
                                        height={62}
                                        disabled={adminView || disabled}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable !border-none !min-w-[250px]">
                                    <TextInputCell
                                        name={`${name}.${index}.organizer`}
                                        control={control}
                                        placeholder="Текст (50 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={50}
                                        height={62}
                                        disabled={adminView || disabled}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable !border-none !min-w-[450px]">
                                    <TextInputCell
                                        name={`${name}.${index}.plan`}
                                        control={control}
                                        placeholder="Текст (100 карактера)"
                                        errors={errors}
                                        isRequired={false}
                                        maxNumberOfCharacter={100}
                                        height={62}
                                        disabled={adminView || disabled}
                                    />
                                </td>
                            </tr>
                            {fields.length >= 2 ? (
                                <div className="ml-[20px] flex justify-center items-center ">
                                    <button onClick={() => remove(index)} disabled={adminView}>
                                        <Delete />
                                    </button>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                })}

                <div className="h-[2px]"> {errors[name] && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>
        </div>
    )
}
export default PlanTable
