import React, { useState } from 'react'
import Tour from 'reactour'
import { TutorialStepInterface } from './Step1'
import registerImage from '../../../../assets/images/LoginTutorialImages/Register.png'
import Actions from '../../Components/Actions'
import { Checkbox } from '@mui/material'
import { ReactComponent as ShowPasswordIcon } from '../../../../assets/svg/EyeFilled.svg'

const getPasswordStrengthLabel = (strengthId: number) => {
    switch (strengthId) {
        case 0:
            return 'ПРЕСЛАБА'
        case 1:
            return 'СЛАБА'
        case 2:
            return 'СРЕДНЈА'
        case 3:
            return 'ЈАКА'
        default:
            return ''
    }
}

const Step3 = ({ nextStep, previousStep, previousButtonDisabled = false, skipClick, onClose }: TutorialStepInterface) => {
    const [isTourOpen, setIsTourOpen] = useState(true)
    const tourSteps = [
        { selector: 'div[id="main"]', content: 'Након клика на дугме „Региструјте се,” приказује се форма за креирање новог корисничког налога. Екран садржи следеће елементе' },
        { selector: 'div[id="ime"]', content: 'Унесите своје име.' },
        {
            selector: 'span[id="error"]',
            content: 'Уколико тражене информације нису унете или постоји грешка са њима појавиће се текстуална порука са објашњењем грешке.',
        },
        { selector: 'div[id="prezime"]', content: 'Унесите своје презиме.' },
        { selector: 'div[id="email"]', content: 'Унесите валидну имејл адресу која ће бити повезана са вашим налогом.' },
        {
            selector: 'div[id="pass"]',
            content:
                'Креирајте лозинку за свој налог. Лозинка мора испуњавати следеће услове: минимална дужина од 8 карактера, најмање један број, најмање једно мало слово, најмање једно велико слово и најмање један специјалан симбол (нпр. @, #, $, %). Кликом на икону „око” можете приказати или сакрити унету лозинку.',
        },
        {
            selector: 'div[id="repeat"]',
            content: 'Поново унесите исту лозинку ради потврде, придржавајући се истих услова. И овде је доступна функција приказивања лозинке кликом на икону „око.”',
        },
        { selector: 'button[id="submit"]', content: 'Након што попуните сва поља, кликните на ово дугме како бисте завршили процес регистрације.' },
        { selector: 'div[id="checks"]', content: 'Овим пољима су приказани услови за постављање лозинке које морате да испуните.' },
        { selector: 'div[id="nocheck"]', content: 'Пример поља где услов није испуњен.' },
        { selector: 'div[id="check"]', content: 'Пример поља где је услов испуњен.' },
    ]

    const handleTourClose = () => {
        setIsTourOpen(false)
    }

    const strengthLabel = getPasswordStrengthLabel(2)
    return (
        <div className="w-full h-full flex items-center flex-col">
            <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={handleTourClose} lastStepNextButton={<button onClick={handleTourClose}>Затвори</button>} />
            <div className=" bg-[#ffffff] items-start flex flex-col border border-borderTutorial p-10">
                <h2 className="mb-[32px] text-[24px] leading-[28px] font-light min-w-[520px] max-w-[520px] text-center cursor-default">Регистрација</h2>

                <form className="flex flex-col items-center w-full min-w-[520px] max-w-[520px]" autoComplete="off">
                    <div className="w-full flex flex-col">
                        <div id="ime">
                            <input
                                disabled
                                className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px] cursor-default"
                                placeholder="Име корисника"
                            />
                        </div>

                        <span id="error" className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px] cursor-default">
                            Име је обавезно поље
                        </span>

                        <div id="prezime">
                            <input
                                disabled
                                className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] cursor-default outline-none text-[16px] leading-[24px]"
                                placeholder="Презиме корисника"
                            />
                        </div>
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px]"></span>
                        <div id="email">
                            <input
                                disabled
                                className="border border-[#0000003B] min-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px] cursor-default"
                                placeholder="Електронска адреса"
                            />
                        </div>
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] mb-[10px]"></span>

                        <div className="flex flex-col w-full">
                            <div id="pass" className="border border-[#0000003B] rounded-[4px] min-w-[520px] min-h-[56px] w-full px-3 flex flex-row items-center cursor-default">
                                <input disabled className="text-[16px] leading-[24px] w-full outline-none cursor-default" placeholder="Лозинка" autoComplete="off" />
                                <div className="cursor-default">
                                    <ShowPasswordIcon />
                                </div>
                            </div>
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] "></span>
                        </div>

                        <div className="flex flex-col w-full mt-[16px]">
                            <div id="repeat" className="border border-[#0000003B] rounded-[4px] min-w-[520px] min-h-[56px] w-full px-3 flex flex-row items-center cursor-default">
                                <input disabled className="text-[16px] leading-[24px] w-full outline-none cursor-default" placeholder="Потврда лозинке" autoComplete="off" />
                                <div className="cursor-default">
                                    <ShowPasswordIcon />
                                </div>
                            </div>

                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] "></span>
                            <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px] "></span>
                        </div>

                        <button
                            id="submit"
                            type="submit"
                            disabled
                            className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-default mt-[30px]"
                        >
                            НАСТАВИ СА РЕГИСТРАЦИЈОМ
                        </button>
                    </div>

                    <div id="checks" className="mt-[23px] flex place-self-start flex-col">
                        <div id="nocheck" className="flex flex-row items-center">
                            <Checkbox
                                disabled
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">ПОКЛАПАЊЕ ЛОЗИНКЕ</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                disabled
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">ЈАЧИНА ЛОЗИНКЕ: {strengthLabel}</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                disabled
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">НАЈМАЊЕ 8 КАРАКТЕРА</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                disabled
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">САДРЖИ БРОЈ</p>
                        </div>
                        <div id="check" className="flex flex-row items-center">
                            <Checkbox
                                checked
                                disabled
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">САДРЖИ МАЛО СЛОВО</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                disabled
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">САДРЖИ ВЕЛИКО СЛОВО</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <Checkbox
                                disabled
                                size="large"
                                sx={{
                                    color: '#A2CEFF',
                                    '&.Mui-checked': {
                                        color: '#A2CEFF',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#A2CEFF',
                                    },
                                }}
                            />
                            <p className="text-[14px] leading-[20px] font-light">САДРЖИ СИМБОЛ</p>
                        </div>
                    </div>
                </form>
            </div>
            <div className="w-full max-w-[70%]">
                <Actions nextButton={nextStep} previousButton={previousStep} previousButtonDisabled={false} skipButton={skipClick} closeModal={onClose} />
            </div>
        </div>
    )
}

export default Step3
