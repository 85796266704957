import React, { useEffect, useRef, useState } from 'react'
import { Button, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Tooltip } from '@mui/material'
import { EmptyTablePaginationActions } from '../Components/EmptyTablePaginationActions'
import AutoComplete from '../../../components/Common/AutoComplete'
import { getFilters, getForms } from '../../../server/server'
import { Filter, STATUS } from '../../../models/CommonTypes'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../../auth/UseUser'
import DashboardSelectComponent from '../Components/DashboardSelectComponent'
import SortArrow from '../../User/Components/SortArrow'
import HelpButton from '../../../components/Common/HelpButton'

type Obrazac = {
    id: string
    statusIzvestaja: string
    type?: string
    form?: number
    totalCount: number
}

type Podnosilac = {
    id: string
    nazivPodnosioca: string
    godinaIzvestaja: number
    datumPodnosenja: string
    kategorijaPodnosioca: string
    podkategorijaPodnosioca: string
    statusIzvestaja: string
    obrasci: Obrazac[]
}

type FormFilter = {
    index: string
    submitted: boolean
}

type AutoCompleteType = 'reportYears' | 'uniqueCategories' | 'uniqueSubCategories' | 'submittedByNames' | 'statuses' | 'uniqueSubmissionDate'

const typeMapping: AutoCompleteType[] = ['reportYears', 'uniqueCategories', 'uniqueSubCategories', 'submittedByNames', 'statuses', 'uniqueSubmissionDate']

const tableHeaders = [
    'Година извештаја',
    'Категорија подносиоца',
    'Подкатегорија подносиоца',
    'Назив подносиоца',
    'Статус извештаја',
    // 'Датум подношења',
    'Образац 1',
    'Образац 2',
    'Образац 3',
    'Образац 4',
    'Образац 5-1',
    'Образац 5-2',
    'Образац 6',
    'Образац 7',
]

const headerMapping = [
    { header: 'Година извештаја', value: 'year' },
    { header: 'Категорија подносиоца', value: 'category' },
    { header: 'Подкатегорија подносиоца', value: 'subCategory' },
    { header: 'Назив подносиоца', value: 'name' },
    { header: 'Статус извештаја', value: 'status' },
    { header: 'Образац 1', value: 'f1' },
    { header: 'Образац 2', value: 'f2' },
    { header: 'Образац 3', value: 'f3' },
    { header: 'Образац 4', value: 'f4' },
    { header: 'Образац 5-1', value: 'f5_1' },
    { header: 'Образац 5-2', value: 'f5_2' },
    { header: 'Образац 6', value: 'f6' },
    { header: 'Образац 7-1', value: 'f7_1' },
    { header: 'Образац 7-2', value: 'f7_2' },
    { header: 'Образац 7-3', value: 'f7_3' },
    { header: 'Образац 7-4', value: 'f7_4' },
    { header: 'Образац 7-5', value: 'f7_5' },
    { header: 'Образац 7-6', value: 'f7_6' },
    { header: 'Образац 7-7', value: 'f7_7' },
    { header: 'Образац 7-8', value: 'f7_8' },
    { header: 'Образац 7-9', value: 'f7_9' },
    { header: 'Образац 7-10', value: 'f7_10' },
    { header: 'Образац 7-11', value: 'f7_11' },
    { header: 'Образац 7-12', value: 'f7_12' },
    { header: 'Образац 7-13', value: 'f7_13' },
    { header: 'Образац 7-14-1', value: 'f7_14_1' },
    { header: 'Образац 7-14-2', value: 'f7_14_2' },
    { header: 'Образац 7-15', value: 'f7_15' },
    { header: 'Образац 7-16', value: 'f7_16' },
    { header: 'Образац 7-17', value: 'f7_17' },
    { header: 'Образац 7-18', value: 'f7_18' },
    { header: 'Образац 7-19', value: 'f7_19' },
]

const FormSearch = () => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [obrazacSelect, setObrazacSelect] = useState<any>()
    const [filters, setFilters] = useState<Filter>({
        reportYears: [],
        statuses: null,
        categories: [],
        subCategories: [],
    })
    const [selectedFilters, setSelectedFilters] = useState({
        years: [] as string[],
        categories: [] as string[],
        subCategories: [] as string[],
        statuses: undefined as number | undefined,
        forms: [] as FormFilter[],
        sortField: 'year',
        sortOrder: 'ASC',
    })
    const [tableData, setTableData] = useState<Podnosilac[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [openAutoCompleteIndex, setOpenAutoCompleteIndex] = useState<number | null>(null)
    const [reportYearDropDownOpen, setReportYearDropDownOpen] = useState(false)
    const [reportCategoryDropDownOpen, setReportCategoryDropDownOpen] = useState(false)
    const [reportSubCategoryDropDownOpen, setReportSubCategoryDropDownOpen] = useState(false)
    const [searchString, setSearchString] = useState('')
    const navigate = useNavigate()
    const { user } = useUser()
    const tableRef = useRef<HTMLDivElement>(null)

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
        scrollToTop()
    }

    const scrollToTop = () => {
        tableRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    useEffect(() => {
        getFiltersForTable()
    }, [])
    useEffect(() => {
        getFormsData()
    }, [page, rowsPerPage, selectedFilters, searchString])

    const getFormsData = async () => {
        const resp = await getForms(page * rowsPerPage, rowsPerPage, selectedFilters, searchString)
        console.log('🚀 ~ getFormsData ~ resp:', resp)
        if (resp.status === 201) {
            setTableData(resp?.data?.reports)
            setTotalCount(resp?.data?.totalCount)
        }
    }
    const getFiltersForTable = async () => {
        const resp = await getFilters()
        if (resp.status === 200) {
            setFilters({ ...resp.data })
        }
    }

    const reportYearsOptions = filters?.reportYears || []
    const categoresOptions = filters?.categories || []
    const subCategoresOptions = filters?.subCategories || []

    const getFormIdentifier = (index: number) => {
        if (index === 9 || index === 10) return 'f5'

        return `f${index - 4}`
    }

    const handleSort = (field: any) => {
        setSelectedFilters((prev: any) => {
            const newSortOrder = prev.sortField === field && prev.sortOrder === 'ASC' ? 'DESC' : 'ASC'
            return { ...prev, sortField: field, sortOrder: newSortOrder }
        })
    }

    return (
        <div className="flex flex-col w-full ">
            <div className="w-full">
                <div className="flex flex-row items-center  mb-4">
                    <h2 className="text-[24px] font-light max-w-[800px]">Управљање извештајима</h2>
                </div>
            </div>
            <div className="!w-full py-10 min-h-full">
                <Paper>
                    <TableContainer ref={tableRef}>
                        <Table className="overflow-x-auto w-full">
                            <TableRow>
                                {headerMapping.map((item, index) => (
                                    <TableCell className="min-w-[150px] border border-[#E0E0E0]" key={index} onClick={() => handleSort(item.value)}>
                                        <div className=" !flex !flex-row justify-between items-center px-2">
                                            {item.header}
                                            <SortArrow
                                                sortField={selectedFilters.sortField}
                                                currentField={item.value}
                                                sortOrder={selectedFilters.sortOrder}
                                                isActive={selectedFilters.sortField === item.value}
                                            />
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow className="!p-0">
                                <TableCell className="min-w-[150px] border border-[#E0E0E0] !p-0">
                                    <AutoComplete
                                        borderColor="transparent"
                                        type={'reportYears'}
                                        setSelectedFilters={setSelectedFilters}
                                        options={['Сви', ...reportYearsOptions]}
                                        isOpen={reportYearDropDownOpen}
                                        onOpen={() => setReportYearDropDownOpen(true)}
                                        onClose={() => setReportYearDropDownOpen(false)}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] !p-0">
                                    <AutoComplete
                                        borderColor="transparent"
                                        type={'uniqueCategories'}
                                        setSelectedFilters={setSelectedFilters}
                                        options={['Сви', ...categoresOptions]}
                                        isOpen={reportCategoryDropDownOpen}
                                        onOpen={() => setReportCategoryDropDownOpen(true)}
                                        onClose={() => setReportCategoryDropDownOpen(false)}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] !p-0">
                                    <AutoComplete
                                        borderColor="transparent"
                                        type={'uniqueSubCategories'}
                                        setSelectedFilters={setSelectedFilters}
                                        options={['Сви', ...subCategoresOptions]}
                                        isOpen={reportSubCategoryDropDownOpen}
                                        onOpen={() => setReportSubCategoryDropDownOpen(true)}
                                        onClose={() => setReportSubCategoryDropDownOpen(false)}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] min-w-[200px] !p-0">
                                    <TextField
                                        type="text"
                                        className="w-full p-2 !rounded-0"
                                        placeholder="Претрага по називу"
                                        value={searchString}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            setSearchString(value)
                                        }}
                                        sx={{
                                            borderRadius: '0',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent !important',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: '#e0e0e0 !important',
                                            },
                                        }}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] !p-0">
                                    <DashboardSelectComponent
                                        borderColor="transparent"
                                        setSelectedFilters={setSelectedFilters}
                                        selectedFilters={selectedFilters}
                                        value={selectedFilters.statuses}
                                        options={[
                                            { value: undefined, label: '-' },
                                            { value: 0, label: 'Започет' },
                                            { value: 1, label: 'Завршен' },
                                            { value: 2, label: 'Закључан' },
                                        ]}
                                    />
                                </TableCell>
                                {headerMapping.slice(5).map((h, index) => (
                                    <TableCell className="border border-[#E0E0E0] !p-0" key={index + 5}>
                                        <DashboardSelectComponent
                                            borderColor="transparent"
                                            setSelectedFilters={setSelectedFilters}
                                            setObrazacSelect={setObrazacSelect}
                                            formIdentifier={getFormIdentifier(index + 5)}
                                            value={obrazacSelect?.[index + 5] !== undefined ? obrazacSelect[index + 5] : undefined}
                                            obrazacIndex={index + 5}
                                            options={[
                                                { value: undefined, label: '-' },
                                                { value: 0, label: 'Поднесен' },
                                                { value: 1, label: 'Није поднесен' },
                                            ]}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableBody>
                                {tableData.map((r) => (
                                    <TableRow key={r.id}>
                                        <TableCell className="border border-[#e0e0e0]" height={52}>
                                            {r.godinaIzvestaja}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={r.kategorijaPodnosioca || '-'} placement="top" arrow>
                                                <div
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: '150px',
                                                        minWidth: '150px',
                                                    }}
                                                >
                                                    {r.kategorijaPodnosioca}
                                                </div>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className="border border-[#e0e0e0]">
                                            <Tooltip title={r.podkategorijaPodnosioca || '-'} placement="top" arrow>
                                                <div
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: '150px',
                                                        minWidth: '150px',
                                                    }}
                                                >
                                                    {r.podkategorijaPodnosioca}
                                                </div>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className="border border-[#e0e0e0]" height={52}>
                                            {r.nazivPodnosioca}
                                        </TableCell>
                                        <TableCell className="border border-[#e0e0e0]" height={52} align="center">
                                            {STATUS[r.statusIzvestaja as keyof typeof STATUS]}
                                        </TableCell>
                                        {r.obrasci.map((item, index) => {
                                            return (
                                                <TableCell align="center" className={`!min-w-[150px] !max-w-[150px] border border-[#e0e0e0]`}>
                                                    {item.id !== null ? (
                                                        <Button
                                                            style={{ width: '74px', backgroundColor: `${item.statusIzvestaja === '0' ? '#0D47A1' : '#32a869'}` }}
                                                            key={item.id}
                                                            onClick={() => {
                                                                const addonString = item.type === 'stranka' || item.type === 'sindikat' ? `/${item.type}` : ''

                                                                user.admin && navigate(`/obrazac${item.form}${addonString}?formId=${item.id}`)
                                                            }}
                                                            variant="contained"
                                                        >
                                                            Отвори
                                                        </Button>
                                                    ) : (
                                                        '-'
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={totalCount || 0}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                    />
                </Paper>
            </div>
        </div>
    )
}

export default FormSearch
