import React from 'react'
import { StepInterface } from './Step1'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { useWatch } from 'react-hook-form'
import { Form7_6Entity } from '../../../../../models/Form7/Entities/Form7_6.entity'
import ActionButtons from '../../../../../components/ActionButtons'

export const Step5 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_6Entity>({ control })

    const fields: ('o7_6_k5_budzet' | 'o7_6_k5_donacije')[] = ['o7_6_k5_budzet', 'o7_6_k5_donacije']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext && !errors.o2_k17_procenat) nextStep()
        else if (adminView) nextStep()
    }

    console.log(errors)
    return (
        <div>
            <h1 className="step-name mb-[20px]">Укупна средства из буџета и донација за пружање услуга социјалне заштите у години извештавања</h1>
            <div className="max-w-[200px] mb-[20px]">
                <h1 className="step-name mb-[20px]">Средства из буџета:</h1>
                <PositiveNumberInputCell
                    name={'o7_6_k5_budzet'}
                    control={control}
                    decimal={true}
                    errors={errors}
                    isRequired={!adminView}
                    maxW={100}
                    clearErrors={clearErrors}
                    disabled={adminView}
                />
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_6_k5_budzet ? 'Попуните обавезно поље!' : ''}</span>
            </div>
            <div className="max-w-[200px]">
                <h1 className="step-name mb-[20px]">Донаторска средства:</h1>
                <PositiveNumberInputCell
                    name={'o7_6_k5_donacije'}
                    control={control}
                    decimal={true}
                    errors={errors}
                    isRequired={!adminView}
                    maxW={100}
                    clearErrors={clearErrors}
                    disabled={adminView}
                />
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_6_k5_donacije ? 'Попуните обавезно поље!' : ''}</span>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
