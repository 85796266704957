import React from 'react'
import { StepInterfaceObl2 } from './Step1'
import { useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import ActionButtons from '../../../components/ActionButtons'
import SimpleInputRowForObject from '../Components/SimpleImputRowForObject'
import UploadFile from '../../../components/UploadFile'

const Step20 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })

    const fields: ('o2_k20_firstName' | 'o2_k20_lastName' | 'o2_k20_phoneNumber' | 'o2_k20_email')[] = ['o2_k20_firstName', 'o2_k20_lastName', 'o2_k20_phoneNumber', 'o2_k20_email']
    const disabledNext = !fields.every((e) => !!watchedValues && !!watchedValues[e])

    const goToNextStep = () => {
        if (!disabledNext && !errors?.o2_k20_email) nextStep()
        else if (adminView) nextStep()
    }

    const renderErrorMessage = (field: string) => {
        const error = errors?.[field]

        if (!error) return null

        let message = 'Попуните обавезно поље'

        if (error?.type === 'pattern') {
            message += ', Електронска адреса није исправно попуњена.'
        } else {
            message += '.'
        }

        return <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">{message}</p>
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }
    return (
        <div onKeyDown={handleKeyDown}>
            <h1 className="step-name">Подаци о СЕКРЕТАРУ/СЕКРЕТАРКИ ТЕЛА ЗА РОДНУ РАВНОПРАВНОСТ</h1>
            <div className="mt-[20px] flex flex-col gap-[15px]">
                <SimpleInputRowForObject
                    title="Име"
                    name="o2_k20_firstName"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    isName
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Презиме"
                    name="o2_k20_lastName"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    isName
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Контакт телефон"
                    name="o2_k20_phoneNumber"
                    maxNumberOfCharacter={50}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    disabled={adminView}
                />
                <SimpleInputRowForObject
                    title="Емаил"
                    name="o2_k20_email"
                    maxNumberOfCharacter={300}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    isEmail
                    disabled={adminView}
                />
            </div>
            <div className="h-[20px]">
                <div className="h-[2px]">
                    {errors.o2_k20_firstName && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o2_k20_firstName')}</p>}
                    {!errors.o2_k20_firstName && errors.o2_k20_lastName && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o2_k20_lastName')}</p>}
                    {!errors.o2_k18_firstName && !errors.o2_k20_lastName && errors.o2_k20_phoneNumber && (
                        <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o2_k20_phoneNumber')}</p>
                    )}
                    {!errors.o2_k20_firstName && !errors.o2_k20_lastName && !errors.o2_k20_phoneNumber && errors.o2_k20_email && (
                        <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('o2_k20_email')}</p>
                    )}
                </div>
            </div>
            <h2>Прилагање одлуке о именовању у ПДФ формату</h2>
            <UploadFile control={control} name="o2_k20_files" />

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step20
