import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface SelectComponentBodyInterface {
    control: any
    name: string
    value: any
    items: any[]
    errors?: any
    isRequired?: any
    isArray?: any
    minWidth?: any
    disabled?: boolean
}

export function SelectBody({ control, value, name, items, errors = {}, isRequired = false, isArray = false, minWidth = 186, disabled }: SelectComponentBodyInterface) {
    const parts = name.split('.')

    const arrayName = parts[0]
    const index = parseInt(parts[1], 10)
    const fieldName = parts[2]

    const hasError = isArray ? !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName] : !!errors[arrayName]

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            render={({ field }) => {
                return (
                    <Select
                        {...field}
                        fullWidth
                        value={value || ''}
                        disabled={disabled}
                        onChange={(e) => {
                            console.log(e.target.value)
                            field.onChange(e.target.value)
                        }}
                        className={`border-[#00000010] h-full min-w-[${minWidth}px] select-component-css !outline-none`}
                        sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: hasError ? '#d32f2f' : '#00000010',
                                borderRadius: 0,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: hasError ? '#d32f2f' : '#00000010',
                                borderRadius: 0,
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: hasError ? '#d32f2f' : '#00000010',
                                borderRadius: 0,
                            },
                            '.MuiSvgIcon-root': {
                                fill: '#0000008F !important',
                            },
                        }}
                        displayEmpty
                        renderValue={(selected) => <span style={{ color: hasError ? '#d32f2f99' : selected ? '#000000' : '#0000008F' }}>{selected || 'Изаберите'}</span>}
                    >
                        {items.map((item: string, index: number) => (
                            <MenuItem value={item} style={{ textAlign: 'center', height: 50, justifyContent: 'center' }} key={index}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                )
            }}
        />
    )
}

export default SelectBody
