import React from 'react'
import { Form7_15Entity } from '../../../../../models/Form7/Entities/Form7_15.entity'
import { Control, useWatch } from 'react-hook-form'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import RadioButtonsControl from '../../../../../components/Common/RadioButtonsControlWithError'
import Textbox from '../../../../../components/Common/Textbox'
import ActionButtons from '../../../../../components/ActionButtons'

export interface StepInterface {
    control?: Control<Form7_15Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export const Step1 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_15Entity>({ control })

    const fields: ('o7_15_k1_usluge' | 'o7_15_k1_z' | 'o7_15_k1_m' | 'o7_15_k1_ocena' | 'o7_15_k1_opis')[] = [
        'o7_15_k1_usluge',
        'o7_15_k1_z',
        'o7_15_k1_m',
        'o7_15_k1_ocena',
        'o7_15_k1_opis',
    ]

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div>
                <h1 className="step-name mb-[10px]">Укупан број здравствених услуга које су у потпуности доступне и приступачне свим лицима</h1>
                <div className="max-w-[200px] flex flex-col">
                    <PositiveNumberInputCell
                        name={`o7_15_k1_usluge`}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={62}
                        disabled={adminView}
                        form7
                        clearErrors={clearErrors}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k1_usluge ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <div>
                <h1 className="step-name mb-[10px]">Који је проценат лица који иду на превентивне прегледе разврстани по полу</h1>
                <div className="max-w-[400px] min-w-[300px] flex flex-col mb-10">
                    <div className="flex flex-row items-center gap-10">
                        <p>Мушкарци:</p>
                        <PositiveNumberInputCell
                            name={`o7_15_k1_m`}
                            control={control}
                            errors={errors}
                            isRequired={!adminView}
                            height={62}
                            disabled={adminView}
                            form7
                            clearErrors={clearErrors}
                            maxW={200}
                            max={100}
                            decimal
                            precentage
                        />
                    </div>
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[130px] mb-10">{errors.o7_15_k1_m || errors.o7_15_k1_m ? 'Попуните обавезно поље!' : ''}</span>
                </div>
                <div className="max-w-[400px] min-w-[300px] flex flex-col">
                    <div className="flex flex-row items-center gap-20">
                        <p>Жене:</p>
                        <PositiveNumberInputCell
                            name={`o7_15_k1_z`}
                            control={control}
                            errors={errors}
                            isRequired={!adminView}
                            height={62}
                            disabled={adminView}
                            form7
                            clearErrors={clearErrors}
                            maxW={200}
                            max={100}
                            decimal
                            precentage
                        />
                    </div>
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[130px] mb-10">{errors.o7_15_k1_z || errors.o7_15_k1_z ? 'Попуните обавезно поље!' : ''}</span>
                </div>

                <h1 className="step-name mb-[10px]">Како оцењујете проценат учешћа лица у превентивним прегледима у Р. Србији?</h1>
                <div className="flex flex-row gap-2 ">
                    <RadioButtonsControl
                        control={control}
                        name={'o7_15_k1_ocena'}
                        options={['Одличан', 'Добар', 'Довољан', 'Недовољан', 'Лош']}
                        errors={errors}
                        enumerated
                        disabled={adminView}
                    />
                </div>
                <div className="mt-[50px] ">
                    <h4 className="mb-[10px]">Молимо Вас да кратко опишете зашто сте дали овакву оцену?</h4>
                    <div className="flex flex-col">
                        <Textbox
                            name="o7_15_k1_opis"
                            control={control}
                            placeholder="Текст (400 карактера)*"
                            errors={errors}
                            isRequired={adminView ? false : 'Попуните обавезно поље.'}
                            maxNumberOfCharacters={400}
                            disabled={adminView}
                        />
                    </div>
                </div>
                <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
            </div>
        </div>
    )
}
